import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  CssBaseline,
  Link,
  Paper,
  Grid,
  makeStyles,
  Snackbar,
} from "@material-ui/core";

import {
  Alert,
  Button,
  Backdrop,
  CircularProgress,
  Box,
  Typography,
  TextField,
} from "@mui/material";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

const image = require("../assets/img/EG_Trade.jpg");

import { useDispatch, useSelector } from "react-redux";

import { loginUser } from "../store/user-context/reducer";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${image.default})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function SignInPage() {
  const classes = useStyles();
  const [employeeId, setEmployeeId] = useState("");
  const [password, setPassword] = useState("");
  const [backdrop, setBackdrop] = useState(false);
  const [toastNotification, setToastNotification] = useState(false);
  // eslint-disable-next-line no-unused-vars
  let history = useHistory();

  const dispatch = useDispatch();

  const userContext = useSelector((state) => state.userContext);

  useEffect(() => {
    // if (userContext.success === false) {
    //   setToastNotification(true);
    // }

    // if (userContext.success === true) {
    //   setToastNotification(true);
    // }

    if (userContext.authenticatedEmployee) {
      history.push("/authenticated/newPickSheet");
    }
  }, [userContext]);

  const handleSignin = (e) => {
    e.preventDefault();
    setBackdrop(true);

    dispatch(loginUser(employeeId, password));
    setBackdrop(false);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100 }}
        open={backdrop}
        onClick={undefined}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={toastNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setToastNotification(false)}
      >
        <Alert
          onClose={() => setToastNotification(false)}
          severity={userContext.success ? "success" : "error"}
        >
          {userContext.message}
        </Alert>
      </Snackbar>

      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={false} md={7} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="employeeId"
                label="Employee Id"
                name="employeeId"
                autoComplete="employeeId"
                autoFocus
                onChange={(e) => {
                  setEmployeeId(e.target.value);
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => handleSignin(e)}
              >
                Sign In
              </Button>

              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Built with ❤️ by Midhil Kari & Venkata Subramanian`}
      <br />
      {"Copyright ©"}
      <Link color="inherit" href="">
        {` MK & VS `}
      </Link>
      {"2021"}
    </Typography>
  );
}
export default SignInPage;
