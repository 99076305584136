import { setUserContext, deactivateUSerSession } from "./actions";
import { AUTH_API_URL, ORDERS_API } from "api-config";
import { jwtDecode } from "jwt-decode";
import { extendToken } from "refresh-token/refreshToken";

export default function userContextReducer(state: any = {}, action: any) {
  switch (action.type) {
    case "setUserContext":
      return { ...state, ...action.payload.userData };
    case "deactivateUSerSession":
      return { ...state, ...action.payload.userData };
    default:
      return state;
  }
}

export const loginUser =
  (username: string, password: string) => async (dispatch: any) => {
    let res = await fetch(`${AUTH_API_URL}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log("Error loading user details", err);
        return err.message;
      });

    if (res) {
      //console.log("res", res);
      const decodedToken: { role: string } = jwtDecode(res.authenticatedEmployee);
      //console.log("decodedToken", decodedToken);
      localStorage.setItem("authenticatedEmployee", res.authenticatedEmployee);
      // localStorage.setItem("success", res.success);
      localStorage.setItem("employeeId", username);
      const role = decodedToken.role;
      localStorage.setItem("role", role);
      extendToken(username);
    }

    dispatch(setUserContext(res));
  };

export const logoutUser = (username: string) => async (dispatch: any) => {
  let res = await fetch(`${ORDERS_API}/api/v2/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`,
    },
    body: JSON.stringify({
      employeeId: username,
    }),
  })
  .then(response => {
    if (response.ok) {
      // Clear all data in local storage
      localStorage.clear();

      dispatch(setUserContext({ success: false }));
      dispatch(deactivateUSerSession(null));
      dispatch({type: "LOG_OUT"});

      window.location.href = '/';
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  })
  .catch(error => {
    console.error('An error occurred while logging out:', error);
  });
};
