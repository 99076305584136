import { combineReducers, createStore, applyMiddleware } from "redux";
import adminManagementReducer from "./admin-management/reducer";
import { employeeReducer, employeeProfileReducer } from "./employees/reducer";
import userContextReducer from "./user-context/reducer";
import {
  productPicksheetReducer,
  multiPicksheetReducer,
  duplicatePicksheetReducer,
  ordersCountReducer,
} from "./picksheet/reducer";

import thunk from "redux-thunk";

const store = createStore(
  combineReducers({
    adminManagement: adminManagementReducer,
    employee: employeeReducer,
    employeeProfile: employeeProfileReducer,
    userContext: userContextReducer,
    productPicksheetContext: productPicksheetReducer,
    multiPicksheetContext: multiPicksheetReducer,
    duplicatePicksheetContext: duplicatePicksheetReducer,
    ordersCount: ordersCountReducer,
  }),
  applyMiddleware(thunk)
);

export default store;

// import { createStore, combineReducers, applyMiddleware } from "redux";
// import thunkMiddleware from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
// import employeeReducer from "./employees/reducer";

// import adminManagementReducer from "./admin-management/reducer";

// // const reducer = () => {
// //   return combineReducers({
// //     adminManagement: adminManagementReducer,
// //     employee: employeeReducer,
// //   });
// // };

// // const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));

// const store = combineReducers({
//   adminManagement: adminManagementReducer,
//   employee: employeeReducer,
// });

// export default store;
