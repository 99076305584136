import React, { useState } from "react";

import { makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {
  Alert,
  Backdrop,
  CircularProgress,
  TextField,
  Button,
  Box,
  Stack,
} from "@mui/material";

import Update from "@material-ui/icons/Update";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useEffect } from "react";
// import store from "../../store/store";
import { setOrdersCount } from "../../store/picksheet/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ORDERS_API } from "api-config";
import { extendToken } from "refresh-token/refreshToken";
const useStyles = makeStyles(styles);

export default function NewPickSheet() {
  const classes = useStyles();
  // const storeData = store.getState();

  const [pickSheet, setPickSheet] = useState(null);

  const [loadOrdersCount, setLoadOrdersCount] = useState(200);

  const [backdrop, setBackdrop] = useState(false);
  const [errorLoadingOrders, setErrorLoadingOrders] = useState(false);

  const [totalOrders, setTotalOrders] = useState(0);
  const [allOrders, setAllOrders] = useState(0);
  const [allOrdersP1, setAllOrdersP1] = useState(0);
  const [allOrdersP2, setAllOrdersP2] = useState(0);
  const [allOrdersM, setAllOrdersM] = useState(0);
  const [onlyAmazonWIOrders, setOnlyAmazonWIOrders] = useState(0);
  const [onlyAmazonWIOrdersP1, setOnlyAmazonWIOrdersP1] = useState(0);
  const [onlyAmazonWIOrdersP2, setOnlyAmazonWIOrdersP2] = useState(0);
  const [onlyAmazonWIOrdersM, setOnlyAmazonWIOrdersM] = useState(0);
  const [clickAndCollect, setClickAndCollect] = useState(0);


  const [productOrdersCount, setProductOrdersCount] = useState(0);
  const [productOrdersPicked, setProductOrdersPicked] = useState(0);
  const [productOrdersIssues, setProductOrdersIssues] = useState(0);

  const [multiOrdersCount, setMultiOrdersCount] = useState(0);
  const [multiOrdersPicked, setMultiOrdersPicked] = useState(0);
  const [multiOrdersIssues, setMultiOrdersIssues] = useState(0);

  const [duplicateOrdersCount, setDuplicateOrdersCount] = useState(0);
  const [duplicateOrdersPicked, setDuplicateOrdersPicked] = useState(0);
  const [duplicateOrdersIssues, setDuplicateOrdersIssues] = useState(0);

  const [loadingOrdersCount, setLoadingOrdersCount] = useState(true);

  const dispatch = useDispatch();
  // const orderCount = useSelector((state) => state.ordersCount);

  // List of values for the dropdown options
  const dropdownOptions = [
    'All Orders',
    'Only Amazon WI Orders',
    'Click & Collect Orders'
    // Add more options as needed
  ];

  // List of values for the dropdown options
  const dropdownOptionsForSiteType = [
    'P1',
    'P2',
    'M'
    // Add more options as needed
  ];
  
  const [selectedOption, setSelectedOption] = useState(dropdownOptions[0]); // State to manage the selected value from the dropdown
  const [selectedSiteTypeOption, setSelectedSiteTypeOption] = useState(dropdownOptionsForSiteType[0]); // State to manage the selected value from the dropdown
  const [showSecondDropdown, setShowSecondDropdown] = useState(true);

  // Function to handle the dropdown value change
  const handleDropdownChange = (event) => {
    const selectedSiteValue = event.target.value;
    setSelectedOption(selectedSiteValue);

    // Show the second dropdown only when the first option is selected in the first dropdown
    if (selectedSiteValue === dropdownOptions[0] || selectedSiteValue === dropdownOptions[1]) {
      setShowSecondDropdown(true);
      setSelectedSiteTypeOption(dropdownOptionsForSiteType[0]);
    } else {
      setShowSecondDropdown(false);
      setSelectedSiteTypeOption(''); // Reset the selected value of the second dropdown
    }
  };

  // Function to handle the dropdown value change
  const handleDropdownChangeForSiteType = (event) => {
    setSelectedSiteTypeOption(event.target.value);
  };

  useEffect(async () => {
    const totalOrdersCount = await getOrdersCount().then((res) => res);

    setTotalOrders(totalOrdersCount.totalOrders || 0);
    setAllOrders(totalOrdersCount.allOrders || 0);
    setAllOrdersP1(totalOrdersCount.allOrdersP1 || 0);
    setAllOrdersP2(totalOrdersCount.allOrdersP2 || 0);
    setAllOrdersM(totalOrdersCount.allOrdersM || 0);
    setOnlyAmazonWIOrders(totalOrdersCount.onlyAmazonWIOrders || 0);
    setOnlyAmazonWIOrdersP1(totalOrdersCount.onlyAmazonWIOrdersP1 || 0);
    setOnlyAmazonWIOrdersP2(totalOrdersCount.onlyAmazonWIOrdersP2 || 0);
    setOnlyAmazonWIOrdersM(totalOrdersCount.onlyAmazonWIOrdersM || 0);
    setClickAndCollect(totalOrdersCount.clickAndCollect || 0);
    setProductOrdersCount(totalOrdersCount.productOrder || 0);
    setMultiOrdersCount(totalOrdersCount.multiOrder || 0);
    setDuplicateOrdersCount(totalOrdersCount.duplicateOrder || 0);
    setProductOrdersPicked(totalOrdersCount.productOrderPicked || 0);
    setProductOrdersIssues(totalOrdersCount.productOrderIssue || 0);

    setMultiOrdersPicked(totalOrdersCount.multiOrderPicked || 0);
    setMultiOrdersIssues(totalOrdersCount.multiOrderIssue || 0);

    setDuplicateOrdersPicked(totalOrdersCount.duplicateOrderPicked || 0);
    setDuplicateOrdersIssues(totalOrdersCount.duplicateOrderIssue || 0);

    window.localStorage.setItem(
      "ProductOrdersCount",
      totalOrdersCount.productOrder
    );
    window.localStorage.setItem(
      "MultiOrdersCount",
      totalOrdersCount.multiOrder
    );
    window.localStorage.setItem(
      "DuplicateOrdersCount",
      totalOrdersCount.duplicateOrder
    );

    let productPickedPages = localStorage.getItem("productOrderPickedPages")
      ? localStorage.getItem("productOrderPickedPages").split(",")
      : [];

    let parsedProductPickedPages = Array.from(productPickedPages).map((a) =>
      JSON.parse(a)
    );

    let unpickedProductPages = Array.from(
      Array(totalOrdersCount.productOrder || 0).keys()
    ).filter((p) => !parsedProductPickedPages.includes(p));

    window.localStorage.setItem("ProductOrderPage", unpickedProductPages[1]);

    let multiPickedPages = localStorage.getItem("multiOrderPickedPages")
      ? localStorage.getItem("multiOrderPickedPages").split(",")
      : [];

    let parsedMultiPickedPages = Array.from(multiPickedPages).map((a) =>
      JSON.parse(a)
    );

    let unpickedMultiPages = Array.from(
      Array(totalOrdersCount.multiOrder || 0).keys()
    ).filter((p) => !parsedMultiPickedPages.includes(p));

    window.localStorage.setItem("MultiOrderPage", unpickedMultiPages[1]);

    let duplicatePickedPages = localStorage.getItem("duplicateOrderPickedPages")
      ? localStorage.getItem("duplicateOrderPickedPages").split(",")
      : [];

    let parsedDuplicatePickedPages = Array.from(duplicatePickedPages).map((a) =>
      JSON.parse(a)
    );

    let unpickedDuplicatePages = Array.from(
      Array(totalOrdersCount.duplicateOrder || 0).keys()
    ).filter((p) => !parsedDuplicatePickedPages.includes(p));

    window.localStorage.setItem(
      "DuplicateOrderPage",
      unpickedDuplicatePages[1]
    );
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("productOrderPickedPages"))
      window.localStorage.setItem("ProductOrderPage", 1);
  }, [productOrdersCount]);

  useEffect(() => {
    if (!localStorage.getItem("multiOrderPickedPages"))
      window.localStorage.setItem("MultiOrderPage", 1);
  }, [multiOrdersCount]);

  useEffect(() => {
    if (!localStorage.getItem("duplicateOrderPickedPages"))
      window.localStorage.setItem("DuplicateOrderPage", 1);
  }, [duplicateOrdersCount]);

  const loadOrdersData = async () => {
    await extendToken();
    //TODO: Load Orders here instead of just product orders
    return fetch(`${ORDERS_API}/api/v2/loadOrders`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        numberOfOrders: loadOrdersCount,
        packingStationSelected: selectedSiteTypeOption,
        orderTypeSelected: selectedOption
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        dispatch(setOrdersCount(res));
        window.localStorage.setItem("ProductOrdersCount", res.productOrder);
        window.localStorage.setItem("MultiOrdersCount", res.multiOrder);
        window.localStorage.setItem("DuplicateOrdersCount", res.duplicateOrder);
        setProductOrdersCount(res.productOrder || 0);
        setMultiOrdersCount(res.multiOrder || 0);
        setDuplicateOrdersCount(res.duplicateOrder || 0);
        setBackdrop(false);
      })
      .catch((err) => {
        console.log("Error loading product orders", err);
        setBackdrop(false);
        setErrorLoadingOrders(true);
      });
  };

  //TODO: Extract these into helper files
  const getOrdersCount = async () => {
    const authenticatedEmployee = localStorage.getItem("authenticatedEmployee");

    if (!authenticatedEmployee) {
      console.log('No authenticated employee found');
      return;
    }

    console.log("authenticatedEmployee", authenticatedEmployee);
    await extendToken();

    return fetch(`${ORDERS_API}/api/v2/picksheet`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authenticatedEmployee}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
      }),
    })
      .then((response) => {
        setLoadingOrdersCount(false);
        return response.json();
      })
      .catch((err) => {
        console.log("error fetching orders count", err);
      });
  };
  const handleProductOrders = async () => {
    productOrdersCount ? setPickSheet("ProductOrders") : setBackdrop(false);
  };

  const handleMultiOrders = async () => {
    multiOrdersCount ? setPickSheet("MultiOrders") : setBackdrop(false);
  };

  const handleDuplicateOrders = async () => {
    duplicateOrdersCount ? setPickSheet("DuplicateOrders") : setBackdrop(false);
  };

  let history = useHistory();

  if (!window.localStorage.getItem("authenticatedEmployee")) {
    history.push("/authenticated/dashboard");
  }

  if (pickSheet === "ProductOrders") {
    history.push("/authenticated/ProductOrdersPickSheet");
  }

  if (pickSheet === "MultiOrders") {
    history.push("/authenticated/MultiOrdersPickSheet");
  }

  if (pickSheet === "DuplicateOrders") {
    history.push("/authenticated/DuplicateOrdersPickSheet");
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
        onClick={undefined}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {errorLoadingOrders && (
        <Alert onClose={() => setErrorLoadingOrders(false)} severity="error">
          Error loading orders. Please try again later.
        </Alert>
      )}
      <GridContainer>
        <div
          style={{
            margin: "0 auto",
            width: "500px",
          }}
        >
          {!pickSheet && (
            <>
              <GridItem xs={12}>
                <div style={{ marginBottom: "20px" }}>
                  <p>
                    <strong>Total Orders:</strong> {totalOrders}
                  </p>

                  <p>
                    <strong>All Orders:</strong> {allOrders} <br />
                    P1: {allOrdersP1} | P2: {allOrdersP2} | M: {allOrdersM}
                  </p>

                  <p>
                    <strong>AmazonWI Orders:</strong> {onlyAmazonWIOrders} <br />
                    P1: {onlyAmazonWIOrdersP1} | P2: {onlyAmazonWIOrdersP2} | M: {onlyAmazonWIOrdersM}
                  </p>

                  <p>
                    <strong>Click and Collect:</strong> {clickAndCollect}
                  </p>
                </div>
                <Card
                  onClick={() => {
                    setBackdrop(true);
                    handleDuplicateOrders();
                  }}
                >
                  <CardBody>
                    <h4
                      className={classes.cardTitle}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      Duplicate Orders
                    </h4>
                    <p className={classes.cardCategory}>
                      Number of Orders:{" "}
                      <span className={classes.successText}>
                        {/* <ArrowUpward className={classes.upArrowCardCategory} />{" "} */}
                        {loadingOrdersCount ? (
                          <CircularProgress size={10} />
                        ) : (
                          // storeData.ordersCount.duplicateOrder
                          duplicateOrdersCount
                        )}
                      </span>
                    </p>
                    <p className={classes.cardCategory}>
                      Picked:{" "}
                      <span className={classes.successText}>
                        {/* <ArrowUpward className={classes.upArrowCardCategory} />{" "} */}
                        {loadingOrdersCount ? (
                          <CircularProgress size={10} />
                        ) : (
                          // storeData.ordersCount.duplicateOrder
                          duplicateOrdersPicked
                        )}
                      </span>
                    </p>
                    <p className={classes.cardCategory}>
                      Issues:{" "}
                      <span className={classes.successText}>
                        {/* <ArrowUpward className={classes.upArrowCardCategory} />{" "} */}
                        {loadingOrdersCount ? (
                          <CircularProgress size={10} />
                        ) : (
                          // storeData.ordersCount.duplicateOrder
                          duplicateOrdersIssues
                        )}
                      </span>
                    </p>
                  </CardBody>
                  <div margin={0} padding={0}>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <Update />
                        Just Updated
                      </div>
                    </CardFooter>
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12}>
                <Card
                  onClick={() => {
                    setBackdrop(true);
                    handleMultiOrders();
                  }}
                >
                  <CardBody>
                    <h4
                      className={classes.cardTitle}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      Multi Orders
                    </h4>
                    <p className={classes.cardCategory}>
                      Number of Orders:{" "}
                      <span className={classes.successText}>
                        {loadingOrdersCount ? (
                          <CircularProgress size={10} />
                        ) : (
                          // storeData.ordersCount.multiOrder
                          multiOrdersCount
                        )}
                      </span>
                    </p>
                    <p className={classes.cardCategory}>
                      Picked:{" "}
                      <span className={classes.successText}>
                        {/* <ArrowUpward className={classes.upArrowCardCategory} />{" "} */}
                        {loadingOrdersCount ? (
                          <CircularProgress size={10} />
                        ) : (
                          // storeData.ordersCount.duplicateOrder
                          multiOrdersPicked
                        )}
                      </span>
                    </p>
                    <p className={classes.cardCategory}>
                      Issues:{" "}
                      <span className={classes.successText}>
                        {/* <ArrowUpward className={classes.upArrowCardCategory} />{" "} */}
                        {loadingOrdersCount ? (
                          <CircularProgress size={10} />
                        ) : (
                          // storeData.ordersCount.duplicateOrder
                          multiOrdersIssues
                        )}
                      </span>
                    </p>
                  </CardBody>
                  <div margin={0} padding={0}>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <Update />
                        Just Updated
                      </div>
                    </CardFooter>
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12}>
                <Card
                  onClick={() => {
                    setBackdrop(true);
                    handleProductOrders(true);
                  }}
                >
                  <CardBody>
                    <h4
                      className={classes.cardTitle}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      Product Orders
                    </h4>
                    <p className={classes.cardCategory}>
                      Number of Orders:{" "}
                      <span className={classes.successText}>
                        {loadingOrdersCount ? (
                          <CircularProgress size={10} />
                        ) : (
                          // orderCount.productOrder
                          productOrdersCount
                        )}
                      </span>
                    </p>
                    <p className={classes.cardCategory}>
                      Picked:{" "}
                      <span className={classes.successText}>
                        {/* <ArrowUpward className={classes.upArrowCardCategory} />{" "} */}
                        {loadingOrdersCount ? (
                          <CircularProgress size={10} />
                        ) : (
                          // storeData.ordersCount.duplicateOrder
                          productOrdersPicked
                        )}
                      </span>
                    </p>
                    <p className={classes.cardCategory}>
                      Issues:{" "}
                      <span className={classes.successText}>
                        {/* <ArrowUpward className={classes.upArrowCardCategory} />{" "} */}
                        {loadingOrdersCount ? (
                          <CircularProgress size={10} />
                        ) : (
                          // storeData.ordersCount.duplicateOrder
                          productOrdersIssues
                        )}
                      </span>
                    </p>
                  </CardBody>
                  <div margin={0} padding={0}>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <Update />
                        Just Updated
                      </div>
                    </CardFooter>
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  style={{
                    paddingBottom: "30px",
                  }}
                >
                  <p>Number of orders</p>

                  <TextField
                    style={{
                      minWidth: "50px",
                      maxWidth: "200px",
                    }}
                    // variant="outlined"
                    margin="normal"
                    fullWidth
                    id="ordersCount"
                    label="Orders Count"
                    name="ordersCount"
                    autoComplete="ordersCount"
                    // autoFocus
                    onChange={(e) => {
                      setLoadOrdersCount(e.target.value);
                    }}
                  />
                </Stack>
              </GridItem>
              <GridItem xs={12}>
                <div style={{ paddingBottom: "30px" }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                    <label htmlFor="dropdown1">Select Type of Order:</label>
                    <select
                      id="dropdown1"
                      onChange={handleDropdownChange}
                      style={{
                        marginLeft: '85px', // Increase space between label and dropdown
                        //width: '250px', // Increase dropdown width
                        //marginTop: '4px', // Add margin at the top
                        //</Stack>marginBottom: '20px', // Add margin at the bottom
                        //display: 'inline-block' // Display inline with label
                      }}
                      value={selectedOption || dropdownOptions[0]} // Set the default value to the first option
                    >
                      {dropdownOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </Stack>
                </div>
              </GridItem>
              
              <GridItem xs={12}>   
                {showSecondDropdown && (
                <div style={{ paddingBottom: "30px" }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                    <label htmlFor="dropdown2" style={{ marginLeft: '0px' }}>Select Packing Station:</label>
                    <select
                      id="dropdown2"
                      onChange={handleDropdownChangeForSiteType}
                      // style={{
                      //   marginLeft: '10px', // Increase space between label and dropdown
                      //   width: '250px', // Increase dropdown width
                      //   marginTop: '4px', // Add margin at the top
                      //   marginBottom: '20px', // Add margin at the bottom
                      //   display: 'inline-block' // Display inline with label
                      // }}
                      value={selectedSiteTypeOption || dropdownOptionsForSiteType[0]} // Set the default value to the first option
                    >
                      {dropdownOptionsForSiteType.map((site, index) => (
                        <option key={index} value={site}>
                          {site}
                        </option>
                      ))}
                    </select>
                  </Stack>
                </div>
                )}
              </GridItem>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setBackdrop(true);
                    loadOrdersData();
                  }}
                  disabled={
                    productOrdersCount ||
                    multiOrdersCount ||
                    duplicateOrdersCount != 0
                  }
                  style={{
                    width: "40%",
                    marginLeft: "25px",
                    marginRight: "25px",
                  }}
                >
                  Load
                </Button>
              </Box>
            </>
          )}
        </div>
      </GridContainer>
    </>
  );
}
