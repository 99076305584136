let ORDERS_HOST;
let ADMIN_HOST;
let SHIPPING_HOST;
let REWARDS;
let EGTRADE_V2;
let AUTH_API;

const hostname = window && window.location && window.location.hostname;

// Prod
if (
  hostname === "egtrade.com.au" ||
  hostname === "egtrade-web-app-prod.s3-website-ap-southeast-2.amazonaws.com"
) {
  ORDERS_HOST = "https://api-egtrade.com/egtrade-api-service";
  SHIPPING_HOST = "https://api-egtrade.com/egtrade-api-shipments";
  ADMIN_HOST = "https://egtrade-admin-api.herokuapp.com";
  REWARDS = "https://api-egtrade.com/egtrade-api-rewards";
  AUTH_API = "https://api-egtrade.com/egtrade-auth-api";
  EGTRADE_V2 =
    "http://egtrade-web-rewards-prod.s3-website-ap-southeast-2.amazonaws.com";
} else if (
  hostname === "egtrade-staging.au" ||
  hostname === "egtrade-web-app.s3-website-ap-southeast-2.amazonaws.com"
) {
  // Staging
  ORDERS_HOST = "https://api-egtrade-staging.com/egtrade-api-service";
  SHIPPING_HOST = "https://api-egtrade-staging.com/egtrade-api-shipments";
  ADMIN_HOST = "https://egtrade-admin-api.herokuapp.com";
  REWARDS = "https://api-egtrade-staging.com/egtrade-api-rewards";
  AUTH_API = "https://api-egtrade-staging.com/egtrade-auth-api";
  EGTRADE_V2 =
    "http://ecs-alb-1679739332.ap-southeast-2.elb.amazonaws.com:3082/";
} else {
  ORDERS_HOST = "https://api-egtrade-staging.com/egtrade-api-service";
  SHIPPING_HOST = "https://api-egtrade-staging.com/egtrade-api-shipments";
  ADMIN_HOST = "https://egtrade-admin-api.herokuapp.com";
  AUTH_API = "https://api-egtrade-staging.com/egtrade-auth-api";
  REWARDS = "https://api-egtrade-staging.com/egtrade-api-rewards";
  EGTRADE_V2 = "http://localhost:3082";
}

export const ORDERS_API = `${ORDERS_HOST}`;
export const ADMIN_API = `${ADMIN_HOST}`;
export const SHIPPING_API = `${SHIPPING_HOST}`;
export const REWARDS_API = `${REWARDS}`;
export const EGTRADE_V2_API = `${EGTRADE_V2}`;
export const AUTH_API_URL = `${AUTH_API}`;
