import { jwtDecode } from 'jwt-decode';
import { AUTH_API_URL } from "api-config";
import { logoutUser } from 'store/user-context/reducer';
import store from 'store/store';

let logoutTimer = null;
let intervalId = null;
let userActivity = false;

const resetTimer = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }

    userActivity = true;
  
    logoutTimer = setTimeout(() => {
      userActivity = false;
    }, 3600000); // set the timeout to 1 hour
};

window.addEventListener('mousemove', resetTimer);
window.addEventListener('keydown', resetTimer);
window.addEventListener('scroll', resetTimer);

export const extendToken = async (username) => {
    // Clear the previous interval
    if (intervalId !== null) {
        clearInterval(intervalId);
    }

    intervalId = setInterval(async () => {
        const token = localStorage.getItem('authenticatedEmployee');
        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            const expiryTime = decodedToken.exp;
            const timeLeft = expiryTime - currentTime;

            // If the token is going to expire in less than 5 minutes and there is user activity, refresh it
            if (timeLeft < 5 * 60 && userActivity) {
                try {
                    // Make a request to the server to get a new token
                    const response = await fetch(`${AUTH_API_URL}/api/extendToken`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();

                    // Replace the old token in local storage with the new one
                    localStorage.setItem('authenticatedEmployee', data.token);

                    const newDecodedToken = jwtDecode(data.token);
                    console.log('New token:', newDecodedToken);
                } catch (error) {
                    console.error('Error:', error);
                }
            } else if (timeLeft < 0 || !userActivity) {
                // If the token is expired or there is no user activity, logout the user
                const username = decodedToken.email;
                await store.dispatch(logoutUser(username));
                window.location.href = '/';
            }
        }
    }, 60 * 1000); // Check every minute
};