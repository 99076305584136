import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import ReceiptRoundedIcon from "@material-ui/icons/ReceiptRounded";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import CancelScheduleSendRoundedIcon from "@material-ui/icons/CancelScheduleSendRounded";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import UserContext from "context/user-context";
// import userContext from "context/user-context";
import { useSelector } from "react-redux";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  const [notification, setNotification] = useState(false);
  const userContext = useSelector((state) => state.userContext);

  useEffect(() => {
    if (window.localStorage.getItem("authenticatedEmployee")) {
      setNotification(true);
    }
  }, [window.localStorage.getItem("authenticatedEmployee")]);

  return (
    <UserContext.Consumer>
      {(context) => (
        <div>
          {userContext.success === true && (
            <Snackbar
              open={notification}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={5000}
              onClose={() => setNotification(false)}
            >
              <Alert
                onClose={() => setNotification(false)}
                severity={
                  window.localStorage.getItem("authenticatedEmployee") ? "success" : "error"
                }
              >
                {userContext.success === true && "Successful login"}
              </Alert>
            </Snackbar>
          )}
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <EqualizerRoundedIcon />
                    <br />
                    {context.userDetails}
                    Active Users
                  </CardIcon>
                  {/* <p className={classes.cardCategory}>Followers</p>
                <h3 className={classes.cardTitle}>+245</h3> */}
                  <p style={{ color: "black", textAlign: "center" }}>7</p>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Update />
                    Just Updated
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="success" icon>
                  <CardIcon color="success">
                    <EqualizerRoundedIcon />
                    <br />
                    Pending Orders
                  </CardIcon>
                  {/* <p className={classes.cardCategory}>Followers</p>
                <h3 className={classes.cardTitle}>+245</h3> */}
                  <p style={{ color: "black", textAlign: "center" }}>150</p>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Update />
                    For Today
                  </div>
                </CardFooter>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <EqualizerRoundedIcon />
                    <br />
                    Picked Orders
                  </CardIcon>
                  {/* <p className={classes.cardCategory}>Followers</p>
                <h3 className={classes.cardTitle}>+245</h3> */}
                  <p style={{ color: "black", textAlign: "center" }}>56</p>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <Update />
                    Current
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card chart>
                <CardHeader color="success"></CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>Daily Sales</h4>
                  <p className={classes.cardCategory}>
                    <span className={classes.successText}>
                      <ArrowUpward className={classes.upArrowCardCategory} />{" "}
                      55%
                    </span>{" "}
                    increase in today sales.
                  </p>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> updated 4 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card chart>
                <CardHeader color="warning"></CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>
                    Products at low stock levels
                  </h4>
                  <p className={classes.cardCategory}>
                    Hoodies, Socks, Shoes and Some more cool stuff
                  </p>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> last updated 2 days ago
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card chart>
                <CardHeader color="danger"></CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>
                    Known Out of Stock Items
                  </h4>
                  <p className={classes.cardCategory}>
                    Chesties, Swetties and some Cool Stuff
                  </p>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> Last Updated 1 hour ago
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomTabs
                title="A sample quick sight table"
                headerColor="primary"
                tabs={[
                  {
                    tabName: "Total Sales",
                    tabIcon: AttachMoneyRoundedIcon,
                    tabContent: (
                      // <Tasks
                      //   checkedIndexes={[0, 3]}
                      //   tasksIndexes={[0, 1, 2, 3]}
                      //   // tasks={bugs}
                      // />
                      <p>test</p>
                    ),
                  },
                  {
                    tabName: "Refunds",
                    tabIcon: ReceiptRoundedIcon,
                    tabContent: (
                      // <Tasks
                      //   checkedIndexes={[0]}
                      //   tasksIndexes={[0, 1]}
                      //   // tasks={website}
                      // />
                      <p>test</p>
                    ),
                  },
                  {
                    tabName: "Cancellations",
                    tabIcon: CancelScheduleSendRoundedIcon,
                    tabContent: (
                      // <Tasks
                      //   checkedIndexes={[1]}
                      //   tasksIndexes={[0, 1, 2]}
                      //   // tasks={server}
                      // />
                      <p>test</p>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="warning">
                  <h4 className={classes.cardTitleWhite}>Employees Stats</h4>
                  <p className={classes.cardCategoryWhite}>
                    New employees on 15th September, 2016
                  </p>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="warning"
                    tableHead={["ID", "Name", "Salary", "Country"]}
                    tableData={[
                      ["1", "Dakota Rice", "$36,738", "Niger"],
                      ["2", "Minerva Hooper", "$23,789", "Curaçao"],
                      ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
                      ["4", "Philip Chaney", "$38,735", "Korea, South"],
                    ]}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </UserContext.Consumer>
  );
}
