/* eslint-disable no-unused-vars */
import React from "react";

export default React.createContext({
  userDetails: "",
  userName: "",
  setUserName: (userName) => "",
  password: "",
  setPassword: (userPassword) => "",
  authenticated: { active: false, message: "" },
  setAuthenticated: (status, message) => {},
  notification: true,
  setNotification: (notificationValue) => {},
  handleSignIn: (user) => {},
  handleCloseProfile: () => {},
  logoutMessage: "",
  setLogoutMessage: () => "",
});
