import { QuickQuote } from "views/QuickQuote/QuickQuote";
import { OrderDetails } from "components/OrderDetails/OrderDetails";
import productCodes from "utils/productCodes";

let websocket;

export const createWebSocketConnection = (url, onMessageCallback) => {
  websocket = new WebSocket(url);

  websocket.onopen = () => {
    console.log('WebSocket connection opened');
  };

  websocket.onmessage = (event) => {
    if (typeof onMessageCallback === 'function') {
      onMessageCallback(event.data);
    }
  };

  websocket.onclose = () => {
    console.log('WebSocket connection closed');
    localStorage.setItem('websocketConnected', 'false');

  };

  websocket.onerror = (errorEvent) => {
    console.error('WebSocket encountered an error:', errorEvent);
    websocket.close();
  };
};

export const closeWebSocketConnection = () => {
  if (websocket && websocket.readyState === WebSocket.OPEN) {
    websocket.close();
  }
};

export const sendMessage = (message, pdfLinkFlag, productLabel, fitToPrintableArea) => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      const data = {
        message,
        pdfLinkFlag,
        productLabel,
        fitToPrintableArea
      };
      websocket.send(JSON.stringify(data));
    }
  };
