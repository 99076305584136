export const setEmployees = (userData: any) => {
  return {
    type: "setEmployees",
    payload: {
      userData: userData,
    },
  };
};

export const employeeDetails = (userData: any) => {
  return {
    type: "getEmployeeDetails",
    payload: {
      userData: userData,
    },
  };
};
