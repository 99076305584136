import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
// import { useBarcode } from "react-barcodes";
import b64toBlob from "b64-to-blob";
import { base64ArrayBuffer } from "../../utils/byteArrayToPdf";
// import { saveAs } from "file-saver";

// import debounce from "lodash/debounce";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import productCodes from "utils/productCodes";
import FormControlLabel from "@mui/material/FormControlLabel";

import { sendMessage } from 'components/PrinterGui/webSocketConnection';

import { SHIPPING_API, ORDERS_API, REWARDS_API } from "../../api-config";
import { extendToken } from "refresh-token/refreshToken";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const OrderDetails = (props) => {
  const {
    tab,
    orderList,
    orderId,
    handleActions,
    shippingStatus,
    onShipmentComplete,
  } = props;

  const orderMeta = orderList[orderId];

  const [tabValue, setTabValue] = useState(tab || 0);

  const [name, setName] = useState(
    `${orderMeta.shippingFirstName} ${orderMeta.shippingLastName}`
  );
  const [companyName, setCompanyName] = useState(
    `${orderMeta.shippingCompanyName || ""}`
  );
  const [phoneNumber, setPhoneNumber] = useState(
    orderMeta.shippingDaytimePhone
  );
  const [emailId, setEmailId] = useState(orderMeta.buyerEmailAddress);
  const [addrLine1, setAddrLine1] = useState(orderMeta.shippingAddressLine1);
  const [addrLine2, setAddrLine2] = useState(orderMeta.shippingAddressLine2);
  const [suburb, setSuburb] = useState(orderMeta.shippingCity);
  const [state, setState] = useState(orderMeta.shippingStateOrProvince);
  const [postCode, setPostCode] = useState(orderMeta.shippingPostalCode);
  const [country, setCountry] = useState(orderMeta.shippingCountry);
  const [loadingShipmentInfo, setLoadingShipmentInfo] = useState(true);
  const [isAddressValidated, setIsAddressValidated] = useState(false);
  const [packageConfig, setPackageConfig] = useState([]);
  const [isLoadingQuotes, setIsLoadingQuotes] = useState(false);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState({
    name: "",
    cost: "",
    partner: "",
    productId: "",
  });
  const [isAddressUpdated, setIsAddressUpdated] = useState(false);
  const [addressSearchValues, setAddressSearchValues] = useState([]);
  const [customAddressSelected, setCustomAddressSelected] = useState();
  const [selectedPackageLine, setSelectedPackageLine] = useState(0);

  const [defaultPackageConfig, setDefaultPackageConfig] = useState([]);

  const [authToLeave, setAuthToLeave] = useState(false);
  const [allowPartialDelivery, setAllowPartialDelivery] = useState(false);
  const [shippingInstructions, setShippingInstructions] = useState("");

  const [consignmentSelectionOpen, setConsignmentSelectionOpen] =
    useState(false);
  const [shipmentCreated, setShipmentCreated] = useState(false);
  const [allowCreateShipment, setAllowCreateShipment] = useState(true);
  const [isLoadingLogs, setIsLoadingLogs] = useState(true);
  const [logsData, setLogsData] = useState(null);

  const anchorRef = useRef(null);
  const productClassificationRef = useRef(null);

  let printComponentRef = useRef();
  const printRef = useRef();

  const [packageQuantity, setPackageQuantity] = useState(
    packageConfig.length > 0
      ? defaultPackageConfig[selectedPackageLine].quantity
      : ""
  );
  const [weight, setWeight] = useState(
    packageConfig.length > 0
      ? defaultPackageConfig[selectedPackageLine].weight
      : ""
  );
  const [length, setLength] = useState(
    packageConfig.length > 0
      ? defaultPackageConfig[selectedPackageLine].length
      : ""
  );
  const [width, setWidth] = useState(
    packageConfig.length > 0
      ? defaultPackageConfig[selectedPackageLine].width
      : ""
  );
  const [height, setHeight] = useState(
    packageConfig.length > 0
      ? defaultPackageConfig[selectedPackageLine].height
      : ""
  );

  const [quotes, setQuotes] = useState();

  const [myFastwayItemCode, setMyFastwayItemCode] = useState("ATL");
  const [isAddingIntlDetails, setIsAddingIntlDetails] = useState(false);
  const [commercialValue, setCommercialValue] = useState(true);
  const [itemDescription, setItemDescription] = useState();
  const [importReferenceNumber, setImportReferenceNumber] = useState(
    orderMeta._id
  );
  const [exportDeclarationNumber, setExportDeclarationNumber] = useState();
  const [intlCountry, setIntlCountry] = useState("AU");
  const [tariffCode, setTariffCode] = useState();
  const [intlQty, setIntlQty] = useState(1);
  const [intlUnitValue, setIntlUnitValue] = useState();
  const [intlWeight, setIntlWeight] = useState();
  const [intlDescription, setIntlDescription] = useState();
  const [tariffConcession, setTariffConcession] = useState();
  const [isIntlDetailsUnfinished, setIsIntlDetailsUnfinished] = useState(true);
  const [selectedProductClassification, setSelectedProductClassification] =
    useState(0);
  const [productClassificationOpen, setProductClassificationOpen] =
    useState(false);

  const handleIntlDetailsCancellation = () => {
    setCommercialValue();
    setItemDescription();
    setImportReferenceNumber();
    setExportDeclarationNumber();
    setIntlCountry();
    setTariffCode();
    setIntlQty();
    setIntlUnitValue();
    setIntlWeight();
    setIntlDescription();
    setTariffConcession();
    setIsAddingIntlDetails(false);
  };

  useEffect(() => {
    if (
      commercialValue &&
      itemDescription &&
      importReferenceNumber &&
      intlCountry &&
      intlQty &&
      intlUnitValue &&
      intlWeight &&
      intlDescription
    ) {
      setIsIntlDetailsUnfinished(false);
    }
  }, [
    commercialValue,
    itemDescription,
    importReferenceNumber,
    intlCountry,
    intlQty,
    intlUnitValue,
    intlWeight,
    intlDescription,
  ]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  async function validateAddress(manualUpdate, usePostcode) {
    const controller = new AbortController();
    const signal = controller.signal;
    const token = localStorage.getItem("authenticatedEmployee");
    const employeeId = localStorage.getItem("employeeId");

    console.log("Token: ", token);
    console.log("Employee ID: ", employeeId);
    await extendToken();

    return fetch(`${SHIPPING_API}/api/v2/suburbOrPostcode`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`},
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        suburbOrPostcode: usePostcode ? postCode : suburb,
      }),
      signal,
    })
      .then((response) => response.json())
      .then((res) => {
        res.result.length > 0 &&
          res.result.map((r) => {
            if (r.Postcode === postCode) {
              setState(r.State);
              setIsAddressValidated(true);
            }
          });

        manualUpdate &&
          (setIsAddressUpdated(true), setAddressSearchValues(res.result));

        setLoadingShipmentInfo(false);
      })
      .catch((err) => {
        console.log("error validating address", err);
      });
  }

  const getPackageConfigs = async () => {
    await extendToken();
    return fetch(`${ORDERS_API}/api/v2/customPackages`, {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
    })
      .then((response) => response.json())
      .then((res) => {
        setDefaultPackageConfig(res);

        // setPackageConfig(() => [...packageConfig, ...[res]]);
      })
      .catch((err) => {
        console.log("error fetching shipment quotes", err);
        setPackageConfig(undefined);
      });
  };

  const loadLogs = async () => {
    await extendToken();
    return fetch(`${ORDERS_API}/api/v2/orderHistory`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        orderID: orderMeta._id,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setIsLoadingLogs(false);

        setLogsData(res.orderHistoryList);
      })
      .catch((err) => {
        console.log("error fetching logs", err);
        setIsLoadingLogs(false);
      });
  };

  const handleAddPackage = () => {
    // console.log(...[packageConfig], defaultPackageConfig);
    // console.log(defaultPackageConfig);
    // setSelectedConsignmentIndex(() => [...selectedConsignmentIndex, 0]);
    // setPackageConfig(() => [...packageConfig, defaultPackageConfig]);
  };

  const getQuote = async () => {
    setIsLoadingQuotes(true);
    await extendToken();
    return fetch(`${SHIPPING_API}/api/v2/getQuote`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        address: {
          name: name,
          companyName: `${orderMeta.shippingCompanyName}`,
          addressLine1: addrLine1,
          addressLine2: addrLine2,
          city: suburb,
          stateOrProvince: state,
          postalCode: postCode,
          country: country,
          phoneNumber: phoneNumber,
        },
        items: [
          {
            Reference: "12345",
            Quantity: packageQuantity,
            Weight: weight,
            Length: length,
            Width: width,
            Height: height,
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        // Ensure the properties exist to avoid errors
        const siteName = orderMeta?.siteName;
        const orderTags = orderMeta?.orderTags;
      
        // Create a new object for quotes to avoid mutation
        const newQuotes = { ...res.quotes };
      
        if (siteName === "Shopify") {
          if (orderTags === "OzdingoShopping") {
            // Delete both for specific Shopify tag
            delete newQuotes.MYFASTWAY;
            delete newQuotes.INHOUSE;
          } else {
            // Delete only MYFASTWAY for general Shopify and Amazon Seller Central - AU
            delete newQuotes.MYFASTWAY;
          }
        } else if (siteName === "Amazon Seller Central - AU") {
          delete newQuotes.MYFASTWAY;
        }

        // Additional processing for MYFASTWAY
        if (newQuotes.MYFASTWAY && newQuotes.MYFASTWAY.carrierQuotes.length > 0) {
          let labelQuote = newQuotes.MYFASTWAY.carrierQuotes.find(q => q.productId === "Labels");
          let totalServicePrice = newQuotes.MYFASTWAY.carrierQuotes.reduce((total, quote) => {
            if (quote.productId === "Service") {
              return total + parseFloat(quote.price);
            }
            return total;
          }, 0);

          // If Labels quote found, add the total service price to it and round to two decimals
          if (labelQuote) {
            labelQuote.price = parseFloat((labelQuote.price + totalServicePrice).toFixed(2));
          }

          // Filter out everything but Labels
          newQuotes.MYFASTWAY.carrierQuotes = labelQuote ? [labelQuote] : [];
        }
      
        // Update the state with the newQuotes object
        setQuotes(newQuotes);

        const cheapestEParcelOption =
          res.quotes.EPARCEL.carrierQuotes?.length > 0 &&
          res.quotes.EPARCEL.carrierQuotes.reduce((prev, curr) => {
            return Number(prev.price) < Number(curr.price) ? prev : curr;
          });
        const cheapestDfeOption =
          res.quotes.DIRECTFREIGHT.carrierQuotes?.length > 0 &&
          res.quotes.DIRECTFREIGHT.carrierQuotes.reduce((prev, curr) => {
            return Number(prev.price) < Number(curr.price) ? prev : curr;
          });
        const cheapestMyFastWayOption =
          res.quotes.MYFASTWAY.carrierQuotes?.length > 0 &&
          res.quotes.MYFASTWAY.carrierQuotes.reduce((prev, curr) => {
            return Number(prev.price) < Number(curr.price) ? prev : curr;
          });
        const cheapestAuPostParcel =
          res.quotes.INHOUSE.carrierQuotes?.length > 0 &&
          res.quotes.INHOUSE.carrierQuotes.reduce((prev, curr) => {
            return Number(prev.price) < Number(curr.price) ? prev : curr;
          });

        let cheapestDeliveryOptions = [];
        cheapestDeliveryOptions.push(
          cheapestEParcelOption,
          cheapestDfeOption,
          cheapestMyFastWayOption,
          cheapestAuPostParcel
        );

        const consolidatedCheapDeliveryOption = cheapestDeliveryOptions
          .filter(Boolean)
          .reduce((prev, curr) => {
            return Number(prev.price) < Number(curr.price) ? prev : curr;
          });

        let cheapestDeliveryCourier = cheapestDeliveryOptions
          .map((d, key) => {
            if (d === consolidatedCheapDeliveryOption) {
              return key;
            }
          })
          .filter(Boolean)[0];

        switch (cheapestDeliveryCourier) {
          case 0:
            cheapestDeliveryCourier = "EPARCEL";
            break;
          case 1:
            cheapestDeliveryCourier = "DIRECTFREIGHT";
            break;
          case 2:
            cheapestDeliveryCourier = "MYFASTWAY";
            break;
          case 3:
            cheapestDeliveryCourier = "INHOUSE";
            break;
          default:
            cheapestDeliveryCourier = "";
        }

        setSelectedDeliveryOption({
          name: consolidatedCheapDeliveryOption.parcelType,
          cost: consolidatedCheapDeliveryOption.price,
          partner: cheapestDeliveryCourier,
        });

        setIsLoadingQuotes(false);
      })
      .catch((err) => {
        console.log("error fetching shipment quotes", err);
        setIsLoadingQuotes(false);
      });
  };

  const handleMenuItemClick = (index) => {
    setSelectedPackageLine(index);

    setPackageQuantity(defaultPackageConfig[index].quantity);
    setWeight(defaultPackageConfig[index].weight);
    setLength(defaultPackageConfig[index].length);
    setWidth(defaultPackageConfig[index].width);
    setHeight(defaultPackageConfig[index].height);
  };

  const handleConsignmentToggle = () => {
    setConsignmentSelectionOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setConsignmentSelectionOpen(false);
  };

  const handleClassificationToggle = () => {
    setProductClassificationOpen((prevOpen) => !prevOpen);
  };

  const handleClassificationClose = (event) => {
    if (
      productClassificationRef.current &&
      productClassificationRef.current.contains(event.target)
    ) {
      return;
    }

    setProductClassificationOpen(false);
  };

  useEffect(() => {
    if (tabValue === 1) {
      getPackageConfigs();
      country === "AU" ? validateAddress() : setLoadingShipmentInfo(false);
    }

    if (tabValue === 2) {
      loadLogs();
    }
  }, [tabValue]);

  useEffect(() => {
    if (defaultPackageConfig.length > 0) {
      setPackageQuantity(defaultPackageConfig[selectedPackageLine].quantity);

      setWeight(defaultPackageConfig[selectedPackageLine].weight);
      setLength(defaultPackageConfig[selectedPackageLine].length);
      setWidth(defaultPackageConfig[selectedPackageLine].width);
      setHeight(defaultPackageConfig[selectedPackageLine].height);
    }
  }, [defaultPackageConfig]);

  const openNewTab = (response) => {
    let contentType = "application/pdf";
    let sliceSize = 512;

    let byteCharacters = atob(response);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    blob = b64toBlob(response, contentType);
    let blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl);
  };

  const productClassifications = [
    {
      name: "Sale of Goods",
      value: "SALE OF GOODS",
    },
    {
      name: "Gift",
      value: "GIFT",
    },
    {
      name: "Commercial Sample",
      value: "COMMERCIAL SAMPLE",
    },
    {
      name: "Other",
      value: "OTHER",
    },
    {
      name: "Returned Goods",
      value: "RETURNED GOODS",
    },
  ];
  const createShipment = async () => {
    if (selectedDeliveryOption.name === "MYFASTWAY" && !phoneNumber) {
      setShipmentCreated(false);

      return;
    }
    await extendToken();

    return fetch(`${SHIPPING_API}/api/v2/createShipment`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`},
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        orderIds: orderMeta.orderIds,
        address: {
          name: name,
          companyName: companyName || "",
          addressLine1: addrLine1,
          addressLine2: addrLine2,
          city: suburb,
          stateOrProvince: state,
          postalCode: postCode,
          country: country,
          phone: phoneNumber,
          email: emailId,
        },
        items: [
          {
            classificationType: country != "AU" ? "OTHER" : null,

            commercialValue: country != "AU" ? commercialValue : null,
            descriptionOfOther:
              country != "AU"
                ? productClassifications[selectedProductClassification].name
                : null,
            exportDeclerationNumber:
              country != "AU" ? exportDeclarationNumber || "" : null,
            importReferenceNumber:
              country != "AU" ? importReferenceNumber : null,
            itemContent:
              country != "AU"
                ? [
                    {
                      countryOfOrigin: intlCountry,
                      description: intlDescription,
                      sku: orderMeta.items[0].sku,
                      quantity: parseInt(intlQty),
                      tariffCode: tariffCode || null,
                      value: parseFloat(intlUnitValue),
                      weight: parseFloat(intlWeight),
                      itemContentsReference: "",
                    },
                  ]
                : null,
            itemDescription: country != "AU" ? itemDescription || "" : null,
            Reference: orderMeta._id,
            Quantity: packageQuantity,
            Weight: weight,
            Length: length,
            Width: width,
            Height: height,
          },
        ],
        courierName: selectedDeliveryOption.partner,
        shippingClass: orderMeta.requestedShippingClass,
        parcelType: selectedDeliveryOption.name,
        productId: selectedDeliveryOption.productId,
        price: selectedDeliveryOption.cost,
        labelNumber: shippingInstructions,
        specialInstructions: shippingInstructions,
        authorityToLeave: country === "AU" ? authToLeave : undefined,
        allowPartialDelivery:
          country === "AU" ? allowPartialDelivery : undefined,
        transitCover:
          selectedDeliveryOption.partner === "EPARCEL"
            ? Number(shippingInstructions)
            : undefined,
        myFastwayItemCode: myFastwayItemCode,
      }),
    })
      .then(async (response) => {
        await ingestProcessingRewards();
        return response.json();
      })
      .then((res) => {
        var productLabel = null;
        if(productCodes.includes(selectedDeliveryOption.productId)) {
          productLabel = "A4"
        } else {
          productLabel = "Label Printer"
        }

        const base64 =
            res.pdfData && base64ArrayBuffer(JSON.parse(res.pdfData));
        
        const isLocalStorageTrue = localStorage.getItem('websocketConnected') === 'true';

        if (isLocalStorageTrue && res.pdfData) {
          sendMessage(base64, false, productLabel, false);
          onShipmentComplete()
        }
        else if (isLocalStorageTrue && !res.pdfData && res.labels) {
          res.labels.map((l) => {
            sendMessage(l.pdfLink, true, productLabel, selectedDeliveryOption.partner === "INHOUSE"? true : false);
            onShipmentComplete();
          })
        }
        else {

          res.pdfData && (openNewTab(base64), onShipmentComplete());

          !res.pdfData && res.labels
            ? res.labels.map((l) => {
                window.open(l.pdfLink);
                onShipmentComplete();
              })
            : (setShipmentCreated(true), printRef?.current?.click());
        }
      })
      .catch((err) => {
        console.log("error fetching shipment quotes", err);
        setShipmentCreated(false);
      });
  };

  const ingestProcessingRewards = async () => {
    await extendToken();
    return fetch(`${REWARDS_API}/api/v2/ingestRewards`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`},
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        transactionType: "PROCESSED",
        orderIds: [orderMeta._id],
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log("error ingesting rewards for picked orders", err);
      });
  };

  // const CustomBarcode = () => {
  //   const { inputRef } = useBarcode({
  //     value: orderMeta.items[0].barcode || "93653247065",
  //     options: {
  //       height: "25",
  //       width: "1",
  //       fontSize: "12",
  //     },
  //   });

  //   return (
  //     <div
  //       style={{
  //         minWidth: "100px",
  //       }}
  //     >
  //       <img ref={inputRef} />
  //     </div>
  //   );
  // };

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <Stack
          spacing={3}
          paddingLeft={5}
          sx={{
            transform: "rotate(90deg)",
          }}
        >
          <Box
            sx={{
              fontSize: "16px",
            }}
            paddingLeft={3}
          >
            {` From:`}
            <br /> {` Eg Trade P/L, PO BOX 222`}
            <br /> {` Heidelberg VIC 3084`}
            <br />
            <br />
          </Box>

          <Box
            paddingLeft={10}
            paddingRight={3}
            sx={{
              fontSize: "18px",
            }}
          >
            <Box
              sx={{
                fontSize: "18px",
                fontWeight: "bolder",
              }}
            >
              To: <br />
              {name}
              <br />
            </Box>
            {companyName && (
              <>
                {companyName}
                <br />
              </>
            )}
            {addrLine1 && (
              <>
                {addrLine1}
                <br />
              </>
            )}
            {addrLine2 && (
              <>
                {addrLine2}
                <br />
              </>
            )}
            {suburb && (
              <>
                {suburb}
                <br />
              </>
            )}
            {state} {postCode}
          </Box>

          {/* <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              width: "100%",
            }}
          >
            <Box>ENV</Box>
            <Box>
              <CustomBarcode />
            </Box>
          </Stack> */}
        </Stack>
      );
    }
  }

  return (
    <Box padding={3}>
      Order Details
      <Dialog
        open={isAddingIntlDetails}
        onClose={() => setIsAddingIntlDetails(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {"Add consignment details"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      style={{
                        color: "#90CAF9",
                      }}
                      indeterminate={false}
                      checked={commercialValue}
                      onChange={() => setCommercialValue(!commercialValue)}
                    />
                  }
                  label="Whether the item has commercial value?"
                  sx={{
                    width: "250px",
                  }}
                />
                <TextField
                  required
                  id="standard-basic"
                  label="Item Description"
                  value={itemDescription}
                  onChange={(e) => setItemDescription(e.target.value)}
                  variant="standard"
                  sx={{
                    width: "300px",
                  }}
                />
              </Stack>

              <Stack direction="row" spacing={1}>
                <TextField
                  required
                  id="standard-basic"
                  label="Import Reference Number"
                  value={importReferenceNumber}
                  onChange={(e) => setImportReferenceNumber(e.target.value)}
                  variant="standard"
                  sx={{
                    width: "250px",
                  }}
                />
                <TextField
                  id="standard-basic"
                  label="Export declaration number"
                  value={exportDeclarationNumber}
                  onChange={(e) => setExportDeclarationNumber(e.target.value)}
                  variant="standard"
                  sx={{
                    width: "300px",
                  }}
                  error={
                    exportDeclarationNumber?.length < 9 ||
                    exportDeclarationNumber?.length > 12
                  }
                />
              </Stack>

              <Stack paddingTop={2}>
                Product Classification
                <ButtonGroup
                  variant="contained"
                  ref={productClassificationRef}
                  aria-label="split button"
                  style={{
                    width: "fit-content",
                  }}
                >
                  <Button
                    onClick={undefined}
                    style={{
                      minWidth: "150px",
                    }}
                  >
                    {productClassifications[selectedProductClassification]
                      ?.name || ""}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={
                      productClassificationOpen
                        ? "split-button-menu"
                        : undefined
                    }
                    aria-expanded={
                      productClassificationOpen ? "true" : undefined
                    }
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleClassificationToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={productClassificationOpen}
                  anchorEl={productClassificationRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper
                        style={{
                          maxHeight: "100px",
                          overflow: "scroll",
                        }}
                      >
                        <ClickAwayListener
                          onClickAway={handleClassificationClose}
                        >
                          <MenuList
                            id="split-button-menu"
                            style={{
                              maxHeight: "100px",
                              overflow: "scroll",
                            }}
                          >
                            {productClassifications.map((option, index) => (
                              <MenuItem
                                key={index}
                                selected={
                                  index === selectedProductClassification
                                }
                                onClick={() => {
                                  setSelectedProductClassification(index);
                                  setProductClassificationOpen();
                                }}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Stack>

              <Box
                paddingTop={15}
                sx={{
                  fontWeight: "bold",
                }}
              >
                Item Details
              </Box>
              <Stack direction="row" spacing={1}>
                <TextField
                  id="standard-basic"
                  label="Country of Origin"
                  value={intlCountry}
                  onChange={(e) => setIntlCountry(e.target.value)}
                  variant="standard"
                  sx={{
                    width: "250px",
                  }}
                />
                <TextField
                  id="standard-basic"
                  label="Tariff Code"
                  value={tariffCode}
                  onChange={(e) => setTariffCode(e.target.value)}
                  variant="standard"
                  sx={{
                    width: "300px",
                  }}
                  type="number"
                />
              </Stack>
              <Stack direction="row" spacing={1}>
                <TextField
                  required
                  id="standard-basic"
                  label="Quantity"
                  value={intlQty}
                  onChange={(e) => setIntlQty(e.target.value)}
                  variant="standard"
                  sx={{
                    width: "180px",
                  }}
                />
                <TextField
                  required
                  id="standard-basic"
                  label="Unit value (AUD)"
                  value={intlUnitValue}
                  onChange={(e) => setIntlUnitValue(e.target.value)}
                  variant="standard"
                  sx={{
                    width: "180px",
                  }}
                />
                <TextField
                  required
                  id="standard-basic"
                  label="Weight(kg)"
                  value={intlWeight}
                  onChange={(e) => setIntlWeight(e.target.value)}
                  variant="standard"
                  sx={{
                    width: "180px",
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={1}>
                <TextField
                  required
                  id="standard-basic"
                  label="Description"
                  value={intlDescription}
                  onChange={(e) => setIntlDescription(e.target.value)}
                  variant="standard"
                  sx={{
                    width: "250px",
                  }}
                />
                <TextField
                  id="standard-basic"
                  label="Tariff Concession"
                  value={tariffConcession}
                  onChange={(e) => setTariffConcession(e.target.value)}
                  variant="standard"
                  sx={{
                    width: "300px",
                  }}
                />
              </Stack>
            </Stack>

            <Stack direction="row" spacing={4} paddingTop={4}>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleIntlDetailsCancellation()}
              >
                Cancel
              </Button>
              <Button
                disabled={isIntlDetailsUnfinished}
                variant="contained"
                color="success"
                onClick={() => setIsAddingIntlDetails(false)}
              >
                Save
              </Button>
            </Stack>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="Order Details"
        >
          <Tab label="Product Details" {...a11yProps(0)} />
          <Tab label="Shipping" {...a11yProps(1)} />
          <Tab label="Logs" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        {orderMeta && (
          <>
            <Stack>
              <Box>Location: {orderMeta.items[0].warehouseLocation}</Box>
              <Box>
                Excess Stock Location: {orderMeta.items[0].excessStock || "N/A"}
              </Box>
              <Box>Qty left in stock: {orderMeta.items[0].availableQty}</Box>
            </Stack>
            <br />
            ACTIONS
            <Stack spacing={2}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Box
                  onClick={() => {
                    orderMeta.pickingStatus === "Picked" &&
                      handleActions(
                        [orderMeta._id],
                        undefined,
                        orderMeta.pickingStatus === "Unpicked"
                          ? true
                          : orderMeta.pickingStatus === "Picked"
                          ? false
                          : undefined,
                        undefined,
                        undefined
                      );
                  }}
                >
                  {orderMeta.pickingStatus === "Picked" ? (
                    <Button
                      variant="outlined"
                      color="error"
                      disabled={
                        shippingStatus.length === 1 &&
                        shippingStatus.includes("PickAndPack")
                      }
                    >
                      Mark as unpicked
                    </Button>
                  ) : (
                    <Button variant="outlined" disabled>
                      Not Picked
                    </Button>
                  )}
                </Box>

                <Box
                  style={{
                    color:
                      orderMeta.issueStatus === "Issue"
                        ? "#FC2E20"
                        : "lightgrey",
                  }}
                  onClick={() => {
                    handleActions(
                      [orderMeta._id],
                      undefined,
                      undefined,
                      orderMeta.issueStatus === "NoIssue"
                        ? true
                        : orderMeta.issueStatus === "Issue"
                        ? false
                        : undefined,
                      undefined
                    );
                  }}
                >
                  {orderMeta.issueStatus === "Issue" ? (
                    <Button
                      variant="outlined"
                      color="success"
                      disabled={
                        shippingStatus.length === 1 &&
                        shippingStatus.includes("PickAndPack")
                      }
                    >
                      Mark as No Issue
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="error"
                      disabled={
                        shippingStatus.length === 1 &&
                        shippingStatus.includes("PickAndPack")
                      }
                    >
                      Mark as Issue
                    </Button>
                  )}
                </Box>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Box
                  onClick={() => {
                    orderMeta.pickingStatus === "Picked" &&
                      handleActions(
                        [orderMeta._id],
                        undefined,
                        orderMeta.pickingStatus === "Unpicked"
                          ? true
                          : orderMeta.pickingStatus === "Picked"
                          ? false
                          : undefined,
                        undefined,
                        undefined
                      );
                  }}
                >
                  {orderMeta.pickingStatus === "Picked" ? (
                    <Button
                      variant="outlined"
                      color="error"
                      disabled={
                        shippingStatus.length === 1 &&
                        shippingStatus.includes("PickAndPack")
                      }
                    >
                      Mark as unpicked
                    </Button>
                  ) : (
                    <Button variant="outlined" disabled>
                      Not Picked
                    </Button>
                  )}
                </Box>

                <Box
                  onClick={() => {
                    handleActions(
                      [orderMeta._id],
                      undefined,
                      undefined,
                      undefined,
                      orderMeta.shippingStatus === "Unshipped"
                        ? true
                        : orderMeta.shippingStatus === "Shipped"
                        ? false
                        : undefined
                    );
                  }}
                >
                  {orderMeta.shippingStatus === "Shipped" ? (
                    <Button
                      variant="outlined"
                      color="error"
                      disabled={
                        shippingStatus.length === 1 &&
                        shippingStatus.includes("PickAndPack")
                      }
                    >
                      Mark as Unshipped
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="success"
                      disabled={
                        shippingStatus.length === 1 &&
                        shippingStatus.includes("PickAndPack")
                      }
                    >
                      Mark as Shipped
                    </Button>
                  )}
                </Box>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Button
                  variant="outlined"
                  disabled={!shippingStatus.includes("PickAndPack")}
                  onClick={() => {
                    handleActions(
                      [orderMeta._id],
                      undefined,
                      undefined,
                      undefined,
                      "Dispatched"
                    );
                  }}
                >
                  Dispatch
                </Button>
              </Stack>
            </Stack>
            <br />
            {orderMeta.items[0].isBundle && (
              <>
                Bundle Details
                {orderMeta.items[0].bundleComponents.map((b, key) => (
                  <Stack key={key}>
                    <Box>Title: {b.title}</Box>
                    <Box>SKU: {b.bundleSku}</Box>
                    <Box>Location: {b.warehouseLocation}</Box>
                    <Box>Quantity: {b.quantity}</Box>
                    <Box>Varying Attributes: {b.varyingAttributes}</Box>
                    <Box>Qty left in stock: {b.availableQty}</Box>
                  </Stack>
                ))}
              </>
            )}
          </>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {loadingShipmentInfo ? (
          <CircularProgress />
        ) : (
          orderMeta && (
            <Stack spacing={3}>
              <Stack
                style={{
                  backgroundColor: "#f9fafc",
                  padding: "15px",
                  boxShadow:
                    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                  borderRadius: "16px",
                }}
                spacing={2}
              >
                <Box>Customer Details</Box>
                <Stack direction="row" spacing={2}>
                  <TextField
                    id="standard-basic"
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    label="Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    variant="standard"
                  />
                </Stack>

                <Stack direction="row" spacing={2}>
                  <TextField
                    id="standard-basic"
                    label="Email"
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    label="Address Line 1"
                    value={addrLine1}
                    onChange={(e) => setAddrLine1(e.target.value)}
                    variant="standard"
                    error={addrLine1?.length > 40}
                  />
                  <TextField
                    id="standard-basic"
                    label="Address Line 2"
                    value={addrLine2}
                    onChange={(e) => setAddrLine2(e.target.value)}
                    variant="standard"
                    error={addrLine2?.length > 40}
                  />
                </Stack>

                <Stack direction="row" spacing={2}>
                  <TextField
                    error={!isAddressValidated && country === "AU"}
                    id="standard-basic"
                    label="Suburb"
                    value={suburb}
                    onChange={(e) => {
                      setSuburb(e.target.value);
                      setTimeout(() => validateAddress(true), 2000);
                    }}
                    variant="standard"
                    helperText={
                      !isAddressValidated &&
                      country === "AU" &&
                      "Suburb & Postcode mismatch"
                    }
                  />
                  <TextField
                    id="standard-basic"
                    label="State"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    variant="standard"
                  />
                  <TextField
                    error={!isAddressValidated && country === "AU"}
                    id="standard-basic"
                    label="Postcode"
                    value={postCode}
                    onChange={(e) => {
                      setPostCode(e.target.value);
                      setTimeout(() => validateAddress(true, true), 2000);
                    }}
                    variant="standard"
                    helperText={
                      !isAddressValidated &&
                      country === "AU" &&
                      "Suburb & Postcode mismatch"
                    }
                  />
                  <TextField
                    id="standard-basic"
                    label="Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    variant="standard"
                  />
                </Stack>

                {isAddressUpdated && (
                  <Box>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        backdropFilter: "blur(20px)",
                        paddingBottom: "10px",
                      }}
                    >
                      Address Search Results
                    </Box>
                    <Stack
                      spacing={1}
                      sx={{
                        maxHeight: "150px",
                        overflow: "scroll",
                      }}
                    >
                      {addressSearchValues.map((a, key) => (
                        <Stack
                          direction="row"
                          spacing={5}
                          padding={1}
                          key={key}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "300",
                            backgroundColor:
                              customAddressSelected === key
                                ? "#E3F2FD"
                                : "#efefef",
                            borderRadius: "6px",

                            // "&:hover": {
                            //   background: "#efefef",
                            // },
                          }}
                          alignItems="center"
                          onClick={() => {
                            setCustomAddressSelected(key);
                            setSuburb(a.Town);
                            setState(a.State);
                            setPostCode(a.Postcode);
                          }}
                        >
                          <Box sx={{ width: "150px" }}>{a.Town}</Box>
                          <Box sx={{ width: "50px" }}> {a.State}</Box>
                          <Box sx={{ width: "50px" }}>{a.Postcode}</Box>
                        </Stack>
                      ))}
                    </Stack>
                  </Box>
                )}
              </Stack>

              <Stack
                style={{
                  backgroundColor: "#f9fafc",
                  padding: "15px",
                  boxShadow:
                    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                  borderRadius: "16px",
                }}
                spacing={2}
              >
                <Box>Shipment Items</Box>

                <Stack direction="row" spacing={2}>
                  <Stack>
                    <ButtonGroup
                      variant="contained"
                      ref={anchorRef}
                      aria-label="split button"
                    >
                      <Button
                        onClick={undefined}
                        style={{
                          minWidth: "150px",
                        }}
                      >
                        {defaultPackageConfig[selectedPackageLine]
                          ?.packageName || ""}
                      </Button>
                      <Button
                        size="small"
                        aria-controls={
                          consignmentSelectionOpen
                            ? "split-button-menu"
                            : undefined
                        }
                        aria-expanded={
                          consignmentSelectionOpen ? "true" : undefined
                        }
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleConsignmentToggle}
                      >
                        <ArrowDropDownIcon />
                      </Button>
                    </ButtonGroup>
                    <Popper
                      open={consignmentSelectionOpen}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper
                            style={{
                              maxHeight: "100px",
                              overflow: "scroll",
                            }}
                          >
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                id="split-button-menu"
                                style={{
                                  maxHeight: "100px",
                                  overflow: "scroll",
                                }}
                              >
                                {defaultPackageConfig.map((option, index) => (
                                  <MenuItem
                                    key={index}
                                    selected={index === selectedPackageLine}
                                    onClick={() => {
                                      handleMenuItemClick(index);
                                    }}
                                  >
                                    {option.packageName}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </Stack>

                  <Stack spacing={2}>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        id="standard-basic"
                        label="Quantity"
                        value={packageQuantity}
                        onChange={(e) => setPackageQuantity(e.target.value)}
                        variant="standard"
                        sx={{
                          maxWidth: "65px",
                        }}
                      />
                      <TextField
                        id="standard-basic"
                        label="Weight(kg)"
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                        variant="standard"
                        sx={{
                          maxWidth: "65px",
                        }}
                        disabled={selectedPackageLine != 0}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <TextField
                      id="standard-basic"
                      label="L(cm)"
                      value={length}
                      onChange={(e) => setLength(e.target.value)}
                      variant="standard"
                      sx={{
                        maxWidth: "65px",
                      }}
                      disabled={selectedPackageLine != 0}
                    />
                    <TextField
                      id="standard-basic"
                      label="W(cm)"
                      value={width}
                      onChange={(e) => setWidth(e.target.value)}
                      variant="standard"
                      sx={{
                        maxWidth: "65px",
                      }}
                      disabled={selectedPackageLine != 0}
                    />
                    <TextField
                      id="standard-basic"
                      label="H(cm)"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      variant="standard"
                      sx={{
                        maxWidth: "65px",
                      }}
                      disabled={selectedPackageLine != 0}
                    />
                  </Stack>
                </Stack>

                {packageConfig.map((pc, key) => (
                  <Stack direction="row" spacing={2} key={key}>
                    {/* <ButtonGroup
                      variant="contained"
                      ref={anchorRef}
                      aria-label="split button"
                    >
                      <Button
                        onClick={undefined}
                        style={{
                          minWidth: "150px",
                        }}
                      >
                        {pc[selectedConsignmentIndex[key]]?.packageName || ""}
                      </Button>
                      <Button
                        size="small"
                        aria-controls={
                          consignmentSelectionOpen
                            ? "split-button-menu"
                            : undefined
                        }
                        aria-expanded={
                          consignmentSelectionOpen ? "true" : undefined
                        }
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleConsignmentToggle}
                      >
                        <ArrowDropDownIcon />
                      </Button>
                    </ButtonGroup> */}
                    {/* <Popper
                      open={consignmentSelectionOpen}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper
                            style={{
                              maxHeight: "100px",
                              overflow: "scroll",
                            }}
                          >
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                id="split-button-menu"
                                style={{
                                  maxHeight: "100px",
                                  overflow: "scroll",
                                }}
                              >
                                {packageConfig[key].map((option, index) => (
                                  <MenuItem
                                    key={index}
                                    selected={
                                      index === selectedConsignmentIndex[key]
                                    }
                                    onClick={(event) => {
                                      setSelectedPackageLine(key);
                                      handleMenuItemClick(event, index, key);
                                    }}
                                  >
                                    {option.packageName}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper> */}
                    {/* 
                    <TextField
                      id="standard-basic"
                      label="Quantity"
                      value={packageQuantity[key]}
                      onChange={(e) => setPackageQuantity(e.target.value)}
                      variant="standard"
                      sx={{
                        maxWidth: "65px",
                      }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Weight(kg)"
                      value={weight[key]}
                      onChange={(e) => setWeight(e.target.value)}
                      variant="standard"
                      sx={{
                        maxWidth: "65px",
                      }}
                      disabled={selectedConsignmentIndex[key] != 0}
                    />
                    <TextField
                      error={!isAddressValidated}
                      id="standard-basic"
                      label="L(cm)"
                      value={length[key]}
                      onChange={(e) => setLength(e.target.value)}
                      variant="standard"
                      sx={{
                        maxWidth: "65px",
                      }}
                      helperText={
                        !isAddressValidated && "Suburb & Postcode mismatch"
                      }
                      disabled={selectedConsignmentIndex[key] != 0}
                    />
                    <TextField
                      id="standard-basic"
                      label="W(cm)"
                      value={width[key]}
                      onChange={(e) => setWidth(e.target.value)}
                      variant="standard"
                      sx={{
                        maxWidth: "65px",
                      }}
                      disabled={selectedConsignmentIndex[key] != 0}
                    />
                    <TextField
                      id="standard-basic"
                      label="H(cm)"
                      value={height[key]}
                      onChange={(e) => setHeight(e.target.value)}
                      variant="standard"
                      sx={{
                        maxWidth: "65px",
                      }}
                      disabled={selectedConsignmentIndex[key] != 0}
                    /> */}
                  </Stack>
                ))}

                <Button
                  onClick={() => handleAddPackage()}
                  sx={{
                    marginRight: "250px",
                  }}
                  // disabled={selectedPackageLine != 0}
                  disabled
                >
                  Add Package
                </Button>

                <Button
                  variant="contained"
                  style={{
                    marginTop: "150px",
                  }}
                  onClick={() => getQuote()}
                >
                  Get Quote
                </Button>

                {isLoadingQuotes && <CircularProgress />}
                {quotes && (
                  <Stack spacing={3}>
                    <Stack
                      direction="row"
                      spacing={5}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Stack
                        sx={{
                          boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
                          borderRadius: "6px",
                          width: "50%",
                        }}
                        padding={2}
                        spacing={2}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            backgroundColor: "#1976d2",
                            color: "white",
                          }}
                          paddingY={1}
                        >
                          Delivery Rates Comparision
                        </Box>

                        <Stack
                          spacing={2}
                          sx={{
                            maxHeight: "300px",
                            overflow: "Scroll",
                          }}
                        >
                          <Stack>
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              eParcel
                            </Box>
                            {quotes.EPARCEL && quotes.EPARCEL.carrierQuotes?.length > 0 &&
                              quotes.EPARCEL.carrierQuotes.map((a, key) => (
                                <Stack key={key}>
                                  <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "300",
                                      backgroundColor:
                                        selectedDeliveryOption.name ===
                                        a.parcelType
                                          ? "#E3F2FD"
                                          : "",
                                      borderRadius: "6px",

                                      "&:hover": {
                                        background: "#efefef",
                                      },
                                    }}
                                    paddingX={0.8}
                                    margin={0.3}
                                    onClick={() =>
                                      setSelectedDeliveryOption({
                                        name: a.parcelType,
                                        cost: a.price,
                                        partner: "EPARCEL",
                                        productId: a.productId,
                                      })
                                    }
                                  >
                                    <Box paddingY={0.5}>{a.parcelType}</Box>
                                    <Box
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {a.price}
                                    </Box>
                                  </Stack>
                                </Stack>
                              ))}
                          </Stack>

                          <Stack>
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              Direct Freight
                            </Box>
                            {quotes.DIRECTFREIGHT && quotes.DIRECTFREIGHT.carrierQuotes?.length > 0 &&
                              quotes.DIRECTFREIGHT.carrierQuotes.map((f, key) => (
                                <Stack key={key}>
                                  <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "300",
                                      backgroundColor:
                                        selectedDeliveryOption.name ===
                                        f.parcelType
                                          ? "#E3F2FD"
                                          : "",
                                      borderRadius: "6px",

                                      "&:hover": {
                                        background: "#efefef",
                                      },
                                    }}
                                    paddingX={0.8}
                                    margin={0.3}
                                    onClick={() =>
                                      setSelectedDeliveryOption({
                                        name: f.parcelType,
                                        cost: f.price,
                                        partner: "DIRECTFREIGHT",
                                        productId: f.productId,
                                      })
                                    }
                                  >
                                    <Box paddingY={0.5}>
                                      {`${f.parcelType}`}
                                    </Box>
                                    <Box
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {f.price}
                                    </Box>
                                  </Stack>
                                </Stack>
                              ))}
                          </Stack>

                          <Stack>
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              My Fastway
                            </Box>
                            {quotes.MYFASTWAY && quotes.MYFASTWAY.carrierQuotes?.length > 0 &&
                              quotes.MYFASTWAY.carrierQuotes.map((f, key) => (
                                <Stack key={key}>
                                  <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "300",
                                      backgroundColor:
                                        selectedDeliveryOption.name ===
                                        f.parcelType
                                          ? "#E3F2FD"
                                          : "",
                                      borderRadius: "6px",

                                      "&:hover": {
                                        background: "#efefef",
                                      },
                                    }}
                                    paddingX={0.8}
                                    margin={0.3}
                                    onClick={() =>
                                      setSelectedDeliveryOption({
                                        name: f.parcelType,
                                        cost: f.price,
                                        partner: "MYFASTWAY",
                                      })
                                    }
                                  >
                                    <Box
                                      paddingY={0.5}
                                    >{`${f.parcelType} `}</Box>
                                    <Box
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {f.price}
                                    </Box>
                                  </Stack>
                                </Stack>
                              ))}
                          </Stack>

                          <Stack>
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              In House Australia Post
                            </Box>
                            {quotes.INHOUSE && quotes.INHOUSE.carrierQuotes?.length > 0 &&
                              quotes.INHOUSE.carrierQuotes.map((i, key) => (
                                <Stack key={key}>
                                  <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "300",
                                      backgroundColor:
                                        selectedDeliveryOption.name ===
                                        i.parcelType
                                          ? "#E3F2FD"
                                          : "",
                                      borderRadius: "6px",

                                      "&:hover": {
                                        background: "#efefef",
                                      },
                                    }}
                                    paddingX={0.8}
                                    margin={0.3}
                                    onClick={() =>
                                      setSelectedDeliveryOption({
                                        name: i.parcelType,
                                        cost: i.price,
                                        partner: "INHOUSE",
                                      })
                                    }
                                  >
                                    <Box
                                      paddingY={0.5}
                                    >{`${i.parcelType}`}</Box>
                                    <Box
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {i.price}
                                    </Box>
                                  </Stack>
                                </Stack>
                              ))}
                          </Stack>
                        </Stack>
                      </Stack>

                      <Stack
                        sx={{
                          boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
                          borderRadius: "6px",
                          width: "50%",
                        }}
                        padding={2}
                        spacing={2}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            backgroundColor: "#1976d2",
                            color: "white",
                          }}
                          paddingY={1}
                        >
                          {`${
                            selectedDeliveryOption.name ||
                            "Selected Delivery Option"
                          } - $${selectedDeliveryOption.cost || 0}`}
                        </Box>

                        <Stack spacing={2}>
                          {country === "AU" && selectedDeliveryOption.partner !== "DIRECTFREIGHT" ? (
                            <TextField
                              id="standard-basic"
                              label={
                                selectedDeliveryOption.partner === "EPARCEL"
                                  ? "Cover Amount"
                                  : selectedDeliveryOption.partner ===
                                      "FASTWAY" ||
                                    selectedDeliveryOption.partner ===
                                      "MYFASTWAY"
                                  ? "Special Instructions"
                                  : "Label Number"
                              }
                              onChange={(e) =>
                                setShippingInstructions(e.target.value)
                              }
                              variant="standard"
                              type={
                                selectedDeliveryOption.partner === "EPARCEL" &&
                                "number"
                              }
                              value={shippingInstructions}
                            />
                          ) : (
                            selectedDeliveryOption.partner !== "DIRECTFREIGHT" && (
                            <div>
                              <Button
                                variant="outlined"
                                onClick={() => setIsAddingIntlDetails(true)}
                              >
                                Add Consignment Details
                              </Button>
                              <TextField
                                id="standard-basic"
                                label={
                                  selectedDeliveryOption.partner === "EPARCEL"
                                    ? "Cover Amount"
                                    : selectedDeliveryOption.partner ===
                                        "FASTWAY" ||
                                      selectedDeliveryOption.partner ===
                                        "MYFASTWAY"
                                    ? "Special Instructions"
                                    : "Label Number"
                                }
                                onChange={(e) =>
                                  setShippingInstructions(e.target.value)
                                }
                                variant="standard"
                                type={
                                  selectedDeliveryOption.partner ===
                                    "EPARCEL" && "number"
                                }
                                value={shippingInstructions}
                              />
                            </div>
                            )
                          )}

                          {country === "AU" &&
                            selectedDeliveryOption.partner === "EPARCEL" && (
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        color: "#90CAF9",
                                      }}
                                      indeterminate={false}
                                      checked={authToLeave}
                                      onChange={() =>
                                        setAuthToLeave(!authToLeave)
                                      }
                                      label="Authority to leave"
                                    />
                                  }
                                  label="Authority to leave"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        color: "#90CAF9",
                                      }}
                                      indeterminate={false}
                                      checked={allowPartialDelivery}
                                      onChange={() =>
                                        setAllowPartialDelivery(
                                          !allowPartialDelivery
                                        )
                                      }
                                    />
                                  }
                                  label="Allow partial delivery"
                                />
                              </FormGroup>
                            )}

                          {selectedDeliveryOption.partner === "MYFASTWAY" && (
                            <FormGroup>
                              <RadioGroup
                                defaultValue={myFastwayItemCode}
                                name="radio-buttons-group"
                                onChange={(e) =>
                                  setMyFastwayItemCode(e.target.value)
                                }
                              >
                                <FormControlLabel
                                  value="ATL"
                                  control={<Radio />}
                                  label="Leave at Door"
                                />
                                <FormControlLabel
                                  value="STN"
                                  control={<Radio />}
                                  label="Standard Service"
                                />
                                <FormControlLabel
                                  value="SGR"
                                  control={<Radio />}
                                  label="Signature Required"
                                />
                              </RadioGroup>
                            </FormGroup>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>

                    <Button
                      variant="contained"
                      disabled={!allowCreateShipment}
                      onClick={() => {
                        setAllowCreateShipment(false);
                        createShipment();
                      }}
                    >
                      Create Shipment
                    </Button>

                    {shipmentCreated && (
                      <>
                        <ReactToPrint
                          trigger={() => (
                            <Button ref={printRef}>Print this out!</Button>
                          )}
                          content={() => printComponentRef}
                          onAfterPrint={() => onShipmentComplete()}
                          pageStyle={`
                        @page {
                          size: 105mm 148mm;
                        }

                        @media all {
                          .pagebreak {
                            display: none;
                          }
                        }
                      
                        @media print {
                          .pagebreak {
                            page-break-before: always;
                          }
                        }
                      `}
                        />
                        <div style={{ display: "none" }}>
                          <ComponentToPrint
                            ref={(el) => (printComponentRef = el)}
                          />
                        </div>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
          )
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {isLoadingLogs ? (
          <CircularProgress />
        ) : (
          logsData &&
          logsData.length > 0 && (
            <Stack spacing={3}>
              <Stack
                style={{
                  backgroundColor: "#f9fafc",
                  padding: "15px",
                  boxShadow:
                    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                  borderRadius: "16px",
                }}
                spacing={2}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    fontWeight: "bolder",
                  }}
                >
                  <Box sx={{ width: "33%" }}>User</Box>
                  <Box sx={{ width: "33%" }}>Action Message</Box>
                  <Box sx={{ width: "33%" }}>Date/Time</Box>
                </Stack>
                {logsData.map((d, key) => (
                  <Stack
                    spacing={2}
                    key={key}
                    direction="row"
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    <Box sx={{ width: "33%" }}>{d.userId}</Box>
                    <Box sx={{ width: "33%" }}>{d.action}</Box>
                    <Box sx={{ width: "33%" }}>
                      {" "}
                      {new Date(d.actionTimestamp).toLocaleString()}
                    </Box>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )
        )}
      </TabPanel>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};
