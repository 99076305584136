import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { sendMessage } from 'components/PrinterGui/webSocketConnection';
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import productCodes from "utils/productCodes";

import { extendToken } from "refresh-token/refreshToken";

import { SHIPPING_API, ORDERS_API } from "../../api-config";


import { base64ArrayBuffer } from "../../utils/byteArrayToPdf";
import b64toBlob from "b64-to-blob";
import { useBarcode } from "react-barcodes";
import ReactToPrint from "react-to-print";

export const QuickQuote = () => {
  const [error, setError] = useState({ status: false, message: undefined });

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [addrLine1, setAddrLine1] = useState("");
  const [addrLine2, setAddrLine2] = useState("");
  const [suburb, setSuburb] = useState("");
  const [state, setState] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState("AU");
  const [orderId, setOrderId] = useState([]);
  const [referenceNumber, setReferenceNumber] = useState("");

  const [isAddressValidated, setIsAddressValidated] = useState(false);
  const [isAddressUpdated, setIsAddressUpdated] = useState(false);
  const [addressSearchValues, setAddressSearchValues] = useState([]);
  const [customAddressSelected, setCustomAddressSelected] = useState();
  const [selectedPackageLine, setSelectedPackageLine] = useState(0);
  const [defaultPackageConfig, setDefaultPackageConfig] = useState([]);
  const [consignmentSelectionOpen, setConsignmentSelectionOpen] =
    useState(false);
  const [packageConfig, setPackageConfig] = useState([]);
  const [packageQuantity, setPackageQuantity] = useState(
    packageConfig.length > 0
      ? defaultPackageConfig[selectedPackageLine].quantity
      : ""
  );
  const [weight, setWeight] = useState(
    packageConfig.length > 0
      ? defaultPackageConfig[selectedPackageLine].weight
      : ""
  );
  const [length, setLength] = useState(
    packageConfig.length > 0
      ? defaultPackageConfig[selectedPackageLine].length
      : ""
  );
  const [width, setWidth] = useState(
    packageConfig.length > 0
      ? defaultPackageConfig[selectedPackageLine].width
      : ""
  );
  const [height, setHeight] = useState(
    packageConfig.length > 0
      ? defaultPackageConfig[selectedPackageLine].height
      : ""
  );
  const [isLoadingQuotes, setIsLoadingQuotes] = useState(false);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState({
    name: "",
    cost: "",
    partner: "",
    productId: "",
  });
  const [quotes, setQuotes] = useState();
  const [shippingInstructions, setShippingInstructions] = useState("");
  const [myFastwayItemCode, setMyFastwayItemCode] = useState("ATL");
  const [isAddingIntlDetails, setIsAddingIntlDetails] = useState(false);
  const [commercialValue, setCommercialValue] = useState(true);
  const [itemDescription, setItemDescription] = useState();
  const [importReferenceNumber, setImportReferenceNumber] = useState();
  const [exportDeclarationNumber, setExportDeclarationNumber] = useState();
  const [intlCountry, setIntlCountry] = useState("AU");
  const [tariffCode, setTariffCode] = useState();
  const [intlQty, setIntlQty] = useState(1);
  const [intlUnitValue, setIntlUnitValue] = useState();
  const [intlWeight, setIntlWeight] = useState();
  const [intlDescription, setIntlDescription] = useState();
  const [tariffConcession, setTariffConcession] = useState();
  const [isIntlDetailsUnfinished, setIsIntlDetailsUnfinished] = useState(true);
  const [selectedProductClassification, setSelectedProductClassification] =
    useState(0);
  const [productClassificationOpen, setProductClassificationOpen] =
    useState(false);
  const [authToLeave, setAuthToLeave] = useState(false);
  const [allowPartialDelivery, setAllowPartialDelivery] = useState(true);
  const [shipmentCreated, setShipmentCreated] = useState(false);
  const [allowCreateShipment, setAllowCreateShipment] = useState(true);

  useEffect(() => {
    getPackageConfigs();
  }, []);

  useEffect(() => {
    if (
      commercialValue &&
      itemDescription &&
      importReferenceNumber &&
      intlCountry &&
      intlQty &&
      intlUnitValue &&
      intlWeight &&
      intlDescription
    ) {
      setIsIntlDetailsUnfinished(false);
    }
  }, [
    commercialValue,
    itemDescription,
    importReferenceNumber,
    intlCountry,
    intlQty,
    intlUnitValue,
    intlWeight,
    intlDescription,
  ]);

  useEffect(() => {
    if (defaultPackageConfig.length > 0) {
      setPackageQuantity(defaultPackageConfig[selectedPackageLine].quantity);

      setWeight(defaultPackageConfig[selectedPackageLine].weight);
      setLength(defaultPackageConfig[selectedPackageLine].length);
      setWidth(defaultPackageConfig[selectedPackageLine].width);
      setHeight(defaultPackageConfig[selectedPackageLine].height);
    }
  }, [defaultPackageConfig]);

  const anchorRef = useRef(null);
  const productClassificationRef = useRef(null);
  let printComponentRef = useRef();
  const printRef = useRef();

  async function validateAddress(manualUpdate, usePostcode) {
    const controller = new AbortController();
    const signal = controller.signal;
    await extendToken();

    return fetch(`${SHIPPING_API}/api/v2/suburbOrPostcode`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        suburbOrPostcode: usePostcode ? postCode : suburb,
      }),
      signal,
    })
      .then((response) => response.json())
      .then((res) => {
        res.result.length > 0 &&
          res.result.map((r) => {
            if (r.Postcode === postCode) {
              setState(r.State);
              setIsAddressValidated(true);
            }
          });

        manualUpdate &&
          (setIsAddressUpdated(true), setAddressSearchValues(res.result));
      })
      .catch((err) => {
        console.log("error validating address", err);
      });
  }

  const getPackageConfigs = async () => {
    await extendToken();
    return fetch(`${ORDERS_API}/api/v2/customPackages`, {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
    })
      .then((response) => response.json())
      .then((res) => {
        setDefaultPackageConfig(res);
      })
      .catch((err) => {
        console.log("error fetching package configs", err);
        setPackageConfig(undefined);
      });
  };

  const getQuote = async () => {
    setIsLoadingQuotes(true);
    await extendToken();
    return fetch(`${SHIPPING_API}/api/v2/getQuote`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        address: {
          name: name,
          companyName: companyName,
          addressLine1: addrLine1,
          addressLine2: addrLine2,
          city: suburb,
          stateOrProvince: state,
          postalCode: postCode,
          country: country,
          phoneNumber: phoneNumber,
        },
        items: [
          {
            Reference: "12345",
            Quantity: packageQuantity,
            Weight: weight,
            Length: length,
            Width: width,
            Height: height,
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((res) => {


        // Create a new object for quotes to avoid mutation
        const newQuotes = { ...res.quotes };

        // Additional processing for MYFASTWAY
        if (newQuotes.MYFASTWAY && newQuotes.MYFASTWAY.carrierQuotes.length > 0) {
          let labelQuote = newQuotes.MYFASTWAY.carrierQuotes.find(q => q.productId === "Labels");
          let totalServicePrice = newQuotes.MYFASTWAY.carrierQuotes.reduce((total, quote) => {
            if (quote.productId === "Service") {
              return total + parseFloat(quote.price);
            }
            return total;
          }, 0);

          // If Labels quote found, add the total service price to it and round to two decimals
          if (labelQuote) {
            labelQuote.price = parseFloat((labelQuote.price + totalServicePrice).toFixed(2));
          }

          // Filter out everything but Labels
          newQuotes.MYFASTWAY.carrierQuotes = labelQuote ? [labelQuote] : [];
        }

        setQuotes(newQuotes);

        const cheapestEParcelOption =
          newQuotes.EPARCEL.carrierQuotes?.length > 0 &&
          newQuotes.EPARCEL.carrierQuotes.reduce((prev, curr) => {
            return Number(prev.price) < Number(curr.price) ? prev : curr;
          });
        const cheapestDfeOption =
          newQuotes.DIRECTFREIGHT.carrierQuotes?.length > 0 &&
          newQuotes.DIRECTFREIGHT.carrierQuotes.reduce((prev, curr) => {
            return Number(prev.price) < Number(curr.price) ? prev : curr;
          });
        const cheapestMyFastWayOption =
          newQuotes.MYFASTWAY.carrierQuotes?.length > 0 &&
          newQuotes.MYFASTWAY.carrierQuotes.reduce((prev, curr) => {
            return Number(prev.price) < Number(curr.price) ? prev : curr;
          });
        const cheapestAuPostParcel =
          newQuotes.INHOUSE.carrierQuotes?.length > 0 &&
          newQuotes.INHOUSE.carrierQuotes.reduce((prev, curr) => {
            return Number(prev.price) < Number(curr.price) ? prev : curr;
          });

        let cheapestDeliveryOptions = [];
        cheapestDeliveryOptions.push(
          cheapestEParcelOption,
          cheapestDfeOption,
          cheapestMyFastWayOption,
          cheapestAuPostParcel
        );

        const consolidatedCheapDeliveryOption = cheapestDeliveryOptions
          .filter(Boolean)
          .reduce((prev, curr) => {
            return Number(prev.price) < Number(curr.price) ? prev : curr;
          });

        let cheapestDeliveryCourier = cheapestDeliveryOptions
          .map((d, key) => {
            if (d === consolidatedCheapDeliveryOption) {
              return key;
            }
          })
          .filter(Boolean)[0];

        switch (cheapestDeliveryCourier) {
          case 0:
            cheapestDeliveryCourier = "EPARCEL";
            break;
          case 1:
            cheapestDeliveryCourier = "DIRECTFREIGHT";
            break;
          case 2:
            cheapestDeliveryCourier = "MYFASTWAY";
            break;
          case 3:
            cheapestDeliveryCourier = "INHOUSE";
            break;
          default:
            cheapestDeliveryCourier = "";
        }

        setSelectedDeliveryOption({
          name: consolidatedCheapDeliveryOption.parcelType,
          cost: consolidatedCheapDeliveryOption.price,
          partner: cheapestDeliveryCourier,
        });

        setIsLoadingQuotes(false);
      })
      .catch((err) => {
        console.log("error fetching shipment quotes", err);
        setIsLoadingQuotes(false);
      });
  };

  const handleMenuItemClick = (index) => {
    setSelectedPackageLine(index);

    setPackageQuantity(defaultPackageConfig[index].quantity);
    setWeight(defaultPackageConfig[index].weight);
    setLength(defaultPackageConfig[index].length);
    setWidth(defaultPackageConfig[index].width);
    setHeight(defaultPackageConfig[index].height);
  };

  const openNewTab = (response) => {
    let contentType = "application/pdf";
    let sliceSize = 512;

    let byteCharacters = atob(response);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    blob = b64toBlob(response, contentType);
    let blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl);
  };

  const createShipment = async () => {
    if (selectedDeliveryOption.name === "MYFASTWAY" && !phoneNumber) {
      setShipmentCreated(false);

      return;
    }
    await extendToken();

    return fetch(`${SHIPPING_API}/api/v2/createShipment`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        orderIds: orderId,
        address: {
          name: name,
          companyName: companyName,
          addressLine1: addrLine1,
          addressLine2: addrLine2,
          city: suburb,
          stateOrProvince: state,
          postalCode: postCode,
          country: country,
          phone: phoneNumber,
          email: emailId,
        },
        items: [
          {
            classificationType: country != "AU" ? "OTHER" : null,

            commercialValue: country != "AU" ? commercialValue : null,
            descriptionOfOther:
              country != "AU"
                ? productClassifications[selectedProductClassification].name
                : null,
            exportDeclerationNumber:
              country != "AU" ? exportDeclarationNumber || "" : null,
            importReferenceNumber:
              country != "AU" ? importReferenceNumber : null,
            itemContent:
              country != "AU"
                ? [
                    {
                      countryOfOrigin: intlCountry,
                      description: intlDescription,
                      sku: "",
                      quantity: parseInt(intlQty),
                      tariffCode: tariffCode || null,
                      value: parseFloat(intlUnitValue),
                      weight: parseFloat(intlWeight),
                      itemContentsReference: "",
                    },
                  ]
                : null,
            itemDescription: country != "AU" ? itemDescription || "" : null,
            Reference: referenceNumber,
            Quantity: packageQuantity,
            Weight: weight,
            Length: length,
            Width: width,
            Height: height,
          },
        ],
        courierName: selectedDeliveryOption.partner,
        shippingClass: "",
        parcelType: selectedDeliveryOption.name,
        productId: selectedDeliveryOption.productId,
        price: selectedDeliveryOption.cost,
        labelNumber: shippingInstructions,
        specialInstructions: shippingInstructions,
        authorityToLeave: country === "AU" ? authToLeave : undefined,
        allowPartialDelivery:
          country === "AU" ? allowPartialDelivery : undefined,
        transitCover:
          selectedDeliveryOption.partner === "EPARCEL"
            ? Number(shippingInstructions)
            : undefined,
        myFastwayItemCode: myFastwayItemCode,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        var productLabel = null;
        if(productCodes.includes(selectedDeliveryOption.productId)) {
          productLabel = "A4"
        } else {
          productLabel = "Label Printer"
        }

        const base64 = res.pdfData && base64ArrayBuffer(JSON.parse(res.pdfData));

        const isLocalStorageTrue = localStorage.getItem('websocketConnected') === 'true';

        if (isLocalStorageTrue && res.pdfData) {
          sendMessage(base64, false,productLabel, false);
        }
        else if (isLocalStorageTrue && !res.pdfData && res.labels) {
          res.labels.map((l) => {
            sendMessage(l.pdfLink, true, productLabel, selectedDeliveryOption.partner === "INHOUSE"? true : false);
          })
        }
        else {

          if (res.pdfData) {
            openNewTab(base64);
          }

          !res.pdfData && res.labels
            ? res.labels.map((l) => {
                window.open(l.pdfLink);
              })
            : (setShipmentCreated(true), printRef?.current?.click());

        }
        window.location.reload();
      })
      .catch((err) => {
        console.log("error fetching shipment quotes", err);
        setShipmentCreated(false);
      });
  };

  const CustomBarcode = () => {
    const { inputRef } = useBarcode({
      value: "93653247065", //TODO: Use proper barcode
      options: {
        height: "25",
        width: "1",
        fontSize: "12",
      },
    });

    return (
      <div
        style={{
          minWidth: "100px",
        }}
      >
        <img ref={inputRef} />
      </div>
    );
  };

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <Stack
          spacing={3}
          paddingLeft={3}
          sx={{
            transform: "rotate(90deg)",
          }}
        >
          <Box
            sx={{
              fontSize: "8px",
            }}
          >
            <br />
            From: EG TRADE PO BOX 222 HEIDELBERG VIC 3084
          </Box>

          <Box
            paddingLeft={5}
            sx={{
              fontSize: "20px",
            }}
          >
            <Box
              sx={{
                fontSize: "20px",
                fontWeight: "bolder",
              }}
            >
              To: <br />
              <br />
              {name}
              <br />
              <br />
            </Box>
            {addrLine1}
            <br />
            {addrLine2 || ""}
            <br />
            {suburb}
            <br />
            {state} {postCode}
          </Box>

          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              width: "100%",
            }}
          >
            <Box>ENV</Box>
            <Box>
              <CustomBarcode />
            </Box>
          </Stack>
        </Stack>
      );
    }
  }

  const handleConsignmentToggle = () => {
    setConsignmentSelectionOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setConsignmentSelectionOpen(false);
  };

  const handleIntlDetailsCancellation = () => {
    setCommercialValue();
    setItemDescription();
    setImportReferenceNumber();
    setExportDeclarationNumber();
    setIntlCountry();
    setTariffCode();
    setIntlQty();
    setIntlUnitValue();
    setIntlWeight();
    setIntlDescription();
    setTariffConcession();
    setIsAddingIntlDetails(false);
  };

  const handleClassificationToggle = () => {
    setProductClassificationOpen((prevOpen) => !prevOpen);
  };

  const handleClassificationClose = (event) => {
    if (
      productClassificationRef.current &&
      productClassificationRef.current.contains(event.target)
    ) {
      return;
    }

    setProductClassificationOpen(false);
  };

  const productClassifications = [
    {
      name: "Sale of Goods",
      value: "SALE OF GOODS",
    },
    {
      name: "Gift",
      value: "GIFT",
    },
    {
      name: "Commercial Sample",
      value: "COMMERCIAL SAMPLE",
    },
    {
      name: "Other",
      value: "OTHER",
    },
    {
      name: "Returned Goods",
      value: "RETURNED GOODS",
    },
  ];

  return (
    <Box
      style={{
        paddingTop: "25px",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!defaultPackageConfig}
        onClick={undefined}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error.status && (
        <Alert onClose={() => setError({ status: false })} severity="error">
          {error.message}
        </Alert>
      )}
      {defaultPackageConfig && (
        <Box
          style={{
            margin: "0 auto",
            maxWidth: "1200px",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            overflow: "hidden",
            boxShadow:
              "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
            borderRadius: "16px",
            minHeight: "500px",
          }}
        >
          <Box
            style={{
              padding: "20px",
            }}
          >
            <Dialog
              open={isAddingIntlDetails}
              onClose={() => setIsAddingIntlDetails(false)}
            >
              <DialogTitle id="alert-dialog-title">
                {"Add consignment details"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            style={{
                              color: "#90CAF9",
                            }}
                            indeterminate={false}
                            checked={commercialValue}
                            onChange={() =>
                              setCommercialValue(!commercialValue)
                            }
                          />
                        }
                        label="Whether the item has commercial value?"
                        sx={{
                          width: "250px",
                        }}
                      />
                      <TextField
                        required
                        id="standard-basic"
                        label="Item Description"
                        value={itemDescription}
                        onChange={(e) => setItemDescription(e.target.value)}
                        variant="standard"
                        sx={{
                          width: "300px",
                        }}
                      />
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      <TextField
                        required
                        id="standard-basic"
                        label="Import Reference Number"
                        value={importReferenceNumber}
                        onChange={(e) =>
                          setImportReferenceNumber(e.target.value)
                        }
                        variant="standard"
                        sx={{
                          width: "250px",
                        }}
                      />
                      <TextField
                        id="standard-basic"
                        label="Export declaration number"
                        value={exportDeclarationNumber}
                        onChange={(e) =>
                          setExportDeclarationNumber(e.target.value)
                        }
                        variant="standard"
                        sx={{
                          width: "300px",
                        }}
                        error={
                          exportDeclarationNumber?.length < 9 ||
                          exportDeclarationNumber?.length > 12
                        }
                      />
                    </Stack>

                    <Stack paddingTop={2}>
                      Product Classification
                      <ButtonGroup
                        variant="contained"
                        ref={productClassificationRef}
                        aria-label="split button"
                        style={{
                          width: "fit-content",
                        }}
                      >
                        <Button
                          onClick={undefined}
                          style={{
                            minWidth: "150px",
                          }}
                        >
                          {productClassifications[selectedProductClassification]
                            ?.name || ""}
                        </Button>
                        <Button
                          size="small"
                          aria-controls={
                            productClassificationOpen
                              ? "split-button-menu"
                              : undefined
                          }
                          aria-expanded={
                            productClassificationOpen ? "true" : undefined
                          }
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={handleClassificationToggle}
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        open={productClassificationOpen}
                        anchorEl={productClassificationRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper
                              style={{
                                maxHeight: "100px",
                                overflow: "scroll",
                              }}
                            >
                              <ClickAwayListener
                                onClickAway={handleClassificationClose}
                              >
                                <MenuList
                                  id="split-button-menu"
                                  style={{
                                    maxHeight: "100px",
                                    overflow: "scroll",
                                  }}
                                >
                                  {productClassifications.map(
                                    (option, index) => (
                                      <MenuItem
                                        key={index}
                                        selected={
                                          index ===
                                          selectedProductClassification
                                        }
                                        onClick={() => {
                                          setSelectedProductClassification(
                                            index
                                          );
                                          setProductClassificationOpen();
                                        }}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    )
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Stack>

                    <Box
                      paddingTop={15}
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Item Details
                    </Box>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        id="standard-basic"
                        label="Country of Origin"
                        value={intlCountry}
                        onChange={(e) => setIntlCountry(e.target.value)}
                        variant="standard"
                        sx={{
                          width: "250px",
                        }}
                      />
                      <TextField
                        id="standard-basic"
                        label="Tariff Code"
                        value={tariffCode}
                        onChange={(e) => setTariffCode(e.target.value)}
                        variant="standard"
                        sx={{
                          width: "300px",
                        }}
                        type="number"
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        required
                        id="standard-basic"
                        label="Quantity"
                        value={intlQty}
                        onChange={(e) => setIntlQty(e.target.value)}
                        variant="standard"
                        sx={{
                          width: "180px",
                        }}
                      />
                      <TextField
                        required
                        id="standard-basic"
                        label="Unit value (AUD)"
                        value={intlUnitValue}
                        onChange={(e) => setIntlUnitValue(e.target.value)}
                        variant="standard"
                        sx={{
                          width: "180px",
                        }}
                      />
                      <TextField
                        required
                        id="standard-basic"
                        label="Weight(kg)"
                        value={intlWeight}
                        onChange={(e) => setIntlWeight(e.target.value)}
                        variant="standard"
                        sx={{
                          width: "180px",
                        }}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        required
                        id="standard-basic"
                        label="Description"
                        value={intlDescription}
                        onChange={(e) => setIntlDescription(e.target.value)}
                        variant="standard"
                        sx={{
                          width: "250px",
                        }}
                      />
                      <TextField
                        id="standard-basic"
                        label="Tariff Concession"
                        value={tariffConcession}
                        onChange={(e) => setTariffConcession(e.target.value)}
                        variant="standard"
                        sx={{
                          width: "300px",
                        }}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={4} paddingTop={4}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleIntlDetailsCancellation()}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={isIntlDetailsUnfinished}
                      variant="contained"
                      color="success"
                      onClick={() => setIsAddingIntlDetails(false)}
                    >
                      Save
                    </Button>
                  </Stack>
                </DialogContentText>
              </DialogContent>
            </Dialog>
            <br />
            <Stack direction="column" spacing={3}>
              <Box>Customer Details</Box>
              <Stack direction="row" spacing={2}>
                <TextField
                  id="standard-basic"
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  variant="standard"
                  required
                />
                <TextField
                  id="standard-basic"
                  label="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  variant="standard"
                />
                <TextField
                  id="standard-basic"
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  variant="standard"
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <TextField
                  id="standard-basic"
                  label="Address Line 1"
                  value={addrLine1}
                  onChange={(e) => setAddrLine1(e.target.value)}
                  variant="standard"
                  error={addrLine1?.length > 40}
                  required
                />
                <TextField
                  id="standard-basic"
                  label="Address Line 2"
                  value={addrLine2}
                  onChange={(e) => setAddrLine2(e.target.value)}
                  variant="standard"
                  error={addrLine2?.length > 40}
                />
                <TextField
                  id="standard-basic"
                  label="Email"
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                  variant="standard"
                  required
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <TextField
                  // error={!isAddressValidated && country === "AU"}
                  id="standard-basic"
                  label="Suburb"
                  value={suburb}
                  onChange={(e) => {
                    setIsAddressValidated(true);
                    setSuburb(e.target.value);
                    setTimeout(() => validateAddress(true), 2000);
                  }}
                  variant="standard"
                  // helperText={
                  //   !isAddressValidated &&
                  //   country === "AU" &&
                  //   "Suburb & Postcode mismatch"
                  // }
                  required
                />
                <TextField
                  id="standard-basic"
                  label="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  variant="standard"
                  required
                />
                <TextField
                  // error={!isAddressValidated && country === "AU"}
                  id="standard-basic"
                  label="Postcode"
                  value={postCode}
                  onChange={(e) => {
                    setIsAddressValidated(true);
                    setPostCode(e.target.value);
                    setTimeout(() => validateAddress(true, true), 2000);
                  }}
                  variant="standard"
                  helperText={!isAddressValidated && country === "AU" && ""}
                  required
                />

                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="country" required>
                    Country
                  </InputLabel>
                  <Select
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                    label="Country"
                    defaultValue={"AU"}
                  >
                    {Object.keys(shippingCountries).map((countryKey, key) => {
                      return (
                        <MenuItem
                          value={countryKey}
                          key={key}
                          defaultValue={"AU"}
                        >
                          {shippingCountries[countryKey]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>

              <Stack direction="row" spacing={2}>
                <TextField
                  id="standard-basic"
                  label="Order Id"
                  value={orderId}
                  onChange={(e) => setOrderId([e.target.value])}
                  variant="standard"
                />
                <TextField
                  id="standard-basic"
                  label="Reference Number"
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                  variant="standard"
                />
              </Stack>

              {isAddressUpdated && (
                <Box>
                  <Box
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      backdropFilter: "blur(20px)",
                      paddingBottom: "10px",
                    }}
                  >
                    Address Search Results
                  </Box>
                  <Stack
                    spacing={1}
                    sx={{
                      maxHeight: "150px",
                      overflow: "scroll",
                    }}
                  >
                    {addressSearchValues.map((a, key) => (
                      <Stack
                        direction="row"
                        spacing={5}
                        padding={1}
                        key={key}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "300",
                          backgroundColor:
                            customAddressSelected === key
                              ? "#E3F2FD"
                              : "#efefef",
                          borderRadius: "6px",
                        }}
                        alignItems="center"
                        onClick={() => {
                          setCustomAddressSelected(key);
                          setSuburb(a.Town);
                          setState(a.State);
                          setPostCode(a.Postcode);
                        }}
                      >
                        <Box sx={{ width: "150px" }}>{a.Town}</Box>
                        <Box sx={{ width: "50px" }}> {a.State}</Box>
                        <Box sx={{ width: "50px" }}>{a.Postcode}</Box>
                      </Stack>
                    ))}
                  </Stack>
                </Box>
              )}

              <Box>Shipment Items</Box>

              <Stack direction="row" spacing={2} alignItems="center">
                <Stack>
                  <ButtonGroup
                    variant="contained"
                    ref={anchorRef}
                    aria-label="split button"
                  >
                    <Button
                      onClick={undefined}
                      style={{
                        minWidth: "150px",
                      }}
                    >
                      {defaultPackageConfig[selectedPackageLine]?.packageName ||
                        ""}
                    </Button>
                    <Button
                      size="small"
                      aria-controls={
                        consignmentSelectionOpen
                          ? "split-button-menu"
                          : undefined
                      }
                      aria-expanded={
                        consignmentSelectionOpen ? "true" : undefined
                      }
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleConsignmentToggle}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper
                    open={consignmentSelectionOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper
                          style={{
                            maxHeight: "100px",
                            overflow: "scroll",
                          }}
                        >
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              id="split-button-menu"
                              style={{
                                maxHeight: "100px",
                                overflow: "scroll",
                              }}
                            >
                              {defaultPackageConfig.map((option, index) => (
                                <MenuItem
                                  key={index}
                                  selected={index === selectedPackageLine}
                                  onClick={() => {
                                    handleMenuItemClick(index);
                                  }}
                                >
                                  {option.packageName}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Stack>

                <Stack spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      id="standard-basic"
                      label="Quantity"
                      value={packageQuantity}
                      onChange={(e) => setPackageQuantity(e.target.value)}
                      variant="standard"
                      sx={{
                        maxWidth: "65px",
                      }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Weight(kg)"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      variant="standard"
                      sx={{
                        maxWidth: "65px",
                      }}
                      disabled={selectedPackageLine != 0}
                    />
                  </Stack>
                </Stack>

                <Stack direction="row" spacing={2}>
                  <TextField
                    id="standard-basic"
                    label="L(cm)"
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                    variant="standard"
                    sx={{
                      maxWidth: "65px",
                    }}
                    disabled={selectedPackageLine != 0}
                  />
                  <TextField
                    id="standard-basic"
                    label="W(cm)"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                    variant="standard"
                    sx={{
                      maxWidth: "65px",
                    }}
                    disabled={selectedPackageLine != 0}
                  />
                  <TextField
                    id="standard-basic"
                    label="H(cm)"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                    variant="standard"
                    sx={{
                      maxWidth: "65px",
                    }}
                    disabled={selectedPackageLine != 0}
                  />
                </Stack>
              </Stack>

              <Chip
                type="submit"
                label={"Get Quote"}
                disabled={
                  !name ||
                  !addrLine1 ||
                  !suburb ||
                  !state ||
                  !postCode ||
                  !country ||
                  !emailId
                }
                onClick={() => getQuote()}
                style={{
                  background: "#90CAF9",
                  marginTop: "150px",
                }}
              />

              {isLoadingQuotes && <CircularProgress />}
                {quotes && (
                  <Stack spacing={3}>
                    <Stack
                      direction="row"
                      spacing={5}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Stack
                        sx={{
                          boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
                          borderRadius: "6px",
                          width: "50%",
                        }}
                        padding={2}
                        spacing={2}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            backgroundColor: "#1976d2",
                            color: "white",
                          }}
                          paddingY={1}
                        >
                          Delivery Rates Comparision
                        </Box>

                        <Stack
                          spacing={2}
                          sx={{
                            maxHeight: "300px",
                            overflow: "Scroll",
                          }}
                        >
                          <Stack>
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              eParcel
                            </Box>
                            {quotes.EPARCEL && quotes.EPARCEL.carrierQuotes?.length > 0 &&
                              quotes.EPARCEL.carrierQuotes.map((a, key) => (
                                <Stack key={key}>
                                  <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "300",
                                      backgroundColor:
                                        selectedDeliveryOption.name ===
                                        a.parcelType
                                          ? "#E3F2FD"
                                          : "",
                                      borderRadius: "6px",

                                      "&:hover": {
                                        background: "#efefef",
                                      },
                                    }}
                                    paddingX={0.8}
                                    margin={0.3}
                                    onClick={() =>
                                      setSelectedDeliveryOption({
                                        name: a.parcelType,
                                        cost: a.price,
                                        partner: "EPARCEL",
                                        productId: a.productId,
                                      })
                                    }
                                  >
                                    <Box paddingY={0.5}>{a.parcelType}</Box>
                                    <Box
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {a.price}
                                    </Box>
                                  </Stack>
                                </Stack>
                              ))}
                          </Stack>

                          <Stack>
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              Direct Freight
                            </Box>
                            {quotes.DIRECTFREIGHT && quotes.DIRECTFREIGHT.carrierQuotes?.length > 0 &&
                              quotes.DIRECTFREIGHT.carrierQuotes.map((f, key) => (
                                <Stack key={key}>
                                  <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "300",
                                      backgroundColor:
                                        selectedDeliveryOption.name ===
                                        f.parcelType
                                          ? "#E3F2FD"
                                          : "",
                                      borderRadius: "6px",

                                      "&:hover": {
                                        background: "#efefef",
                                      },
                                    }}
                                    paddingX={0.8}
                                    margin={0.3}
                                    onClick={() =>
                                      setSelectedDeliveryOption({
                                        name: f.parcelType,
                                        cost: f.price,
                                        partner: "DIRECTFREIGHT",
                                        productId: f.productId,
                                      })
                                    }
                                  >
                                    <Box paddingY={0.5}>
                                      {`${f.parcelType}`}
                                    </Box>
                                    <Box
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {f.price}
                                    </Box>
                                  </Stack>
                                </Stack>
                              ))}
                          </Stack>

                          <Stack>
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              My Fastway
                            </Box>
                            {quotes.MYFASTWAY && quotes.MYFASTWAY.carrierQuotes?.length > 0 &&
                              quotes.MYFASTWAY.carrierQuotes.map((f, key) => (
                                <Stack key={key}>
                                  <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "300",
                                      backgroundColor:
                                        selectedDeliveryOption.name ===
                                        f.parcelType
                                          ? "#E3F2FD"
                                          : "",
                                      borderRadius: "6px",

                                      "&:hover": {
                                        background: "#efefef",
                                      },
                                    }}
                                    paddingX={0.8}
                                    margin={0.3}
                                    onClick={() =>
                                      setSelectedDeliveryOption({
                                        name: f.parcelType,
                                        cost: f.price,
                                        partner: "MYFASTWAY",
                                      })
                                    }
                                  >
                                    <Box
                                      paddingY={0.5}
                                    >{`${f.parcelType} `}</Box>
                                    <Box
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {f.price}
                                    </Box>
                                  </Stack>
                                </Stack>
                              ))}
                          </Stack>

                          <Stack>
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              Australia Post
                            </Box>
                            {quotes.INHOUSE && quotes.INHOUSE.carrierQuotes?.length > 0 &&
                              quotes.INHOUSE.carrierQuotes.map((i, key) => (
                                <Stack key={key}>
                                  <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "300",
                                      backgroundColor:
                                        selectedDeliveryOption.name ===
                                        i.parcelType
                                          ? "#E3F2FD"
                                          : "",
                                      borderRadius: "6px",

                                      "&:hover": {
                                        background: "#efefef",
                                      },
                                    }}
                                    paddingX={0.8}
                                    margin={0.3}
                                    onClick={() =>
                                      setSelectedDeliveryOption({
                                        name: i.parcelType,
                                        cost: i.price,
                                        partner: "INHOUSE",
                                      })
                                    }
                                  >
                                    <Box
                                      paddingY={0.5}
                                    >{`${i.parcelType}`}</Box>
                                    <Box
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {i.price}
                                    </Box>
                                  </Stack>
                                </Stack>
                              ))}
                          </Stack>
                        </Stack>
                      </Stack>

                      <Stack
                        sx={{
                          boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
                          borderRadius: "6px",
                          width: "50%",
                        }}
                        padding={2}
                        spacing={2}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            backgroundColor: "#1976d2",
                            color: "white",
                          }}
                          paddingY={1}
                        >
                          {`${
                            selectedDeliveryOption.name ||
                            "Selected Delivery Option"
                          } - $${selectedDeliveryOption.cost || 0}`}
                        </Box>

                        <Stack spacing={2}>
                          {country === "AU" && selectedDeliveryOption.partner !== "DIRECTFREIGHT" ? (
                            <TextField
                              id="standard-basic"
                              label={
                                selectedDeliveryOption.partner === "EPARCEL"
                                  ? "Cover Amount"
                                  : selectedDeliveryOption.partner ===
                                      "FASTWAY" ||
                                    selectedDeliveryOption.partner ===
                                      "MYFASTWAY"
                                  ? "Special Instructions"
                                  : "Label Number"
                              }
                              onChange={(e) =>
                                setShippingInstructions(e.target.value)
                              }
                              variant="standard"
                              type={
                                selectedDeliveryOption.partner === "EPARCEL" &&
                                "number"
                              }
                              value={shippingInstructions}
                            />
                          ) : (
                            selectedDeliveryOption.partner !== "DIRECTFREIGHT" && (
                            <div>
                              <Button
                                variant="outlined"
                                onClick={() => setIsAddingIntlDetails(true)}
                              >
                                Add Consignment Details
                              </Button>
                              <TextField
                                id="standard-basic"
                                label={
                                  selectedDeliveryOption.partner === "EPARCEL"
                                    ? "Cover Amount"
                                    : selectedDeliveryOption.partner ===
                                        "FASTWAY" ||
                                      selectedDeliveryOption.partner ===
                                        "MYFASTWAY"
                                    ? "Special Instructions"
                                    : "Label Number"
                                }
                                onChange={(e) =>
                                  setShippingInstructions(e.target.value)
                                }
                                variant="standard"
                                type={
                                  selectedDeliveryOption.partner ===
                                    "EPARCEL" && "number"
                                }
                                value={shippingInstructions}
                              />
                            </div>
                            )
                          )}

                          {country === "AU" &&
                            selectedDeliveryOption.partner === "EPARCEL" && (
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        color: "#90CAF9",
                                      }}
                                      indeterminate={false}
                                      checked={authToLeave}
                                      onChange={() =>
                                        setAuthToLeave(!authToLeave)
                                      }
                                      label="Authority to leave"
                                    />
                                  }
                                  label="Authority to leave"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        color: "#90CAF9",
                                      }}
                                      indeterminate={false}
                                      checked={allowPartialDelivery}
                                      onChange={() =>
                                        setAllowPartialDelivery(
                                          !allowPartialDelivery
                                        )
                                      }
                                    />
                                  }
                                  label="Allow partial delivery"
                                />
                              </FormGroup>
                            )}

                          {selectedDeliveryOption.partner === "MYFASTWAY" && (
                            <FormGroup>
                              <RadioGroup
                                defaultValue={myFastwayItemCode}
                                name="radio-buttons-group"
                                onChange={(e) =>
                                  setMyFastwayItemCode(e.target.value)
                                }
                              >
                                <FormControlLabel
                                  value="ATL"
                                  control={<Radio />}
                                  label="Leave at Door"
                                />
                                <FormControlLabel
                                  value="STN"
                                  control={<Radio />}
                                  label="Standard Service"
                                />
                                <FormControlLabel
                                  value="SGR"
                                  control={<Radio />}
                                  label="Signature Required"
                                />
                              </RadioGroup>
                            </FormGroup>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>

                  <Button
                    variant="contained"
                    disabled={!allowCreateShipment}
                    onClick={() => {
                      setAllowCreateShipment(false);
                      createShipment();
                    }}
                  >
                    Create Shipment
                  </Button>

                  {shipmentCreated && (
                    <>
                      <ReactToPrint
                        trigger={() => (
                          <Button ref={printRef}>Print this out!</Button>
                        )}
                        content={() => printComponentRef}
                        onAfterPrint={() => window.location.reload()}
                        pageStyle={`
                        @page {
                          size: 105mm 148mm;
                        }

                        @media all {
                          .pagebreak {
                            display: none;
                          }
                        }
                      
                        @media print {
                          .pagebreak {
                            page-break-before: always;
                          }
                        }
                      `}
                      />
                      <div style={{ display: "none" }}>
                        <ComponentToPrint
                          ref={(el) => (printComponentRef = el)}
                        />
                      </div>
                    </>
                  )}
                </Stack>
              )}
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const shippingCountries = {
  AF: "Afghanistan",
  AX: "Åland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, The Democratic Republic of the",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Cote D'Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran, Islamic Republic Of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Democratic People's Republic of Korea",
  KR: "Korea, Republic of",
  XK: "Kosovo",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyan Arab Jamahiriya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macedonia, The Former Yugoslav Republic of",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia, Federated States of",
  MD: "Moldova, Republic of",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory, Occupied",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  SH: "Saint Helena",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania, United Republic of",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UM: "United States Minor Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viet Nam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};
