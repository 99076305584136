import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

import CloseIcon from "@mui/icons-material/Close";

import Papa from "papaparse";

import { ORDERS_API } from "api-config";
import { extendToken } from "refresh-token/refreshToken";

export const Shipping = () => {
  const [parsedCsv, setParsedCsv] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [error, setError] = useState({ status: false, message: undefined });

  const [isSuccessfulUpload, setIsSuccessfulUpload] = useState(false);

  const handleFileLoad = async () => {
    setUploadingFile(true);
    await extendToken();
    return parsedCsv.length === 0
      ? (setError({ status: true, message: "Please select a file to upload" }),
        setUploadingFile(false))
      : parsedCsv.length > 500
      ? (setError({
          status: true,
          message:
            "Please select a file with small file. Tip: Have less than 500 entries for one upload",
        }),
        setUploadingFile(false))
      : fetch(`${ORDERS_API}/api/v2/uploadTracking`, {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
          body: JSON.stringify({
            employeeId: localStorage.getItem("employeeId") || "",
            shippedOrders: parsedCsv,
          }),
        })
          .then((response) => response.json())
          .then(async (res) => {
            setUploadingFile(false);
            setIsSuccessfulUpload(true);

            return res;
          })
          .catch((err) => {
            console.log("error fetching productOrders", err);
            setError({ status: true, message: err });
            setUploadingFile(false);
          });
  };

  return (
    <Box
      style={{
        paddingTop: "25px",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={uploadingFile}
        onClick={undefined}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error.status && (
        <Alert onClose={() => setError({ status: false })} severity="error">
          {error.message}
        </Alert>
      )}
      <Box
        style={{
          margin: "0 auto",
          maxWidth: "1200px",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          overflow: "hidden",
          boxShadow:
            "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
          borderRadius: "16px",
          minHeight: "500px",
          backgroundColor: isSuccessfulUpload && "#E3F2FD",
        }}
      >
        <Box
          style={{
            padding: "20px",
          }}
        >
          <h2>Upload Orders Tracking</h2>
          <br />
          <Stack direction="column" spacing={3}>
            {isSuccessfulUpload ? (
              <p>Your file has been uploaded successfully</p>
            ) : (
              <Stack direction="row" alignItems="center">
                <input
                  id="csvInput"
                  type="file"
                  accept=".csv"
                  onChange={(e) => {
                    let files = e.target.files;

                    if (files) {
                      files[0] !== undefined
                        ? Papa.parse(files[0], {
                            complete: function (results) {
                              setParsedCsv(results.data);
                            },
                            header: true,
                          })
                        : setError({ status: true });
                    } else {
                      setError({ status: true });
                    }
                  }}
                  style={{
                    border: 0,
                    borderRadius: "8px",

                    padding: "10px",
                  }}
                />

                {parsedCsv.length > 0 && (
                  <Box
                    onClick={() => {
                      document.getElementById("csvInput").value = null;
                      setParsedCsv([]);
                    }}
                  >
                    <CloseIcon />
                  </Box>
                )}
              </Stack>
            )}

            <Chip
              type="submit"
              label={isSuccessfulUpload ? "Upload another file" : "Upload"}
              onClick={() =>
                isSuccessfulUpload
                  ? setIsSuccessfulUpload(false)
                  : handleFileLoad()
              }
              style={{
                background: "#90CAF9",
              }}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
