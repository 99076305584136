import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactToPrint from "react-to-print";

import b64toBlob from "b64-to-blob";
import { base64ArrayBuffer } from "../../utils/byteArrayToPdf";

import {
  Alert,
  Button,
  Backdrop,
  CircularProgress,
  Stack,
  Table,
  TableRow,
  TableFooter,
  TablePagination,
  Box,
  IconButton,
  Chip,
  TextField,
  Link,
} from "@mui/material";
import Input from "@mui/material/Input";

import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VerifiedIcon from "@mui/icons-material/Verified";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Checkbox from "@mui/material/Checkbox";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import DesktopDateTimePicker from "@mui/lab/DesktopDateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PrintIcon from "@mui/icons-material/Print";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { useTheme } from "@mui/material/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import {createWebSocketConnection, closeWebSocketConnection, sendMessage } from 'components/PrinterGui/webSocketConnection';


import CardBody from "components/Card/CardBody.js";
import { OrderDetails } from "components/OrderDetails/OrderDetails";
// import StatusBadge from "components/StatusBadge/StatusBadge";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { ORDERS_API, SHIPPING_API, REWARDS_API } from "api-config";
import { extendToken } from "refresh-token/refreshToken";
import { is } from "date-fns/locale";

const useStyles = makeStyles(styles);

export default function OrderManagement() {
  const classes = useStyles();

  const [orderManagementPageOrders, setOrdeManagementPageOrders] = useState();
  const [errorLoadingOrdersData, setErrorLoadingOrdersData] = useState(false);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [allRowsSelected, setAllRowsSelected] = useState([]);
  const [isOrderDialogueOpen, setIsOrderDialogueOpen] = useState({
    orderId: null,
    open: false,
    tabId: null,
  });

  const [shippingFilter, setShippingFilter] = useState(
    window.localStorage.getItem("shippingFilter")
      ? [window.localStorage.getItem("shippingFilter")]
      : ["Unshipped"]
  );
  const [pickingFilter, setPickingFilter] = useState(
    shippingFilter[0] === "PickAndPack"
      ? []
      : window.localStorage.getItem("pickingFilter")
      ? [window.localStorage.getItem("pickingFilter")]
      : ["Unpicked"]
  );
  const [isSelectingPickingFilter, setIsSelectingPickingFilter] =
    useState(false);

  const [issueFilter, setIssueFilter] = useState(
    shippingFilter[0] === "PickAndPack"
      ? []
      : window.localStorage.getItem("issueFilter")
      ? [window.localStorage.getItem("issueFilter")]
      : ["NoIssue"]
  );
  const [isSelectingIssueFilter, setIsSelectingIssueFilter] = useState(false);

  const [isSelectingShippingFilter, setIsSelectingShippingFilter] =
    useState(false);

  const [unassigned, setUnassigned] = useState(false);
  const [duplicateOrders, setDuplicateOrders] = useState(false);
  const [expressOrders, setExpressOrders] = useState(false);

  const [isSelectingOtherFilter, setIsSelectingOtherFilter] = useState(false);

  const [searchString, setSearchString] = useState("");

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [isActionsListOpen, setIsActionsListOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [isDeletingOrderShipment, setIsDeletingOrderShipment] = useState(false);
  const [selectedOrderToDelete, setSelectedOrderToDelete] = useState();
  const [isAddingInternalNotes, setIsAddingInternalNotes] = useState({
    row: null,
    open: false,
  });
  const [internalNote, setInternalNote] = useState();
  const [internalNoteRowEditing, setInternalRowEditing] = useState();

  const [selectedActionRef, setSelectedActionRef] = useState(1);
  
  //new Tab
  const[newTab,setnewTab] = useState(0);


  useEffect(() => {
    window.localStorage.setItem("pickingFilter", pickingFilter);
    window.localStorage.setItem("issueFilter", issueFilter);
    window.localStorage.setItem("shippingFilter", shippingFilter);
  }, [pickingFilter, issueFilter, shippingFilter]);

  useEffect(async () => {
    window.localStorage.setItem("orderManagementPageNumber", 1);

    handlePageLoad();
  }, [rowsPerPage]);

  const openWebSocketInBackground = () => {
    const websocketUrl = 'ws://localhost:9000';
    createWebSocketConnection(websocketUrl, (message) => {
      console.log('Received message:', message);
    });
    localStorage.setItem('websocketConnected', 'true');
  }

  useEffect(() => {
    openWebSocketInBackground();
    return () => {
      handleCloseWebSocket();
    };
  }, []);


  const handlePageLoad = async (isSearchString) => {
    setIsSelectingPickingFilter(false);
    setIsSelectingIssueFilter(false);
    setIsSelectingShippingFilter(false);
    setIsSelectingOtherFilter(false);
    setSelectedOrderIds([]);
    setAllRowsSelected([]);

    await extendToken();

    return fetch(`${ORDERS_API}/api/v2/orderManagement`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`},
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        pickingStatus: pickingFilter,
        issueStatus: issueFilter,
        shippingStatus: shippingFilter,
        unassigned: unassigned,
        duplicateOrders: duplicateOrders,
        expressOrders: expressOrders,
        search: searchString,
        pageNumber: isSearchString
          ? "1"
          : window.localStorage.getItem("orderManagementPageNumber"),
        pageSize: rowsPerPage,
        from: fromDate,
        to: toDate,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        res.orders.map((o) => {
          if (
            o.shippingStatus === "Unshipped" &&
            o.pickingStatus === "Unpicked" &&
            o.issueStatus === "NoIssue" &&
            o.userId === ""
          ) {
            o.statusId = 0; // Grey
          }

          if (
            o.shippingStatus === "Unshipped" &&
            o.pickingStatus === "Unpicked" &&
            o.issueStatus === "NoIssue" &&
            o.userId
          ) {
            o.statusId = 1; //Amber
          }

          if (
            o.shippingStatus === "Unshipped" &&
            o.pickingStatus === "Picked" &&
            o.issueStatus === "NoIssue" &&
            o.userId
          ) {
            o.statusId = 2; //Green
          }

          if (o.shippingStatus === "Unshipped" && o.issueStatus === "Issue") {
            o.statusId = 3; //Red
          }
        });

        setOrdeManagementPageOrders(res.orders);

        // Set total orders count
        setTotalOrdersCount(res.totalOrders);

        return res.orders;
      })
      .catch((err) => {
        console.log("error fetching productOrders", err);
        setOrdeManagementPageOrders(null);
        setErrorLoadingOrdersData(true);
      });
  };

  const handlePageChange = async (event, newPage) => {
    window.localStorage.setItem("orderManagementPageNumber", newPage + 1);
    await handlePageLoad();
  };

  const handlePageSize = async (event) => {
    window.localStorage.setItem(
      "orderManagementPageNumber",
      parseInt(event.target.value, 10)
    );
    setRowsPerPage(parseInt(event.target.value, 10));

    await handlePageLoad();
  };

  const handleOrderRowSelection = (selectedId) => {
    if (!selectedOrderIds.includes(selectedId)) {
      setSelectedOrderIds([...selectedOrderIds, selectedId]);
    } else if (selectedOrderIds.includes(selectedId)) {
      let newIdList = selectedOrderIds.filter((id) => id !== selectedId);
      setSelectedOrderIds(newIdList);
    }
  };

  const handleAllRowSelection = () => {
    let idsInPage = orderManagementPageOrders.map((o) => o._id);

    if (allRowsSelected.length > 0) {
      setAllRowsSelected([]);
      setSelectedOrderIds([]);
    } else if (allRowsSelected.length === 0) {
      setAllRowsSelected(idsInPage);
      setSelectedOrderIds(idsInPage);
    }
  };

  const handleActions = async (orderIds, unAssign, pick, issue, ship) => {
    await extendToken();
    return fetch(`${ORDERS_API}/api/v2/action`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        userId: unAssign ? "" : undefined,
        idList: orderIds,
        shippingStatus:
          ship === "Dispatched"
            ? "Dispatched"
            : ship === "Cancelled"
            ? "Cancelled" 
            : ship === "ReadyToPrint"
            ? "ReadyToPrint" 
            : ship === "Error"
            ? "Error" 
            :ship === true
            ? "Shipped"
            : ship === false
            ? "Unshipped"
            : undefined,
        issueStatus:
          issue === true ? "Issue" : issue === false ? "NoIssue" : undefined,
        pickingStatus: pick
          ? "Picked"
          : pick === false
          ? "Unpicked"
          : undefined,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        handlePageLoad();
      })
      .catch((err) => {
        console.log("error fetching productOrders", err);
      });
  };

  const handlePickFilter = (value) => {
    if (shippingFilter.includes("PickAndPack")) return;

    pickingFilter.includes(value)
      ? setPickingFilter((pickingFilter) =>
          pickingFilter.filter((e) => e !== value)
        )
      : setPickingFilter((pickingFilter) => [...pickingFilter, value]);
  };

  const handleIssueFilter = (value) => {
    if (shippingFilter.includes("PickAndPack")) return;

    issueFilter.includes(value)
      ? setIssueFilter((issueFilter) => issueFilter.filter((e) => e !== value))
      : setIssueFilter((issueFilter) => [...issueFilter, value]);
  };

  const handleShippingFilter = (value) => {
    if (value === "PickAndPack" && !shippingFilter.includes("PickAndPack")) {
      setIssueFilter([]);
      setPickingFilter([]);
      setShippingFilter(["PickAndPack"]);
      setUnassigned(false);
      setDuplicateOrders(false);
      setExpressOrders(false);
      return;
    }

    if (shippingFilter.includes("PickAndPack") && value != "PickAndPack")
      return;

    shippingFilter.includes(value)
      ? setShippingFilter((shippingFilter) =>
          shippingFilter.filter((e) => e !== value)
        )
      : setShippingFilter((shippingFilter) => [...shippingFilter, value]);
  };

  const handleShipmentStatusRollback = async (shippingStatus) => {
    await extendToken();
    return fetch(`${SHIPPING_API}/api/v2/deleteShipment`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        orderIds: selectedOrderToDelete.orderMeta.orderIds,
        shippingStatus: shippingStatus,
        shipmentDetails: [
          selectedOrderToDelete.orderMeta.shipmentDetails[
            selectedOrderToDelete.shipKey
          ],
        ],
      }),
    })
      .then((response) => response.json())
      .then(() => {
        setIsDeletingOrderShipment(false);
        handlePageLoad();
      })
      .catch((err) => {
        console.log("error deleting shipment", err);
      });
  };

  const handleOrderDeletion = (orderMeta, key) => {
    setSelectedOrderToDelete({ orderMeta: orderMeta, shipKey: key });
    setIsDeletingOrderShipment(true);
  };

  const time = new Date();

  let windowHeight = window.innerHeight;
  useEffect(() => {
    windowHeight = window.innerHeight;
  }, [window.innerHeight]);

  let actionsList = [
    "Unassign all orders",
    "Mark all orders as picked",
    "Mark all orders as un-picked",
    "Mark all orders as issues",
    "Mark all orders as no-issues",
    "Mark all orders as shipped",
    "Mark all orders as un-shipped",
    "Mark all orders as dispatched",
    "Mark all orders as cancelled",
    "Mark all orders as ReadyToPrint",
  ];

  const handleActionOnAllOrders = (event, index) => {
    if (index === 0) {
      handleActions(selectedOrderIds, true, undefined, undefined, undefined);
    }
    if (index === 1) {
      handleActions(selectedOrderIds, undefined, true, undefined, undefined);
    }
    if (index === 2) {
      handleActions(selectedOrderIds, undefined, false, undefined, undefined);
    }
    if (index === 3) {
      handleActions(selectedOrderIds, undefined, undefined, true, undefined);
    }
    if (index === 4) {
      handleActions(selectedOrderIds, undefined, undefined, false, undefined);
    }
    if (index === 5) {
      handleActions(selectedOrderIds, undefined, undefined, undefined, true);
    }
    if (index === 6) {
      handleActions(selectedOrderIds, undefined, undefined, undefined, false);
    }
    if (index === 7) {
      handleActions(
        selectedOrderIds,
        undefined,
        undefined,
        undefined,
        "Dispatched"
      );
    }
    if (index === 8) {
      handleActions(selectedOrderIds, undefined, undefined, undefined, "Cancelled");
    }
    if (index === 9) {
      handleActions(selectedOrderIds, undefined, undefined, undefined, "ReadyToPrint");
    }

    setSelectedActionRef(index);
    setIsActionsListOpen(false);
  };

  const handleActionClickAwayListener = () => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setIsActionsListOpen(false);
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  let printComponentRef = useRef();
  const printRef = useRef();

  const printButtonRef = useRef();

  const [selectedOrderForPrinting, setSelectedOrderForPrinting] = useState(0);

  const openNewTab = (response) => {
    let contentType = "application/pdf";
    let sliceSize = 512;

    let byteCharacters = atob(response);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    blob = b64toBlob(response, contentType);
    let blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl);
  };

  const handleOrderPrint = (data, o) => {
    const isLocalStorageTrue = localStorage.getItem('websocketConnected') === 'true';
  
    if (data.pdfLink) {
      if (isLocalStorageTrue) {
        sendMessage(data.pdfLink, true, "Label Printer", false);
      } else {
        window.open(data.pdfLink);
      }
    } else if (data.pdfData) {
      const base64 = base64ArrayBuffer(JSON.parse(data.pdfData));
      if (isLocalStorageTrue) {
        sendMessage(base64, false, "Label Printer", false);
      } else {
        openNewTab(base64);
      }
    } else {
      console.log("PDF link and data not available");
    }
  
    setSelectedOrderForPrinting(o);
    printRef.current?.click();
  };  

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  const createShipment = async (orderDetails, key, labelType) => {
    setSelectedOrderForPrinting(key);
    await extendToken();

    return fetch(`${SHIPPING_API}/api/v2/createShipment`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        orderIds: orderDetails.orderIds,
        address: {
          name: `${orderDetails.shippingFirstName} ${orderDetails.shippingLastName}`,
          companyName: `${orderDetails.shippingCompanyName}`,
          addressLine1: orderDetails.shippingAddressLine1,
          addressLine2: orderDetails.shippingAddressLine2,
          city: orderDetails.shippingCity,
          stateOrProvince: orderDetails.shippingStateOrProvince,
          postalCode: orderDetails.shippingPostalCode,
          country: orderDetails.shippingCountry,
          phone: orderDetails.shippingDaytimePhone,
          email: orderDetails.buyerEmailAddress,
        },
        items: [
          {
            Reference: orderDetails._id,
            Quantity: 1,
            Weight: 0.5,
            Length: 10,
            Width: 10,
            Height: 10,
          },
        ],
        courierName: "INHOUSE",
        siteName: orderDetails.siteName,
        labelType: labelType,
        shippingClass: orderDetails.requestedShippingClass,
        parcelType: "Large letter",
        price: 2.2,
        labelNumber: "",
        specialInstructions: orderDetails.specialInstructions,
      }),
    })
      .then(async (response) => {
        await ingestProcessingRewards(orderDetails._id);
        return response.json();
      })
      .then((res) => {
        if (labelType != "Print Label" && !res.labels) {
          printButtonRef?.current?.click();
        } else if (res.labels && labelType === "Env Label") {
          res.labels.map((l) => {
            const isLocalStorageTrue = localStorage.getItem('websocketConnected') === 'true';
            if (isLocalStorageTrue) {
              sendMessage(l.pdfLink, true, "Label Printer", true);
            } else {
              window.open(l.pdfLink);
            }
            handlePageLoad();
          });
        } else if (labelType == "Print Label" && orderDetails.shipmentDetails[0].pdfLink == null) {
          console.log("PDF link value not available.");
        } else { 
          const isLocalStorageTrue = localStorage.getItem('websocketConnected') === 'true';
          if (isLocalStorageTrue && orderDetails.shipmentDetails[0].courierName != "INHOUSE") {
            sendMessage(orderDetails.shipmentDetails[0].pdfLink, true, "Label Printer", false);
          } else if (isLocalStorageTrue && orderDetails.shipmentDetails[0].courierName === "INHOUSE") {
            sendMessage(orderDetails.shipmentDetails[0].pdfLink, true, "Label Printer", true);
          }else {
            window.open(orderDetails.shipmentDetails[0].pdfLink, '_blank');
          }
          handlePageLoad();
        }
      })
      .catch((err) => {
        console.log("error creating shipment", err);
      });
  };

  const ingestProcessingRewards = async (orderId) => {
    await extendToken();
    return fetch(`${REWARDS_API}/api/v2/ingestRewards`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        transactionType: "PROCESSED",
        orderIds: [orderId],
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log("error ingesting rewards for picked orders", err);
      });
  };

  const updateInternalNotes = async (orderId, note) => {
    await extendToken();
    return fetch(`${ORDERS_API}/api/v2/internalNote`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        orderID: orderId,
        internalNote: note,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        handlePageLoad();
      })
      .catch((err) => {
        console.log("error updating internal notes", err);
      });
  };

  class ComponentToPrint extends React.Component {
    render() {
      return orderManagementPageOrders &&
        orderManagementPageOrders[selectedOrderForPrinting] ? (
        <Stack
          spacing={3}
          paddingLeft={5}
          sx={{
            transform: "rotate(90deg)",
          }}
        >
          <Box
            sx={{
              fontSize: "16px",
            }}
            paddingLeft={3}
          >
            {` From:`}
            <br /> {` Eg Trade P/L, PO BOX 222`}
            <br /> {` Heidelberg VIC 3084`}
            <br />
            <br />
          </Box>
          {orderManagementPageOrders &&
            orderManagementPageOrders.length > 0 && (
              <Box
                paddingLeft={10}
                paddingRight={3}
                sx={{
                  fontSize: "18px",
                }}
              >
                <Box
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  To: <br />
                  {`${orderManagementPageOrders[selectedOrderForPrinting].shippingFirstName} ${orderManagementPageOrders[selectedOrderForPrinting].shippingLastName}`}
                  <br />
                </Box>
                {orderManagementPageOrders[selectedOrderForPrinting]
                  .shippingAddressLine1 && (
                  <>
                    {
                      orderManagementPageOrders[selectedOrderForPrinting]
                        .shippingAddressLine1
                    }
                    <br />
                  </>
                )}
                {orderManagementPageOrders[selectedOrderForPrinting]
                  .shippingAddressLine2 && (
                  <>
                    {
                      orderManagementPageOrders[selectedOrderForPrinting]
                        .shippingAddressLine2
                    }
                    <br />
                  </>
                )}
                {orderManagementPageOrders[selectedOrderForPrinting]
                  .shippingCity && (
                  <>
                    {
                      orderManagementPageOrders[selectedOrderForPrinting]
                        .shippingCity
                    }
                    <br />
                  </>
                )}
                {
                  orderManagementPageOrders[selectedOrderForPrinting]
                    .shippingStateOrProvince
                }{" "}
                {
                  orderManagementPageOrders[selectedOrderForPrinting]
                    .shippingPostalCode
                }
              </Box>
            )}

          {/* <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              width: "100%",
            }}
          >
            <Box>ENV</Box>
            <Box>
              <CustomBarcode />
            </Box>
          </Stack> */}
        </Stack>
      ) : (
        <></>
      );
    }
  }

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!orderManagementPageOrders && !errorLoadingOrdersData}
        onClick={undefined}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {errorLoadingOrdersData && (
        <Alert
          onClose={() => setErrorLoadingOrdersData(false)}
          severity="error"
        >
          Error loading Orders data. Please try again later.
        </Alert>
      )}

      <Dialog
        open={isDeletingOrderShipment}
        onClose={() => setIsDeletingOrderShipment(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {"Roll back order shipment status"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Stack direction="row" spacing={4}>
              <Button
                variant="contained"
                onClick={() => handleShipmentStatusRollback("Shipped")}
              >
                Move to Shipped
              </Button>
              <Button
                variant="contained"
                onClick={() => handleShipmentStatusRollback("Unshipped")}
              >
                Move to UnShipped
              </Button>
            </Stack>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {orderManagementPageOrders && (
        <div style={{ width: "100%", padding: "15px" }}>
          <GridContainer style={{ width: "100%", margin: 0 }}>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{ width: "100%", margin: 0 }}
            >
              <Card style={{ margin: 0 }}>
                <CardHeader>
                  {orderManagementPageOrders.length > 0 ? (
                    <>
                      <p className={classes.cardCategoryWhite}>
                        As on {time.toDateString()}
                      </p>
                    </>
                  ) : (
                    <Button onClick={() => handlePageLoad()}>
                      Refresh Orders List
                    </Button>
                  )}

                  <Stack direction="column" spacing={4}>
                    <Box
                      style={{
                        paddingTop: "25px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <FormControl variant="standard">
                          <Input
                            id="input-with-icon-adornment"
                            value={
                              searchString ||
                              localStorage.getItem("searchString") ||
                              ""
                            }
                            onChange={(e) => {
                              setSearchString(e.target.value);
                              window.localStorage.setItem(
                                "searchString",
                                e.target.value
                              );
                            }}
                            sx={{
                              borderBottom: "#90CAF9",
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handlePageLoad(true);
                              }
                            }}
                          />
                        </FormControl>

                        <Chip
                          type="submit"
                          label={"Search"}
                          onClick={() => handlePageLoad(true)}
                          style={{
                            background: "#90CAF9",
                          }}
                        />

                        <>
                          <ButtonGroup
                            variant="contained"
                            ref={anchorRef}
                            aria-label="split button"
                          >
                            <Button
                              onClick={undefined}
                              style={{ background: "#90CAF9" }}
                            >
                              {"Actions"}
                            </Button>
                            <Button
                              size="small"
                              style={{ background: "#90CAF9" }}
                              onClick={() =>
                                setIsActionsListOpen((prevOpen) => !prevOpen)
                              }
                            >
                              <ArrowDropDownIcon />
                            </Button>
                          </ButtonGroup>
                          <Popper
                            open={isActionsListOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            style={{
                              zIndex: 10,
                            }}
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom"
                                      ? "center top"
                                      : "center bottom",
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener
                                    onClickAway={() =>
                                      handleActionClickAwayListener()
                                    }
                                  >
                                    <MenuList id="split-button-menu">
                                      {actionsList.map((option, index) => (
                                        <MenuItem
                                          key={option}
                                          selected={index === selectedActionRef}
                                          onClick={(event) =>
                                            handleActionOnAllOrders(
                                              event,
                                              index
                                            )
                                          }
                                          disabled={
                                            shippingFilter.includes(
                                              "PickAndPack"
                                            )
                                              ? !(
                                                  shippingFilter.length === 1 &&
                                                  shippingFilter.includes(
                                                    "PickAndPack"
                                                  ) &&
                                                  index === 7
                                                )
                                              : index === 7
                                          }
                                        >
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </>
                      </Stack>
                    </Box>

                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        style={{
                          color: "#90CAF9",
                        }}
                        indeterminate={false}
                        checked={allRowsSelected.length > 0}
                        onChange={() => handleAllRowSelection()}
                      />

                      <Box
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={{ xs: 1, sm: 2 }}
                          onClick={() =>
                            setIsSelectingPickingFilter(
                              !isSelectingPickingFilter
                            )
                          }
                          style={{
                            border:
                              pickingFilter.length > 0
                                ? "2px solid #595c97"
                                : "2px solid #e4e9f0",
                            padding: "5px",
                            color: pickingFilter.length > 0 && "#595c97",
                          }}
                        >
                          <p style={{ margin: 0 }}>Picking Status</p>
                          {isSelectingPickingFilter ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </Stack>
                        {isSelectingPickingFilter && (
                          <Stack direction="row">
                            <Box
                              style={{
                                margin: 0,
                                position: "absolute",
                                top: "100%",
                                left: 0,
                                width: "150px",
                                zIndex: 2,
                                border: "1px solid rgba(0, 0, 0, 0.04)",
                                boxShadow:
                                  "0 16px 24px 2px rgba(0, 0, 0, 0.14)",
                                backgroundColor: "white",
                              }}
                            >
                              <Stack direction="column" spacing={2}>
                                {["Picked", "Unpicked"].map((name) => (
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    key={name}
                                    alignItems="center"
                                    onClick={() => handlePickFilter(name)}
                                  >
                                    <Checkbox
                                      checked={pickingFilter.indexOf(name) > -1}
                                    />
                                    <Box>{name}</Box>
                                  </Stack>
                                ))}
                              </Stack>
                            </Box>
                          </Stack>
                        )}
                      </Box>

                      <Box
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={{ xs: 1, sm: 2 }}
                          onClick={() =>
                            setIsSelectingIssueFilter(!isSelectingIssueFilter)
                          }
                          style={{
                            border:
                              issueFilter.length > 0
                                ? "2px solid #595c97"
                                : "2px solid #e4e9f0",
                            padding: "5px",
                            color: issueFilter.length > 0 && "#595c97",
                          }}
                        >
                          <p style={{ margin: 0 }}>Issue Status</p>
                          {isSelectingIssueFilter ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </Stack>
                        {isSelectingIssueFilter && (
                          <Box
                            style={{
                              margin: 0,
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              width: "150px",
                              zIndex: 2,
                              border: "1px solid rgba(0, 0, 0, 0.04)",
                              boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14)",
                              backgroundColor: "white",
                            }}
                          >
                            {["Issue", "NoIssue"].map((name) => (
                              <Stack
                                direction="row"
                                spacing={1}
                                key={name}
                                alignItems="center"
                                onClick={() => handleIssueFilter(name)}
                              >
                                <Checkbox
                                  checked={issueFilter.indexOf(name) > -1}
                                />
                                <Box>{name}</Box>
                              </Stack>
                            ))}
                          </Box>
                        )}
                      </Box>

                      <Box
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={{ xs: 1, sm: 2 }}
                          onClick={() =>
                            setIsSelectingShippingFilter(
                              !isSelectingShippingFilter
                            )
                          }
                          style={{
                            border:
                              shippingFilter.length > 0
                                ? "2px solid #595c97"
                                : "2px solid #e4e9f0",
                            padding: "5px",
                            color: shippingFilter.length > 0 && "#595c97",
                          }}
                        >
                          <p style={{ margin: 0 }}>Shipment Status</p>
                          {isSelectingShippingFilter ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </Stack>
                        {isSelectingShippingFilter && (
                          <Box
                            style={{
                              margin: 0,
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              width: "150px",
                              zIndex: 2,
                              border: "1px solid rgba(0, 0, 0, 0.04)",
                              boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14)",
                              backgroundColor: "white",
                            }}
                          >
                            {["Error", "Shipped", "ReadyToPrint", "Unshipped", "PickAndPack", "Cancelled"].map(
                              (name) => (
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  key={name}
                                  alignItems="center"
                                  onClick={() => handleShippingFilter(name)}
                                >
                                  <Checkbox
                                    checked={shippingFilter.indexOf(name) > -1}
                                  />
                                  <Box>{name}</Box>
                                </Stack>
                              )
                            )}
                          </Box>
                        )}
                      </Box>

                      <Box
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={{ xs: 1, sm: 2 }}
                          onClick={() =>
                            setIsSelectingOtherFilter(!isSelectingOtherFilter)
                          }
                          style={{
                            border: unassigned
                              ? "2px solid #595c97"
                              : "2px solid #e4e9f0",
                            padding: "5px",
                            color: unassigned && "#595c97",
                          }}
                        >
                          <p style={{ margin: 0 }}>Others</p>
                          {isSelectingOtherFilter ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </Stack>
                        {isSelectingOtherFilter && (
                          <Box
                            style={{
                              margin: 0,
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              width: "160px",
                              zIndex: 2,
                              border: "1px solid rgba(0, 0, 0, 0.04)",
                              boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14)",
                              backdropFilter: "blur(6px)",
                              backgroundColor: "white",
                            }}
                          >
                            {
                              <Stack direction="column">
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  onClick={() =>
                                    !shippingFilter.includes("PickAndPack") &&
                                    setUnassigned(!unassigned)
                                  }
                                >
                                  <Checkbox checked={unassigned} />
                                  <Box>Unassigned</Box>
                                </Stack>

                                <Stack
                                  direction="row"
                                  spacing={1}
                                  key={name}
                                  alignItems="center"
                                  onClick={() =>
                                    !shippingFilter.includes("PickAndPack") &&
                                    setDuplicateOrders(!duplicateOrders)
                                  }
                                >
                                  <Checkbox checked={duplicateOrders} />
                                  <Box>DuplicateOrders</Box>
                                </Stack>

                                <Stack
                                  direction="row"
                                  spacing={1}
                                  key={name}
                                  alignItems="center"
                                  onClick={() =>
                                    !shippingFilter.includes("PickAndPack") &&
                                    setExpressOrders(!expressOrders)
                                  }
                                >
                                  <Checkbox checked={expressOrders} />
                                  <Box>ExpressOrders</Box>
                                </Stack>
                              </Stack>
                            }
                          </Box>
                        )}
                      </Box>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack direction="row" spacing={2}>
                          <DesktopDateTimePicker
                            value={fromDate || null}
                            onChange={(newValue) => {
                              setFromDate(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField variant="standard" {...params} />
                            )}
                          />
                          <DesktopDateTimePicker
                            minDateTime={fromDate}
                            minTime={fromDate}
                            value={toDate || null}
                            onChange={(newValue) => {
                              setToDate(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField variant="standard" {...params} />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Stack>
                  </Stack>
                </CardHeader>

                <CardBody
                  style={{
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  {orderManagementPageOrders.length === 0 && (
                    <p>No orders to show!!</p>
                  )}

                  <Box style={{ display: "none" }}>
                    <ReactToPrint
                      trigger={() => <Button ref={printButtonRef} />}
                      content={() => printComponentRef}
                      onAfterPrint={() => handlePageLoad()}
                      pageStyle={`
                                                      @page {
                                                        size: 105mm 148mm;
                                                      }

                                                      @media all {
                                                        .pagebreak {
                                                          display: none;
                                                        }
                                                      }
                                                    
                                                      @media print {
                                                        .pagebreak {
                                                          page-break-before: always;
                                                        }
                                                      }
                                                `}
                    />
                    <div style={{ display: "none" }}>
                      <ComponentToPrint
                        ref={(el) => (printComponentRef = el)}
                      />
                    </div>
                  </Box>
                  {orderManagementPageOrders.length > 0 && (
                    <Box
                      style={{
                        overflowY: "scroll",
                        overflow: "scroll",
                        maxHeight: windowHeight * 0.82,
                        width: "100%",
                      }}
                    >
                      <Box>
                        {orderManagementPageOrders.length > 0 &&
                          orderManagementPageOrders.map((o, key) => (
                            <Box
                              key={key}
                              style={{
                                backgroundColor:
                                  o.requestedShippingClass === "Express"
                                    ? "#FFFF66"
                                    : o.shippingCountry != "AU"
                                    ? "#f3ceff"
                                    : o.selectedQuote && o.selectedQuote.shipmentItems && o.selectedQuote.shipmentItems.length > 0 && (o.selectedQuote.shipmentItems[0].productId === "INH8" || o.selectedQuote.shipmentItems[0].productId === "INH5")
                                    ? o.selectedQuote.shipmentItems[0].productId === "INH8" ? "#f09e90" : "#d1edd8"
                                    : (o.shippingLastName === "Shopify POS Guest Customer" || o.siteName === "Amazon WI")
                                    ? "#ffa500" //Mark orange for Amazon orders sent through EIZ
                                    : o.requestedShippingClass.includes("Click n Collect")
                                    ? "#00FF00" //Mark Green for Click and Collect Orders
                                    : o.requestedShippingClass.includes("Click & Collect")
                                    ? "#00FF00" //Mark Green for Click and Collect Orders
                                    : new Set(o.orderIds).size !==
                                      o.orderIds.length //Mark red for duplicate order id's
                                    ? "#ffe1df"
                                    : "#f9fafc",
                                padding: "15px",
                                margin: "15px",
                                boxShadow:
                                  "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                                borderRadius: "16px",
                                minWidth: 0,
                              }}
                            >
                              <Stack direction="column" spacing={2}>
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Stack
                                    direction="column"
                                    spacing={0}
                                    paddingRight={2}
                                  >
                                    {o.items.map((i, key) => (
                                      <ProductItemLine itemLine={i} key={key} />
                                    ))}
                                  </Stack>

                                  <Stack
                                    spacing={1}
                                    style={{
                                      minWidth: 0,
                                      width: "300px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <Stack>
                                      <Box>
                                        {o.shippingFirstName}{" "}
                                        {o.shippingLastName}
                                      </Box>
                                      <Box>{o.shippingCompanyName}</Box>
                                      <Box>
                                        {o.shippingAddressLine1}{" "}
                                        {o.shippingAddressLine2}{" "}
                                        {o.shippingCity}
                                      </Box>
                                      <Box>{`${
                                        o.shippingStateOrProvince || ""
                                      } ${o.shippingCountry} ${
                                        o.shippingPostalCode
                                      }`}</Box>
                                    </Stack>
                                  </Stack>
                                </Box>

                                <Stack direction="row" spacing={2}>
                                  {internalNoteRowEditing != key && (
                                    <>
                                      {!o.internalNote ? (
                                        <Link
                                          onClick={() => {
                                            setIsAddingInternalNotes({
                                              row: key,
                                              open: true,
                                            });
                                            setInternalRowEditing(key);
                                          }}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          Add Notes
                                        </Link>
                                      ) : (
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          alignItems="center"
                                        >
                                          <Box
                                            sx={{
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                              color: "#595c97",
                                            }}
                                          >
                                            {o.internalNote}
                                          </Box>
                                          <IconButton
                                            id="edit-internal-note"
                                            onClick={() => {
                                              setIsAddingInternalNotes({
                                                row: key,
                                                open: true,
                                              });
                                              setInternalRowEditing(key);
                                            }}
                                          >
                                            <EditIcon
                                              sx={{
                                                cursor: "pointer",
                                                height: "15px",
                                              }}
                                            />
                                          </IconButton>
                                        </Stack>
                                      )}
                                    </>
                                  )}

                                  {isAddingInternalNotes.open &&
                                    key === isAddingInternalNotes.row && (
                                      <>
                                        <textarea
                                          name="body"
                                          onChange={(e) =>
                                            setInternalNote(e.target.value)
                                          }
                                          defaultValue={o.internalNote}
                                        />

                                        <IconButton
                                          id="edit-internal-note"
                                          onClick={async () => {
                                            await updateInternalNotes(
                                              o._id,
                                              internalNote || o.internalNote
                                            );
                                            setIsAddingInternalNotes({
                                              row: key,
                                              open: false,
                                            });
                                            setInternalRowEditing(null);
                                            setInternalNote();
                                          }}
                                        >
                                          <CheckCircleIcon
                                            sx={{
                                              color: "green",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </IconButton>

                                        <IconButton
                                          id="edit-internal-note"
                                          onClick={async () => {
                                            await updateInternalNotes(
                                              o._id,
                                              ""
                                            );
                                            setIsAddingInternalNotes({
                                              row: key,
                                              open: false,
                                            });
                                            setInternalRowEditing(null);
                                            setInternalNote();
                                          }}
                                        >
                                          <DeleteIcon
                                            color="error"
                                            sx={{
                                              cursor: "pointer",
                                            }}
                                          />
                                        </IconButton>
                                      </>
                                    )}
                                </Stack>
                                <br />
                                {o.pickerNote}

                                <Stack
                                  direction="row"
                                  justifyContent={"space-between"}
                                  alignItems="center"
                                >
                                  <Stack>
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      alignItems="center"
                                    >
                                      <Box>
                                        {o.orderIds.map(
                                          (oi, oId) =>
                                            `${oi}${
                                              o.orderIds.length != oId + 1
                                                ? `,`
                                                : ``
                                            }`
                                        )}
                                      </Box>

                                      <Box
                                        style={{
                                          color: "#595c97",
                                        }}
                                      >
                                        {o.siteName}
                                      </Box>
                                      <Box
                                        style={{
                                          color: "#595c97",
                                        }}
                                      >
                                        {o?.pickingDetail?.packingStationId }
                                      </Box>
                                      <Box
                                        style={{
                                          color: "#595c97",
                                        }}
                                      >
                                        {o.requestedShippingClass}
                                      </Box>
                                      <Box>
                                        {`${
                                          new Date(
                                            o.appImportDateTime
                                          ).toLocaleString() || ""
                                        }`}
                                      </Box>

                                      <IconButton
                                        id="sub-action"
                                        aria-haspopup="true"
                                        onClick={() =>
                                          setIsOrderDialogueOpen({
                                            orderId: key,
                                            open: !isOrderDialogueOpen.open,
                                            tabId: 0,
                                          })
                                        }
                                      >
                                        <MoreHorizIcon />
                                      </IconButton>
                                    </Stack>

                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <Checkbox
                                        style={{
                                          color: "#90CAF9",
                                        }}
                                        indeterminate={false}
                                        checked={selectedOrderIds.includes(
                                          o._id
                                        )}
                                        onChange={() =>
                                          handleOrderRowSelection(o._id)
                                        }
                                      />

                                      <Button
                                        variant="outlined"
                                        
                                        disabled={
                                          (o.shipmentDetails === undefined || o.shipmentDetails.length === 0 || !o.shipmentDetails[0]?.pdfLink)
                                        }
                                      onClick={() => {createShipment(o,key, "Print Label")}}
                                      >
                                        Print Label
                                      </Button>

                                      <Button
                                        variant="outlined"
                                        
                                        disabled={
                                          (o.shipmentDetails != undefined && o.shipmentDetails.length != 0 && o.shipmentDetails[0]?.pdfLink) ||
                                          o.shippingCountry != "AU" ||
                                          new Set(o.orderIds).size !==
                                            o.orderIds.length ||
                                          o.orderTags === "OzdingoShopping" ||
                                          o.siteName === "Catch AU" ||
                                          o.shippingLastName === "Shopify POS Guest Customer" ||                                                                 
                                          o.requestedShippingClass.includes("Click n Collect") ||
                                          o.requestedShippingClass.includes("Click & Collect") ||
                                          o.requestedShippingClass.includes("Direct Freight Courier via Road")
                                        }
                                      onClick={() => {createShipment(o,key, "Env Label")}}
                                      >
                                        Env Label
                                      </Button>

                                      <Button
                                        variant="outlined"
                                        disabled={
                                          new Set(o.orderIds).size !==
                                          o.orderIds.length ||
                                          (o.shipmentDetails != undefined && o.shipmentDetails.length != 0 && o.shipmentDetails[0]?.pdfLink) ||
                                          o.shippingLastName === "Shopify POS Guest Customer" ||
                                          o.siteName === "Amazon WI" ||
                                          o.requestedShippingClass.includes("Click n Collect") ||
                                          o.requestedShippingClass.includes("Click & Collect") ||
                                          o.requestedShippingClass.includes("Direct Freight Courier via Road")
                                        }
                                        onClick={() =>
                                          setIsOrderDialogueOpen({
                                            orderId: key,
                                            open: !isOrderDialogueOpen.open,
                                            tabId: 1,
                                          })
                                        }
                                      >
                                        Shipment
                                      </Button>

                                      <Button
                                        variant="outlined"
                                        onClick={() =>
                                          setIsOrderDialogueOpen({
                                            orderId: key,
                                            open: !isOrderDialogueOpen.open,
                                            tabId: 2,
                                          })
                                        }
                                      >
                                        Logs
                                      </Button>
                                    </Stack>
                                  </Stack>

                                  <Stack
                                    sx={{
                                      width: "300px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        maxHeight: "75px",
                                        overflow: "scroll",
                                      }}
                                    >
                                      {o.shipmentDetails != undefined &&
                                        o.shipmentDetails.map((sd, key) => {
                                          return (
                                            <Stack
                                              direction="row"
                                              key={key}
                                              paddingY={0.5}
                                            >
                                              <Stack sx={{ width: "90%" }}>
                                                <Stack
                                                  direction="row"
                                                  spacing={1}
                                                  alignItems="center"
                                                >
                                                  <Box>Shipping Carrier:</Box>
                                                  <Box
                                                    style={{
                                                      fontSize: "16px",
                                                      color: "#595c97",
                                                      fontWeight: "bolder",
                                                    }}
                                                  >
                                                    {sd?.shippingCarrier}
                                                  </Box>
                                                </Stack>

                                                <Stack
                                                  direction="row"
                                                  spacing={1}
                                                  alignItems="center"
                                                >
                                                  <Box>Price:</Box>
                                                  <Box
                                                    style={{
                                                      fontSize: "12px",
                                                      color: "#595c97",
                                                      fontWeight: "bolder",
                                                    }}
                                                  >
                                                    {sd?.ShippingCost}
                                                  </Box>
                                                </Stack>
                                                <Stack
                                                  direction="row"
                                                  spacing={1}
                                                  alignItems="center"
                                                >
                                                  <Box>Tracking:</Box>
                                                  <Box
                                                    style={{
                                                      fontSize: "12px",
                                                      color: "#595c97",
                                                      fontWeight: "bolder",
                                                    }}
                                                  >
                                                    {sd?.trackingNumber ? (
                                                      sd.trackingNumber
                                                    ) : (
                                                      sd?.shippedItems && sd.shippedItems.length > 0 ? 
                                                        sd.shippedItems.map(item => item.itemTrackingId).join(", ") :
                                                        "No Tracking Available"
                                                    )}
                                                  </Box>
                                                </Stack>
                                                <Stack
                                                  direction="row"
                                                  spacing={1}
                                                  alignItems="center"
                                                >
                                                  <Box>CA notified:</Box>
                                                  <Box
                                                    style={{
                                                      fontSize: "16px",
                                                      color: "#595c97",
                                                      fontWeight: "bolder",
                                                    }}
                                                  >
                                                    {sd.notifyChannelAdvisor
                                                      ? "Yes"
                                                      : "No"}
                                                  </Box>
                                                </Stack>
                                              </Stack>
                                              <Stack alignItems="center">
                                                <Button
                                                  ref={printRef}
                                                  onClick={() => {
                                                    handleOrderPrint(sd, o);
                                                  }}
                                                  sx={{ cursor: "pointer" }}
                                                >
                                                  <PrintIcon
                                                    sx={{
                                                      cursor: "pointer",
                                                      color: "#90caf9",
                                                    }}
                                                  />
                                                </Button>
                                                {sd?.deleteEnable && (
                                                  <Box
                                                    onClick={() =>
                                                      handleOrderDeletion(
                                                        o,
                                                        key
                                                      )
                                                    }
                                                    sx={{ cursor: "pointer" }}
                                                  >
                                                    <DeleteOutlineIcon
                                                      sx={{
                                                        color: "#d32f2f",
                                                      }}
                                                    />
                                                  </Box>
                                                )}
                                              </Stack>
                                            </Stack>
                                          );
                                        })}
                                    </Box>
                                  </Stack>
                                </Stack>
                              </Stack>
                              <Box>
                                <ReactToPrint
                                  trigger={() => (
                                    <Button ref={printRef}></Button>
                                  )}
                                  content={() => printComponentRef}
                                  // onAfterPrint={() => onShipmentComplete()}
                                  pageStyle={`
                                                      @page {
                                                        size: 105mm 148mm;
                                                      }

                                                      @media all {
                                                        .pagebreak {
                                                          display: none;
                                                        }
                                                      }
                                                    
                                                      @media print {
                                                        .pagebreak {
                                                          page-break-before: always;
                                                        }
                                                      }
                                                `}
                                />
                                <div style={{ display: "none" }}>
                                  <ComponentToPrint
                                    ref={(el) => (printComponentRef = el)}
                                  />
                                </div>
                              </Box>
                            </Box>
                          ))}

                        {selectedOrderIds.length < 2 &&
                          isOrderDialogueOpen.open && (
                            <Dialog
                              fullScreen
                              sx={{
                                width: "50%",
                                left: "auto",
                              }}
                              open={isOrderDialogueOpen.open}
                              onClose={() =>
                                setIsOrderDialogueOpen({
                                  orderId: null,
                                  open: !isOrderDialogueOpen.open,
                                  tabId: null,
                                })
                              }
                              TransitionComponent={Transition}
                            >
                              <OrderDetails
                                tab={isOrderDialogueOpen.tabId}
                                orderId={isOrderDialogueOpen.orderId}
                                orderList={orderManagementPageOrders}
                                onShipmentComplete={() => (
                                  setIsOrderDialogueOpen({
                                    orderId: null,
                                    open: !isOrderDialogueOpen.open,
                                    tabId: null,
                                  }),
                                  handlePageLoad()
                                )}
                                handleActions={(
                                  orderIds,
                                  unAssign,
                                  pick,
                                  issue,
                                  ship
                                ) => {
                                  handleActions(
                                    orderIds,
                                    unAssign,
                                    pick,
                                    issue,
                                    ship
                                  );

                                  setIsOrderDialogueOpen({
                                    orderId: null,
                                    open: !isOrderDialogueOpen.open,
                                    tabId: null,
                                  });
                                }}
                                shippingStatus={shippingFilter}
                              />
                            </Dialog>
                          )}

                        <Table>
                          <TableFooter
                            style={{
                              width: "100%",
                              background: "#E3F2FD",
                              display: "absolute",
                              bottom: 0,
                            }}
                          >
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[20, 50, 100, 250, 500]}
                                colSpan={3}
                                count={totalOrdersCount}
                                rowsPerPage={rowsPerPage}
                                page={
                                  window.localStorage.getItem(
                                    "orderManagementPageNumber"
                                  ) - 1
                                }
                                SelectProps={{
                                  inputProps: {
                                    "aria-label": "rows per page",
                                  },
                                  native: true,
                                }}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={(e) => handlePageSize(e)}
                                ActionsComponent={TablePaginationActions}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </Box>
                    </Box>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </div>
  );
}

const ProductItemLine = (itemLine, key) => {
  const [isVerified, setIsVerified] = useState(false);

  const [barcodeValue, setBarcodeValue] = useState();

  const itemData = itemLine.itemLine;

  const handleBarcodeValidation = () => {
    barcodeValue === itemData.barcode
      ? setIsVerified(true)
      : setIsVerified(false);
  };

  return (
    <Stack direction="row" spacing={2} key={key}>
      <Box
        sx={{
          cursor: "pointer",
        }}
        onClick={() => window.open(itemData.imageURL)}
      >
        <img src={itemData.imageURL} width="70px" />
      </Box>

      <Stack>
        <Box
          style={{
            fontSize: "16px",
          }}
        >
          {itemData.title}
        </Box>

        <Box
          style={{
            fontSize: "14px",
            color: "grey",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Stack direction="row" spacing={1}>
              <Box>QTY:</Box>
              <Box
                style={{
                  fontSize: "18px",
                  color: "#595c97",
                  fontWeight: "bolder",
                }}
              >
                {itemData.quantity}
              </Box>
            </Stack>

            <Box
              style={{
                fontSize: "14px",
                color: "#595c97",
                // fontWeight: "bold",
              }}
            >
              {itemData.varyingAttributes}
            </Box>
            <Box>
              <a href={itemData.productURL} target="_blank" rel="noreferrer">
                {itemData.sku}
              </a>
            </Box>

            <Stack direction="row" spacing={1}>
              <Box>Tot. Price:</Box>
              <Box
                style={{
                  fontSize: "16px",
                  color: "#595c97",
                  fontWeight: "bolder",
                }}
              >
                {Math.round(itemData.quantity * itemData.unitPrice * 100) / 100}
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Stack direction="row" spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box>Est. Shipping Cost:</Box>
            <Box
              style={{
                fontSize: "16px",
                color: "#595c97",
                fontWeight: "bolder",
              }}
            >
              {itemData.unitEstimatedShippingCost}
            </Box>
          </Stack>

          {itemData.barcode ? (
            itemData.barcode === localStorage.getItem("searchString") ||
            isVerified ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <Box>{barcodeValue}</Box>

                <VerifiedIcon
                  sx={{
                    color: "green",
                  }}
                />
              </Stack>
            ) : (
              <Stack direction="row" spacing={1} alignItems="center">
                <input
                  type="number"
                  placeholder="Verify barcode"
                  onChange={(e) => {
                    setBarcodeValue(e.target.value);
                  }}
                  style={{
                    width: "200px",
                  }}
                />

                <IconButton
                  id="verify-barcode"
                  onClick={() => handleBarcodeValidation()}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "green",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </Stack>
            )
          ) : (
            <Box>Barcode N/A</Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
