import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";

import {
  Alert,
  Backdrop,
  ButtonGroup,
  CircularProgress,
  Checkbox,
  Button,
  Box,
  Stack,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Table,
  TableRow,
  TableFooter,
  TablePagination,
  IconButton,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import DeleteIcon from "@mui/icons-material/Delete";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useTheme } from "@mui/material/styles";

import { SHIPPING_API } from "api-config";
import { extendToken } from "refresh-token/refreshToken";

const useStyles = makeStyles(styles);

export const Consignments = () => {
  const classes = useStyles();
  const consignmentListTypeRef = React.useRef(null);

  const [backdrop, setBackdrop] = useState(false);
  const [errorLoadingOrders, setErrorLoadingOrders] = useState(false);
  const [selectedCourier, setSelectedCourier] = useState();

  const [consignmentType, setConsignmentType] = useState(0);
  const [selectingConsignmentList, setSelectingConsignmentList] =
    useState(false);

  const [totalConsignmentsCount, setTotalConsignmentsCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [consignmentList, setConsignmentList] = useState();

  const [selectedConsignmentIds, setSelectedConsignmentIds] = useState([]);
  const [areAllRowsSelected, setAreAllRowsSelected] = useState(false);

  const consignmentOptions = ["Open Consignments", "Closed Manifests"];

  const loadConsignments = async (courier) => {
    setBackdrop(true);
    setSelectedConsignmentIds([]);
    await extendToken();

    return fetch(`${SHIPPING_API}/api/v2/openConsignments`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        courierName: courier,
        pageNumber: window.localStorage.getItem("consignmentsPageNumber"),
        pageSize: rowsPerPage,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setTotalConsignmentsCount(res.totalConsignments);
        setConsignmentList(res.shipmentDetails);
        setBackdrop(false);
      })
      .catch((err) => {
        console.log("error fetching consignments", err);
        setBackdrop(false);
        setErrorLoadingOrders(true);
      });
  };

  const submitConsignments = async (submitAll) => {
    setBackdrop(true);
    setSelectedConsignmentIds([]);

    const shipmentDetails = selectedConsignmentIds.map((id) => {
      return { shipmentId: id };
    });
    await extendToken();

    return fetch(`${SHIPPING_API}/api/v2/createManifest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/*",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`
      },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        courierName: selectedCourier,
        submitAll: submitAll || false,
        shipmentDetails: shipmentDetails,
      }),
    })
      .then((response) => response.arrayBuffer())
      .then((data) => {
        var base64Str = Buffer.from(data).toString("base64");

        var binaryString = window.atob(base64Str);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        var arrBuffer = bytes;

        var newBlob = new Blob([arrBuffer], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        data = window.URL.createObjectURL(newBlob);

        var link = document.createElement("a");
        document.body.appendChild(link);
        link.href = data;
        link.download = "Faktura.pdf";
        link.click();
        window.URL.revokeObjectURL(data);
        link.remove();
        loadConsignments();
        setBackdrop(false);
      })
      .catch((err) => {
        console.log("error submitting consignments", err);
        setBackdrop(false);
        setErrorLoadingOrders(true);
      });
  };

  const handleShipmentStatusRollback = async (ids, shipmentDetails) => {
    await extendToken();
    return fetch(`${SHIPPING_API}/api/v2/deleteShipment`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        orderIds: ids,
        shipmentDetails: [shipmentDetails],
      }),
    })
      .then((response) => response.json())
      .then(() => {
        loadConsignments(selectedCourier);
      })
      .catch((err) => {
        console.log("error deleting consignment", err);
      });
  };

  useEffect(() => {
    window.localStorage.setItem("consignmentsPageNumber", 1);
  }, [rowsPerPage]);

  const handleClose = (event) => {
    if (
      consignmentListTypeRef.current &&
      consignmentListTypeRef.current.contains(event.target)
    ) {
      return;
    }

    setSelectingConsignmentList(false);
  };

  const handleConsignmentSelection = (selectedConsignment) => {
    if (!selectedConsignmentIds.includes(selectedConsignment)) {
      setSelectedConsignmentIds([
        ...selectedConsignmentIds,
        selectedConsignment,
      ]);
    } else if (selectedConsignmentIds.includes(selectedConsignment)) {
      let newIdList = selectedConsignmentIds.filter(
        (id) => id !== selectedConsignment
      );
      setSelectedConsignmentIds(newIdList);
    }
  };

  const handleAllRowsSelection = () => {
    let idsInPage = consignmentList.map((o) => o.shipmentId);

    if (selectedConsignmentIds.length > 0) {
      setSelectedConsignmentIds([]);
      setAreAllRowsSelected(false);
    } else if (selectedConsignmentIds.length === 0) {
      setSelectedConsignmentIds(idsInPage);
      setAreAllRowsSelected(true);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setConsignmentType(index);
    setSelectingConsignmentList(false);
  };

  const handlePageChange = async (event, newPage) => {
    window.localStorage.setItem("consignmentsPageNumber", newPage + 1);
    await loadConsignments(selectedCourier);
  };

  const handlePageSize = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));

    await loadConsignments(selectedCourier);
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
        onClick={undefined}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {errorLoadingOrders && (
        <Alert onClose={() => setErrorLoadingOrders(false)} severity="error">
          Error loading consignments. Please try again later.
        </Alert>
      )}
      {!selectedCourier && (
        <Stack>
          <GridContainer>
            <div
              style={{
                margin: "0 auto",
                width: "500px",
              }}
            >
              <GridItem xs={12}>
                <Card
                  onClick={() => (
                    setSelectedCourier("EPARCEL"), loadConsignments("EPARCEL")
                  )}
                >
                  <CardBody>
                    <h4
                      className={classes.cardTitle}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      eParcel
                    </h4>
                  </CardBody>
                  <div margin={0} padding={0}>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        Click here to manage eParcel consignments
                      </div>
                    </CardFooter>
                  </div>
                </Card>
              </GridItem>

              <GridItem xs={12}>
                <Card
                  onClick={() => (
                    setSelectedCourier("FASTWAY"), loadConsignments("FASTWAY")
                  )}
                >
                  <CardBody>
                    <h4
                      className={classes.cardTitle}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      Fastway
                    </h4>
                  </CardBody>
                  <div margin={0} padding={0}>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        Click here to manage Fastway consignments
                      </div>
                    </CardFooter>
                  </div>
                </Card>
              </GridItem>

              <GridItem xs={12}>
                <Card
                  onClick={() => (
                    setSelectedCourier("MYFASTWAY"),
                    loadConsignments("MYFASTWAY")
                  )}
                >
                  <CardBody>
                    <h4
                      className={classes.cardTitle}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      myFastway
                    </h4>
                  </CardBody>
                  <div margin={0} padding={0}>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        Click here to manage myFastway consignments
                      </div>
                    </CardFooter>
                  </div>
                </Card>
              </GridItem>
            </div>
          </GridContainer>
        </Stack>
      )}

      {selectedCourier && (
        <GridContainer style={{ width: "100%", padding: "15px" }}>
          <GridItem xs={12}>
            <Card style={{ marging: 0 }}>
              <CardHeader>
                <ButtonGroup
                  variant="contained"
                  ref={consignmentListTypeRef}
                  aria-label="split button"
                >
                  <Button>{consignmentOptions[consignmentType]}</Button>
                  <Button
                    size="small"
                    aria-controls={
                      selectingConsignmentList ? "split-button-menu" : undefined
                    }
                    aria-expanded={
                      selectingConsignmentList ? "true" : undefined
                    }
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() =>
                      setSelectingConsignmentList(!selectingConsignmentList)
                    }
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={selectingConsignmentList}
                  anchorEl={consignmentListTypeRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu">
                            {consignmentOptions.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === consignmentType}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </CardHeader>
              <CardBody>
                {consignmentType === 0 && consignmentList && (
                  <Stack>
                    <Stack direction="row" spacing={3} alignItems="center">
                      <Checkbox
                        style={{
                          color: "#90CAF9",
                        }}
                        checked={areAllRowsSelected}
                        onChange={() => handleAllRowsSelection()}
                      />

                      <Button
                        variant="outlined"
                        disabled={
                          areAllRowsSelected ||
                          selectedConsignmentIds.length > 0
                        }
                        onClick={() => submitConsignments(true)}
                      >
                        Submit all consignments
                      </Button>

                      <Button
                        variant="outlined"
                        disabled={
                          !(
                            areAllRowsSelected ||
                            selectedConsignmentIds.length > 0
                          )
                        }
                        onClick={() => submitConsignments()}
                      >
                        Submit selected consignments
                      </Button>
                    </Stack>
                    {consignmentList.map((c, index) => (
                      <Box
                        key={index}
                        style={{
                          backgroundColor: "#f9fafc",
                          padding: "15px",
                          margin: "15px",
                          boxShadow:
                            "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                          borderRadius: "16px",
                          minWidth: 0,
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Checkbox
                              style={{
                                color: "#90CAF9",
                              }}
                              indeterminate={false}
                              checked={selectedConsignmentIds.includes(
                                c.shipmentId
                              )}
                              onChange={() =>
                                handleConsignmentSelection(c.shipmentId)
                              }
                            />

                            <Stack
                              direction="row"
                              spacing={8}
                              alignItems="center"
                            >
                              <Stack spacing={1}>
                                <Stack spacing={1}>
                                  <Box
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`Name`}
                                  </Box>
                                  <Box>{`${c.name || "N/A"}`}</Box>
                                </Stack>
                                <Stack spacing={1}>
                                  <Box
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`Order Id's`}
                                  </Box>
                                  <Box>{`${c.orderIds || "N/A"}`}</Box>
                                </Stack>
                              </Stack>
                              <Stack spacing={1}>
                                <Stack spacing={1}>
                                  <Box
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`Shipping Id`}
                                  </Box>
                                  <Box>{`${c.shipmentId}`}</Box>
                                </Stack>
                                <Stack spacing={1}>
                                  <Box
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`Tracking Number`}
                                  </Box>
                                  <Box>
                                    {c.trackingNumber ? (
                                      c.trackingNumber
                                    ) : (
                                      c.shippedItems && c.shippedItems.length > 0 ? 
                                        c.shippedItems.map(item => item.itemTrackingId).join(", ") :
                                        "No Tracking Available"
                                    )}
                                  </Box>
                                </Stack>
                              </Stack>

                              <Box>{c.parcelName}</Box>

                              <Box>{`$ ${c.ShippingCost}`}</Box>
                              <Box>
                                {new Date(
                                  c.shippingStatusDatetime
                                ).toLocaleString()}
                              </Box>
                            </Stack>
                          </Stack>

                          <Box>
                            <IconButton
                              id="delete-consignment"
                              onClick={() => {
                                handleShipmentStatusRollback(c.orderIds, c);
                              }}
                            >
                              <DeleteIcon
                                color="error"
                                sx={{
                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          </Box>
                        </Stack>
                      </Box>
                    ))}
                  </Stack>
                )}

                {consignmentType === 1 && <ClosedConsignments />}
              </CardBody>

              <Table>
                <TableFooter
                  style={{
                    width: "100%",
                    background: "#E3F2FD",
                    display: "absolute",
                    bottom: 0,
                  }}
                >
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100, 250, 500]}
                      colSpan={3}
                      count={totalConsignmentsCount}
                      rowsPerPage={rowsPerPage}
                      page={
                        window.localStorage.getItem("consignmentsPageNumber") -
                        1
                      }
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={(e) => handlePageSize(e)}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};

const ClosedConsignments = () => {
  return (
    <Stack>
      <Box>Closed consignments</Box>
    </Stack>
  );
};
