export const setUserContext = (userData: any) => {
  return {
    type: "setUserContext",
    payload: {
      userData: userData,
    },
  };
};

export const deactivateUSerSession = (userData: any) => {
  return {
    type: "deactivateUSerSession",
    payload: {
      userData: userData,
    },
  };
};
