import { setEmployees, employeeDetails } from "./actions";
import { ADMIN_API, AUTH_API_URL } from "api-config";

export function employeeReducer(state: any = "", action: any) {
  switch (action.type) {
    case "setEmployees":
      return { ...state, userData: action.payload.userData };
    default:
      return state;
  }
}

export function employeeProfileReducer(state: any = "", action: any) {
  switch (action.type) {
    case "getEmployeeDetails":
      return { ...state, userData: action.payload.userData.userDetails };
    case "setEmployeeDetails":
      return { ...state, userData: action.payload.userData.userDetails };
    default:
      return state;
  }
}

export const loadEmployees = () => async (dispatch: any) => {
  const res = await fetch(`${AUTH_API_URL}/api/getAllEmployees`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`
    }
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error loading user details", err);
      return err.message;
    });

  dispatch(setEmployees(res.users));
};

export const loadEmployeeDetails =
  (employeeId: string) => async (dispatch: any) => {
    let res = await fetch(`${ADMIN_API}/api/user/getUserProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`
      },
      body: JSON.stringify({
        employeeId: employeeId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log("Error loading user details", err);
        return err.message;
      });

    if (res) {
      localStorage.setItem("authenticatedEmployee", res.authenticatedEmployee);
    }

    dispatch(employeeDetails(res));
  };

export const updateEmployeeDetails =
  (
    email?: string,
    phoneNumber?: string,
    firstName?: string,
    lastName?: string
  ) =>
  async () => {
    let res = await fetch(`${ADMIN_API}/api/user/updateUserProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`
      },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        email: email || "",
        phoneNumber: phoneNumber || "",
        firstName: firstName || "",
        lastName: lastName || "",
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log("Error loading user details", err);
        return err.message;
      });

    if (res.success) {
      localStorage.setItem("authenticatedEmployee", res.authenticatedEmployee);
      loadEmployeeDetails(localStorage.getItem("employeeId") || "");
    }
  };
