import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Poppers from "@material-ui/core/Popper";

import {
  MenuItem,
  MenuList,
  Grow,
  Paper,
  ClickAwayListener,
  Hidden,
  // Poppers,
  Divider,
  Snackbar,
} from "@material-ui/core";

import { Backdrop, CircularProgress } from "@mui/material";

import Alert from "@material-ui/lab/Alert";

// @material-ui/icons
import Person from "@material-ui/icons/Person";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
// import UserContext from "../../context/user-context";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/user-context/reducer";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  let history = useHistory();

  const classes = useStyles();
  const [openProfile, setOpenProfile] = useState(null);
  const [backdrop, setBackdrop] = useState(false);

  const dispatch = useDispatch();

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const userSessionId = window.localStorage.getItem("authenticatedEmployee");

  const handleLogout = async () => {
    setBackdrop(true);
    await dispatch(logoutUser(localStorage.getItem("employeeId") || ""));

    localStorage.removeItem("employeeId");
    const token = window.localStorage.getItem("authenticatedEmployee");
    setBackdrop(false);
    if (!token) {
      history.push("/");
    }
  };

  const handleProfileSelect = (e) => {
    e.preventDefault();
    history.push("/authenticated/userProfile");
  };

  // Error handling for null user session
  // TODO: Should handle this more gracefully.
  // Don't really want to show data any data when user session is not loaded correctly
  function UserSessionError() {
    history.push("/");

    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => undefined}
      >
        <Alert onClose={() => history.push("/")} severity={"error"}>
          {"User Session not loaded correct"}
        </Alert>
      </Snackbar>
    );
  }

  if (!userSessionId) return <UserSessionError />;

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100 }}
        open={backdrop}
        onClick={undefined}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() =>
                    openProfile ? setOpenProfile(null) : undefined
                  }
                >
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleProfileSelect}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
