export default function adminManagementReducer(state: any = [], action: any) {
  switch (action.type) {
    case "getUserDetails":
      return action.payload.users;
    case "loadUserDetails":
      return action.payload.userData;
    default:
      return state;
  }
}
