import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { extendToken } from "refresh-token/refreshToken.js";
import { ORDERS_API, REWARDS_API } from "../../api-config.js";
import zIndex from "@material-ui/core/styles/zIndex.js";

export const ProductOrdersPickSheet = () => {
  let history = useHistory();

  const [backdrop, setBackdrop] = useState(false);
  const [productPickedPages, setProductPickedPages] = useState(
    localStorage.getItem("productOrderPickedPages") || []
  );

  const [pickSheetData, setPickSheetData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(
    Number(window.localStorage.getItem("ProductOrderPage"))
  );

  useEffect(async () => {
    setBackdrop(true);

    localStorage.setItem("productOrderPickedPages", productPickedPages);

    const res = await handleProductOrders().then((res) => res);
    setPickSheetData(res);
    setBackdrop(false);
  }, []);

  const handleProductOrders = async () => {
    setBackdrop(true);
    await extendToken();

    return fetch(`${ORDERS_API}/api/v2/productOrders`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        pageNumber: window.localStorage.getItem("ProductOrderPage") !== null && window.localStorage.getItem("ProductOrderPage") !== "undefined" ? window.localStorage.getItem("ProductOrderPage") : 1,
        pageSize: 1,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setPickSheetData(res.Orders);
        setBackdrop(false);

        return res.Orders;
      })
      .catch((err) => {
        console.log("error fetching productOrders", err);
      });
  };

  const handlePageSelection = (event) => {
    setSelectedPage(event.target.value);
    window.localStorage.setItem("ProductOrderPage", event.target.value);
    handlePageLoad("PAGINATION", event.target.value);
  };

  const handlePageLoad = async (type, page) => {
    type === "PREVIOUS"
      ? (window.localStorage.setItem(
          "ProductOrderPage",
          Number(selectedPage) - 1
        ),
        setSelectedPage(selectedPage - 1))
      : type === "NEXT"
      ? (window.localStorage.setItem("ProductOrderPage", selectedPage + 1),
        setSelectedPage(selectedPage + 1))
      : (window.localStorage.setItem("ProductOrderPage", page),
        setSelectedPage(page));

    await handleProductOrders();
  };

  const handlePickAll = () => {
    setProductPickedPages(() =>
      Array.from(productPickedPages).filter((e) => e !== selectedPage)
    );

    let pickedPages = localStorage.getItem("productOrderPickedPages");

    pickedPages = pickedPages.split(",").filter((e) => e);

    pickedPages = [...pickedPages, selectedPage];
    pickedPages = pickedPages.map(Number);
    pickedPages = Array.from(new Set(pickedPages));

    localStorage.setItem("productOrderPickedPages", pickedPages);

    const noIssueIds = pickSheetData.filter((i) => i.issueStatus != "Issue");

    const pickIds = noIssueIds.map((pd) => {
      return pd.orderDetails.map((od) => od.OrderID);
    });

    const flatIdArray = Array.prototype.concat.apply([], pickIds);

    const pickedOrderIds = flatIdArray.map((f) => {
      return { orderID: f, pickingstatus: true };
    });
    handlePickAllOrders(pickedOrderIds || [], flatIdArray);
  };

  const handlePickAllOrders = async (pickedOrderIds, pickedOrders) => {
    setBackdrop(true);
    await extendToken();
    return fetch(`${ORDERS_API}/api/v2/pickAll`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        orderDetails: pickedOrderIds,
      }),
    })
      .then(async (response) => {
        // Ingest picking rewards
        await ingestPickingRewards(pickedOrders);
        return response.json();
      })
      .then(() => {
        handlePageLoad(
          "PICKEDALL",
          selectedPage ==
            Math.ceil(window.localStorage.getItem("ProductOrdersCount") / 5)
            ? selectedPage
            : selectedPage + 1
        );
      })
      .catch((err) => {
        console.log("error marking orders as PICKED", err);
      });
  };

  const ingestPickingRewards = async (pickedOrders) => {
    await extendToken();
    return fetch(`${REWARDS_API}/api/v2/ingestRewards`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        transactionType: "PICKED",
        orderIds: pickedOrders,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log("error ingesting rewards for picked orders", err);
      });
  };

  const setOrderIssue = async (orderDetails) => {
    await extendToken();
    return fetch(`${ORDERS_API}/api/v2/issueOrders`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        employeeId: localStorage.getItem("employeeId") || "",
        issueDetails: [orderDetails],
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then(() => {
        handlePageLoad("ORDER_ISSUE", selectedPage);
      })
      .catch((err) => {
        console.log("error marking issues for orders", err);
      });
  };

  const isPickAllDisabled = pickSheetData
    .map((i) => {
      return i.pickingStatus === "Picked" || i.issueStatus === "Issue";
    })
    .every((i) => i === true); // Disable pick all button if all orderes are either picked/issues

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
        onClick={undefined}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box paddingX="10px">
        {/* Header */}
        {/* //TODO: Factor this out */}
        <Box
        style={{
          textAlign: "center",
          display: "inline-flex",
          flexDirection: "row",
          width: "100%",
          padding: 1,
          position: "fixed",
          top: 0,
          backgroundColor: "white", // Optional: to ensure it doesn't blend with the background
          zIndex: 2000, // Optional: to ensure it stays above other content
          }}
        >
          <Box
            style={{
              float: "left",
              width: "content",
            }}
          >
            <Button
              variant="contained"
              onClick={() => history.push("/authenticated/newPickSheet")}
            >
              Back
            </Button>
          </Box>
          <Box
            style={{
              right: 0,
              left: 0,
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <p>Product Orders</p>
          </Box>
          <Box
  style={{
    float: "right",
    width: "100px",
  }}
>
  <FormControl
    style={{
      width: "100px",
      height: "36px",
    }}
  >
    <InputLabel
      id="page-id"
      style={{
        marginTop: "-5px",
      }}
    >
      Page
    </InputLabel>
    <Select
      labelId="page-id"
      id="page-selector-id"
      value={selectedPage}
      label="Page"
      onChange={handlePageSelection}
      style={{
        width: "100px",
        height: "36px",
      }}
      MenuProps={{
        getContentAnchorEl: null, // Ensures the dropdown aligns properly
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          style: {
            zIndex: 4000, // Ensure the dropdown is above other elements
            maxHeight: 200, // Optional: Adjust as needed for scrollable dropdown
            overflowY: 'auto',
          },
        },
      }}
    >
      {Array.from(
        Array(
          Math.ceil(
            window.localStorage.getItem("ProductOrdersCount") / 1
          )
        ).keys()
      ).map((key) => (
        <MenuItem value={key + 1} key={key + 1}>
          {key + 1}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>

        </Box>

        {/* Body */}
        {/* //TODO: Factor this out */}
        <>
          <Box
            style={{ height:"100dvh" }}
            overflow="scroll"
          >
            <TableContainer component={Paper} sx={{height:"100vh"}}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Location
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Photo
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Quantity
                    </TableCell>

                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Order Type
                    </TableCell>

                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Title
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Varying attributes
                    </TableCell>

                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Order Id
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pickSheetData &&
                    pickSheetData.map((pd, key) => (
                      <ProductPickSheetData
                        rowData={pd}
                        key={key}
                        handleOrderIssue={(orderDetails) =>
                          setOrderIssue(orderDetails)
                        }
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box style={{ height: "70dvh" }}></Box>
          </Box>
        </>

        {/* Footer */}
        {/* //TODO: Factor this out */}
        <Box
          style={{
            display: "inline-flex",
            flexDirection: "row",
            width: "100%",
           padding:1,
            zIndex:2000,
            position: "fixed",
            bottom: 10,
            backgroundColor: "white",
          }}
        >
          <Box
            style={{
              float: "left",
              width: "content",
            }}
          >
            <Button
              variant="contained"
              disabled={window.localStorage.getItem("ProductOrderPage") == 1}
              onClick={() => handlePageLoad("PREVIOUS")}
            >
              Previous
            </Button>
          </Box>
        <Box
  style={{
    right: 0,
    left: 0,
    marginRight: "auto",
    marginLeft: "auto",
  }}
>
  <Button
    disabled={isPickAllDisabled}
    variant="contained"
    onClick={() => handlePickAll()}
    color="primary"
  >
    Pick All
  </Button>
</Box>

 
<Box
  style={{
    float: "right",
    width: "content",
  }}
>
  <Button
    variant="contained"
    disabled={
      selectedPage ==
      Math.ceil(window.localStorage.getItem("ProductOrdersCount") / 5)
    }
    onClick={() => handlePageLoad("NEXT")}
  >
    Next
  </Button>
</Box>

        </Box>
      </Box>
    </>
  );
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const ProductPickSheetData = (props) => {
  const { rowData } = props;

  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);

  if (!props.rowData) {
    return <p>Error occured loading data. please try again later.</p>;
  }

  return (
    <>
      <TableRow className={classes.root} onClick={() => setOpen(!open)}>
        <TableCell
          align="center"
          style={{
            color:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "rgb(161 153 153 / 87%)",
            textDecoration:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "line-through",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>
            {rowData.warehouseLocation}
          </p>
          {/* <br /> */}
          {rowData.Sku}
        </TableCell>
        <TableCell
          align="center"
          style={{
            color:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "rgb(161 153 153 / 87%)",
            textDecoration:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "line-through",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => window.open(rowData.imageURL)}
          >
            <img src={rowData.imageURL} width="50px" />
          </Box>
        </TableCell>
        <TableCell
          align="center"
          style={{
            color:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "rgb(161 153 153 / 87%)",
            textDecoration:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "line-through",
          }}
        >
          {rowData.Quantity}
        </TableCell>
        <TableCell
          align="center"
          style={{
            color:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "rgb(161 153 153 / 87%)",
            textDecoration:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "line-through",
          }}
        >
          {rowData.orderType}
        </TableCell>
        <TableCell
          align="center"
          style={{
            color:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "rgb(161 153 153 / 87%)",
            textDecoration:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "line-through",
          }}
        >
          {rowData.Title}
        </TableCell>
        <TableCell
          align="center"
          style={{
            color:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "rgb(161 153 153 / 87%)",
            textDecoration:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "line-through",
          }}
        >
          {rowData.varyingAttributes}
        </TableCell>
        <TableCell
          align="center"
          style={{
            color:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "rgb(161 153 153 / 87%)",
            textDecoration:
              (rowData.pickingStatus === "Picked" ||
                rowData.issueStatus === "Issue") &&
              "line-through",

            background:
              rowData.RequestedShippingClass === "Express"
                ? "yellow"
                : rowData.shippingCountry !== "AU"
                ? "#f3ceff"
                : rowData.requestedShippingClass === "Click n Collect"
                ? "#00FF00" //Mark Green for Click and Collect Orders
                : rowData.requestedShippingClass === "Click & Collect"
                ? "#00FF00" //Mark Green for Click and Collect Orders
                : undefined,
          }}
        >
          {rowData.split}
        </TableCell>
      </TableRow>

      {/* Order Details Section */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Order Details
              </Typography>
              {/* TODO: Bundle Product Details here */}
              {rowData.IsBundle ? (
                <Table>
                  <TableHead>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Location
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Photo
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Quantity
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Title
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Varying Attributes
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Quantity left in stock
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Excess stock location
                    </TableCell>
                  </TableHead>

                  {rowData.BundleComponents.map((b, key) => (
                    <TableRow key={key}>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          color:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "rgb(161 153 153 / 87%)",
                          textDecoration:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "line-through",
                        }}
                      >
                        {b.warehouseLocation || ""}
                        <br />
                        {b.Sku}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          color:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "rgb(161 153 153 / 87%)",
                          textDecoration:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "line-through",
                        }}
                      >
                        <Box
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(b.imageURL)}
                        >
                          <img src={b.imageURL} width="50px" />
                        </Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          color:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "rgb(161 153 153 / 87%)",
                          textDecoration:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "line-through",
                        }}
                      >
                        {b.Quantity || ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          color:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "rgb(161 153 153 / 87%)",
                          textDecoration:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "line-through",
                        }}
                      >
                        {b.Title || ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          color:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "rgb(161 153 153 / 87%)",
                          textDecoration:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "line-through",
                        }}
                      >
                        {b.varyingAttributes || ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          color:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "rgb(161 153 153 / 87%)",
                          textDecoration:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "line-through",
                        }}
                      >
                        {b.availableQty || ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          color:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "rgb(161 153 153 / 87%)",
                          textDecoration:
                            (rowData.pickingStatus === "Picked" ||
                              rowData.issueStatus === "Issue") &&
                            "line-through",
                        }}
                      >
                        {b.excessStock || "N/A"}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              ) : (
                <>
                  <p>Quantity left in stock: {rowData.availableQty}</p>
                  <p>Excess stock location: {rowData.excessStock || "N/A"}</p>
                </>
              )}

              <ProductIssuesSection
                rowData={rowData}
                handleOrderIssue={(orderDetails) =>
                  props.handleOrderIssue(orderDetails)
                }
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ProductIssuesSection = (props) => {
  const rowData = props.rowData ? props.rowData : [];
  // let issueDetails = [];
  // const [issue, setIssue] = useState(false);
  // const [isAddingPickerNotes, setIsAddingPickerNotes] = useState("ADD");
  // const [pickerNote, setPickerNote] = useState("");

  // const handleIssueCheck = (id, PrivateNotes) => {
  //   setIssue(!issue);

  //   const issueOrder = {
  //     orderID: id,
  //     issuestatus: true,
  //     pickerNote: PrivateNotes,
  //   };
  //   issueDetails.push(issueOrder);
  //   props.handleOrderIssue(issueOrder);
  // };

  if (!rowData) {
    return <p>Issue loading this section. Please try again later.</p>;
  }

  return (
    <>
      {rowData && rowData.orderDetails && (
        <>
          <TableRow>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Order Id
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Customer Name
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Picker Notes
            </TableCell>
            <TableCell align="center">
              {/* <Button variant="contained" color="secondary">
                Issue(s)
              </Button> */}
            </TableCell>
          </TableRow>
          {rowData.orderDetails.map((od, key) => (
            <OrderDetails
              key={key}
              orderDetails={od}
              pickingStatus={rowData.pickingStatus}
              issueOrderIds={rowData.issueOrderIds}
              handleOrderIssue={(orderDetails) =>
                props.handleOrderIssue(orderDetails)
              }
            />
          ))}
        </>
      )}
    </>
  );
};

const OrderDetails = (props) => {
  const orderDetails = props.orderDetails;

  let issueDetails = [];
  const [issue, setIssue] = useState(false);
  const [isAddingPickerNotes, setIsAddingPickerNotes] = useState("ADD");
  const [pickerNote, setPickerNote] = useState("");

  const handleIssueCheck = (id, PrivateNotes) => {
    setIssue(!issue);

    const issueOrder = {
      orderID: id,
      issuestatus: true,
      pickerNote: PrivateNotes,
    };
    issueDetails.push(issueOrder);
    props.handleOrderIssue(issueOrder);
  };

  return (
    <TableRow
      style={{
        background:
          orderDetails.RequestedShippingClass === "Express"
            ? "yellow"
            : orderDetails.shippingCountry !== "AU"
            ? "#f3ceff"
            : orderDetails.requestedShippingClass === "Click n Collect"
            ? "#00FF00" //Mark Green for Click and Collect Orders
            : orderDetails.requestedShippingClass === "Click & Collect"
            ? "#00FF00" //Mark Green for Click and Collect Orders
            : undefined,
      }}
    >
      <TableCell
        align="center"
        style={{
          color:
            (props.pickingStatus === "Picked" ||
              (props.issueOrderIds &&
                props.issueOrderIds.includes(orderDetails.OrderID))) &&
            "rgb(161 153 153 / 87%)",
          textDecoration:
            (props.pickingStatus === "Picked" ||
              (props.issueOrderIds &&
                props.issueOrderIds.includes(orderDetails.OrderID))) &&
            "line-through",
        }}
      >
        {orderDetails.OrderID || ""}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color:
            (props.pickingStatus === "Picked" ||
              (props.issueOrderIds &&
                props.issueOrderIds.includes(orderDetails.OrderID))) &&
            "rgb(161 153 153 / 87%)",
          textDecoration:
            (props.pickingStatus === "Picked" ||
              (props.issueOrderIds &&
                props.issueOrderIds.includes(orderDetails.OrderID))) &&
            "line-through",
        }}
      >
        {orderDetails.customerName || ""}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color:
            (props.pickingStatus === "Picked" ||
              (props.issueOrderIds &&
                props.issueOrderIds.includes(orderDetails.OrderID))) &&
            "rgb(161 153 153 / 87%)",
          textDecoration:
            (props.pickingStatus === "Picked" ||
              (props.issueOrderIds &&
                props.issueOrderIds.includes(orderDetails.OrderID))) &&
            "line-through",
        }}
      >
        {!orderDetails.pickerNote && isAddingPickerNotes === "ADD" ? (
          <textarea
            name="body"
            onChange={(e) => setPickerNote(e.target.value)}
            defaultValue={pickerNote}
          />
        ) : (
          orderDetails.pickerNote || pickerNote
        )}
      </TableCell>
      <TableCell align="center">
        <Stack direction="column" spacing={1}>
          <Button
            variant="contained"
            onClick={() =>
              setIsAddingPickerNotes(
                isAddingPickerNotes === "ADD" ? "UPDATE" : "ADD"
              )
            }
            style={{
              height: "20px",
              width: "120px",
            }}
          >
            {isAddingPickerNotes === "ADD" && !orderDetails.pickerNote
              ? "Add Notes"
              : "Edit"}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleIssueCheck(orderDetails.OrderID, pickerNote)}
            style={{
              height: "20px",
              width: "120px",
            }}
            disabled={!pickerNote || isAddingPickerNotes === "ADD"}
          >
            Submit
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
