import { setOrdersCount } from "./actions";
import { ORDERS_API } from "../../api-config";
import { extendToken } from "refresh-token/refreshToken";

export function productPicksheetReducer(
  state: any = [{ orders: [] }],
  action: any
) {
  switch (action.type) {
    case "storeProductOrders":
      state.orders = action.payload.orders || [];
      return state.orders;

    case "markProductOrdersAsPicked":
      state.orders = action.payload.orders;
      return state.orders;

    default:
      return state;
  }
}

export function multiPicksheetReducer(
  state: any = [{ orders: [] }],
  action: any
) {
  switch (action.type) {
    case "storeMultiOrders":
      state.orders = action.payload.orders || [];
      return state.orders;

    case "markMultiOrdersAsPicked":
      state.orders = action.payload.orders;
      return state.orders;

    default:
      return state;
  }
}

export function duplicatePicksheetReducer(
  state: any = [{ orders: [] }],
  action: any
) {
  switch (action.type) {
    case "storeDuplicateOrders":
      state.orders = action.payload.orders || [];
      return state.orders;

    case "markDuplicateOrdersAsPicked":
      state.orders = action.payload.orders;
      return state.orders;

    default:
      return state;
  }
}

export function ordersCountReducer(
  state: {
    productOrder: number;
    multiOrder: number;
    duplicateOrder: number;
  } = { productOrder: 0, multiOrder: 0, duplicateOrder: 0 },
  action: any
) {
  switch (action.type) {
    case "setOrdersCount":
      state.productOrder = action.payload.productOrder;
      state.multiOrder = action.payload.multiOrder;
      state.duplicateOrder = action.payload.duplicateOrder;

      return state;

    default:
      return state;
  }
}

export const ordersCount = (requestedOrders?: any) => async (dispatch: any) => {
  await extendToken();
  const res = fetch(`${ORDERS_API}/api/v2/loadOrders`, {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
    body: JSON.stringify({
      employeeId: localStorage.getItem("employeeId") || "",
      authenticatedEmployee: localStorage.getItem("authenticatedEmployee") || "",
      numberOfOrders: requestedOrders || 20,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log("Error loading order details", err);
      return err.message;
    });

  dispatch(setOrdersCount(res));
};
