/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {
  Alert,
  Button,
  Backdrop,
  Box,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useDispatch, useSelector } from "react-redux";
import {
  loadEmployeeDetails,
  updateEmployeeDetails,
} from "../../store/employees/reducer";
const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.employeeProfile) || {
    userData: {},
  };
  const [backDrop, setBackDrop] = useState(userProfile);

  const [userEmail, setUserEmail] = useState(
    userProfile.userData && userProfile.userData.success
      ? userProfile.userData.email
      : ""
  );

  useEffect(() => {
    dispatch(loadEmployeeDetails(localStorage.getItem("employeeId")));

    setBackDrop(true);
  }, []);

  const handleUserUpdate = () => {
    setBackDrop(false);
    dispatch(
      updateEmployeeDetails(
        "midhilkari403@gmail.com",
        "0402031143",
        "Midhil",
        "kari"
      )
    );
    updateDetails();
  };

  const updateDetails = () => {
    dispatch(loadEmployeeDetails(localStorage.getItem("employeeId")));
    setUserEmail(userProfile.userData.email);
    setBackDrop(true);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!backDrop}
        onClick={undefined}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* {errorLoadingOrders && (
        <Alert onClose={() => setErrorLoadingOrders(false)} severity="error">
          Error loading orders. Please try again later.
        </Alert>
      )} */}
      {userProfile && (
        <GridContainer
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              margin: "0 auto",
              width: "500px",
            }}
          >
            <>
              <GridItem xs={12}>
                <Card>
                  <CardBody>
                    <h4
                      className={classes.cardTitle}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      Employee Details
                    </h4>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Employee Details</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            scope="row"
                            style={{
                              paddingTop: "5px",
                            }}
                          >
                            Employee Id
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              paddingTop: "5px",
                            }}
                          >
                            {userProfile.userData
                              ? userProfile.userData.employeeId
                              : ""}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            scope="row"
                            style={{
                              paddingTop: "5px",
                            }}
                          >
                            First Name
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              paddingTop: "5px",
                            }}
                          >
                            {userProfile.userData
                              ? userProfile.userData.firstName
                              : ""}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            scope="row"
                            style={{
                              paddingTop: "5px",
                            }}
                          >
                            Last Name
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              paddingTop: "5px",
                            }}
                          >
                            {userProfile.userData
                              ? userProfile.userData.lastName
                              : ""}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            scope="row"
                            style={{
                              paddingTop: "5px",
                            }}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              paddingTop: "5px",
                            }}
                          >
                            {userProfile.userData
                              ? userProfile.userData.email
                              : ""}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            scope="row"
                            style={{
                              paddingTop: "5px",
                            }}
                          >
                            Phone Number
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              paddingTop: "5px",
                            }}
                          >
                            {userProfile.userData
                              ? userProfile.userData.phoneNumber
                              : ""}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardBody>
                  <div margin={0} padding={0}>
                    <CardFooter stats>
                      <Box>
                        <Button onClick={() => handleUserUpdate()}>
                          Update
                        </Button>
                      </Box>
                    </CardFooter>
                  </div>
                </Card>
              </GridItem>
            </>
          </div>
        </GridContainer>
      )}
    </>
  );
}
