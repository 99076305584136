import React from "react";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
// import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes1.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

// import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/EG_Trade.jpg";
import Dashboard from "views/Dashboard/Dashboard";
import AdminPortal from "views/AdminPortal/AdminPortal";
import UserProfile from "views/UserProfile/UserProfile";
import { ProductOrdersPickSheet } from "views/ProductOrdersPickSheet/ProductOrdersPickSheet";
import { MultiOrdersPickSheet } from "views/MultiOrdersPickSheet/MultiOrdersPickSheet";
import { DuplicateOrdersPickSheet } from "views/DuplicateOrdersPickSheet/DuplicateOrdersPickSheet";
import { Shipping } from "views/Shipping/Shipping";
import { Consignments } from "views/Consignments/Consignments";

import NewPickSheet from "views/NewPickSheet/NewPickSheet";
import OrderManagement from "views/OrderManagement/OrderManagement";
import { QuickQuote } from "views/QuickQuote/QuickQuote";

let ps;

const useStyles = makeStyles(styles);
export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  // const [image, setImage] = React.useState(bgImage);
  // const [color, setColor] = React.useState("blue");

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={
          window.localStorage.getItem("role") === "ADMIN"
            ? routes
            : window.localStorage.getItem("role") === "processor"
            ? routes.slice(0, 5)
            : routes.slice(0, 2)
        }
        logoText={"EG Trade"}
        logo={logo}
        // image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"blue"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={
            window.localStorage.getItem("role") === "ADMIN"
              ? routes
              : routes.slice(0, 2)
          }
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

        <div
          className={classes.map}
          style={{
            backgroundColor: "#f9fafc",
            height: "100vh",
          }}
        >
          <Switch>
            <Route path="/authenticated/dashboard" component={Dashboard} />
            <Route
              path="/authenticated/newPickSheet"
              component={NewPickSheet}
            />
            <Route path="/authenticated/adminPortal" component={AdminPortal} />
            <Route
              path="/authenticated/orderManagement"
              component={OrderManagement}
            />
            <Route path="/authenticated/userProfile" component={UserProfile} />
            <Route
              path="/authenticated/ProductOrdersPickSheet"
              component={ProductOrdersPickSheet}
            />
            <Route
              path="/authenticated/MultiOrdersPickSheet"
              component={MultiOrdersPickSheet}
            />
            <Route
              path="/authenticated/DuplicateOrdersPickSheet"
              component={DuplicateOrdersPickSheet}
            />
            <Route path="/authenticated/shipping" component={Shipping} />
            <Route
              path={"/authenticated/consignments"}
              component={Consignments}
            />
            <Route path={"/authenticated/quickQuote"} component={QuickQuote} />
          </Switch>
        </div>
        {/* Use footer in later stages */}
        {/* <Footer /> */}
        {/* {getRoute() ? <Footer /> : null} */}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
}
