import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SignInPage from "./components/SignInPage";
import Admin from "./layouts/Admin";

import { Provider } from "react-redux";
import store from "./store/store";

const Routes = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignInPage} />
          <Route path="/authenticated" component={Admin} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

export default Routes;
