export const storeProductOrders = (orders: any) => {
  //TODO: This should be provided by api
  const updateOrderWithStatus =
    orders &&
    orders.map((o: any) => {
      return { ...o, status: "NOT_PICKED" };
    });

  return {
    type: "storeProductOrders",
    payload: {
      orders: updateOrderWithStatus,
    },
  };
};

export const pickProductOrder = (
  orders: any,
  fromOrderId: any,
  tillOrderId: any
) => {
  for (var i = fromOrderId; i < tillOrderId; i++) {
    orders[i].status = "PICKED";
  }

  return {
    type: "markProductOrdersAsPicked",
    payload: {
      orders: orders,
    },
  };
};

export const storeMultiOrders = (orders: any) => {
  //TODO: This should be provided by api
  const updateOrderWithStatus =
    orders &&
    orders.map((o: any) => {
      return { ...o, status: "NOT_PICKED" };
    });

  return {
    type: "storeMultiOrders",
    payload: {
      orders: updateOrderWithStatus,
    },
  };
};

export const pickMultiOrder = (
  orders: any,
  fromOrderId: any,
  tillOrderId: any
) => {
  for (var i = fromOrderId; i < tillOrderId; i++) {
    orders[i].status = "PICKED";
  }

  return {
    type: "markMultiOrdersAsPicked",
    payload: {
      orders: orders,
    },
  };
};

export const storeDuplicateOrders = (orders: any) => {
  //TODO: This should be provided by api
  const updateOrderWithStatus =
    orders &&
    orders.map((o: any) => {
      return { ...o, status: "NOT_PICKED" };
    });

  return {
    type: "storeDuplicateOrders",
    payload: {
      orders: updateOrderWithStatus,
    },
  };
};

export const pickDuplicateOrder = (
  orders: any,
  fromOrderId: any,
  tillOrderId: any
) => {
  for (var i = fromOrderId; i < tillOrderId; i++) {
    orders[i].status = "PICKED";
  }

  return {
    type: "markDuplicateOrdersAsPicked",
    payload: {
      orders: orders,
    },
  };
};

export const setOrdersCount = (ordersData: any) => {
  return {
    type: "setOrdersCount",
    payload: {
      productOrder: ordersData.productOrder || 0,
      multiOrder: ordersData.multiOrder || 0,
      duplicateOrder: ordersData.duplicateOrder || 0,
    },
  };
};
