import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Update from "@material-ui/icons/Update";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import PrintIcon from '@mui/icons-material/Print';

import {
  Alert,
  Button,
  Backdrop,
  CircularProgress,
  Box,
  Fade,
  Typography,
  Modal,
  TextField,
  Stack,
  Chip,
  FormControlLabel,
  Switch,
} from "@mui/material";

import PeopleIcon from "@mui/icons-material/People";
import InfoIcon from "@mui/icons-material/Info";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { ADMIN_API, AUTH_API_URL } from "api-config";
import { ORDERS_API } from "api-config";

import UserContext from "context/user-context";

import { createWebSocketConnection, closeWebSocketConnection } from "components/PrinterGui/webSocketConnection"

import useWebSocket from "components/PrinterGui/webSocket";

const useStyles = makeStyles(styles);

import { loadEmployees } from "store/employees/reducer";

import { useSelector, useDispatch } from "react-redux";
import { Socket } from "socket.io-client";
import { extendToken } from "refresh-token/refreshToken";

export default function AdminPortal() {
  const classes = useStyles();
  const [backdrop, setBackdrop] = useState(false);
  const [errorLoadingUsersData, setErrorLoadingUsersData] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [selectedRow, setSelectedRow] = useState();

  const [importCaOrders, setImportCaOrders] = useState(false);


  const [isConnected, setIsConnected] = useState(localStorage.getItem('websocketConnected') === 'true');
  const [showValidationMessage, setShowValidationMessage] = useState(false);


  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    // Load the initial connection status from local storage
    const initialStatus = localStorage.getItem('websocketConnected');
    if (initialStatus === 'true') {
      // If the status is 'true', establish the WebSocket connection
      createWebSocketConnection('ws://localhost:9000', handleWebSocketMessage);
    }
  }, []);

  const handleToggle = () => {
    if (isConnected) {
      // Close the WebSocket connection
      closeWebSocketConnection();
      localStorage.setItem('websocketConnected', 'false');
    } else {
      // Open the WebSocket connection
      createWebSocketConnection('ws://localhost:9000', handleWebSocketMessage);
      localStorage.setItem('websocketConnected', 'true');

    }
    
    setIsConnected(!isConnected);
  };

  const handleWebSocketMessage = (message) => {
    // Handle incoming WebSocket messages
    console.log('Received message:', message);
  };
  

  const employee = useSelector((state) => state.employee);

  const dispatch = useDispatch();
  console.log(backdrop);

  useEffect(async () => {
    dispatch(loadEmployees());

    await getIngestionServiceStatus();
  }, [employee.userDetails]);

  const getIngestionServiceStatus = async () => {
    await extendToken();
    return fetch(`${ORDERS_API}/api/v2/serviceStatus`, {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
    })
      .then((response) => response.json())
      .then((res) => {
        setImportCaOrders(res.status);

        return res.status;
      })
      .catch((err) => {
        console.log("error fetching ingestion service status", err);
      });
  };

  const handleIngestionServiceToggle = async () => {
    await extendToken();
    return fetch(`${ORDERS_API}/api/v2/serviceStatusChange`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}` },
      body: JSON.stringify({
        _id: "Ingestion Service",
        status: !importCaOrders,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        await getIngestionServiceStatus();
        return res.orders;
      })
      .catch((err) => {
        console.log("error fetching productOrders", err);
      });
  };


  const updateUser = (
    updatedEmployeeID,
    updatedDisplayName,
    updatedFirstName,
    updatedLastName,
    updatedEmail,
    updatedPhoneNumber,
    updatedGender,
    updatedAddress,
    updatedRole,
    updatedLocationsAllocated
  ) => {
    return fetch(`${AUTH_API_URL}/api/updateEmployee`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`},
      body: JSON.stringify({
        employeeId: updatedEmployeeID,
        displayName: updatedDisplayName,
        firstName: updatedFirstName,
        lastName: updatedLastName,
        email: updatedEmail,
        phoneNumber: updatedPhoneNumber,
        gender: updatedGender,
        address: updatedAddress,
        role: updatedRole,
        locationsAllocated: updatedLocationsAllocated,
        requester: {
          name: localStorage.getItem("employeeId") || "",
          role: localStorage.getItem("role") || "",
        },
      }),
    })
      .then((response) => {
        setBackdrop(true);
        return response.json();
      })
      .then(() => {
        setModalOpen(false);
        setBackdrop(false);
        dispatch(loadEmployees());
      })
      .catch((err) => {
        console.log("Error loading user details", err);
        setBackdrop(false);
        setErrorLoadingUsersData(true);
      });
  };

  const removeUser = (employeeId) => {
    return fetch(`${AUTH_API_URL}/api/deleteEmployee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`},
      body: JSON.stringify({
        employeeId: employeeId,
        requester: {
          name: localStorage.getItem("employeeId") || "",
          role: localStorage.getItem("role") || "",
        },
      }),
    })
      .then((response) => {
        setBackdrop(true);
        return response.json();
      })
      .then(() => {
        setModalOpen(false);
        setBackdrop(false);
        dispatch(loadEmployees());
      })
      .catch((err) => {
        console.log("Error loading user details", err);
        setBackdrop(false);
        setErrorLoadingUsersData(true);
      });
  };

  //TODO: Fix employee creation -> use redux store to fetch employee details
  const createUser = async (
    updatedEmployeeID,
    updatedDisplayName,
    updatedFirstName,
    updatedLastName,
    updatedEmail,
    updatedPhoneNumber,
    updatedGender,
    updatedAddress,
    updatedRole,
    updatedLocationsAllocated
  ) => {
    try {
      setBackdrop(true);
      const response = await fetch(`${AUTH_API_URL}/api/onboardNewEmployee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("authenticatedEmployee")}`
        },
        body: JSON.stringify({
          employeeId: updatedEmployeeID,
          displayName: updatedDisplayName,
          firstName: updatedFirstName,
          lastName: updatedLastName,
          email: updatedEmail,
          phoneNumber: updatedPhoneNumber,
          gender: updatedGender,
          address: updatedAddress,
          role: updatedRole,
          locationsAllocated: updatedLocationsAllocated,
          requester: {
            name: localStorage.getItem("employeeId") || "",
            role: localStorage.getItem("role") || "",
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // const data = await response.json();

      setModalOpen(false);
      setBackdrop(false);
      dispatch(loadEmployees());
    } catch (err) {
      console.log("Error loading user details", err);
      setBackdrop(false);
      setErrorLoadingUsersData(true);
    }
  };

  const handleAdminAction = (type, index) => {
    setSelectedRow(index);
    setModalOpen(true);
    setModalType(type);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleUserUpdate = (
    updatedEmployeeID,
    updatedDisplayName,
    updatedFirstName,
    updatedLastName,
    updatedEmail,
    updatedPhoneNumber,
    updatedGender,
    updatedAddress,
    updatedRole,
    updatedLocationsAllocated
  ) => {
    updateUser(
      updatedEmployeeID,
      updatedDisplayName,
      updatedFirstName,
      updatedLastName,
      updatedEmail,
      updatedPhoneNumber,
      updatedGender,
      updatedAddress,
      updatedRole,
      updatedLocationsAllocated
    );
  };

  const handleUserDeletion = (employeeId) => {
    removeUser(employeeId);
  };

  const handleUserCreation = (
    updatedEmployeeID,
    updatedDisplayName,
    updatedFirstName,
    updatedLastName,
    updatedEmail,
    updatedPhoneNumber,
    updatedGender,
    updatedAddress,
    updatedRole,
    updatedLocationsAllocated
  ) => {
    createUser(
      updatedEmployeeID,
      updatedDisplayName,
      updatedFirstName,
      updatedLastName,
      updatedEmail,
      updatedPhoneNumber,
      updatedGender,
      updatedAddress,
      updatedRole,
      updatedLocationsAllocated
    );
  };

  const handleUserAcion = (
    updatedEmployeeID,
    updatedDisplayName,
    updatedFirstName,
    updatedLastName,
    updatedEmail,
    updatedPhoneNumber,
    updatedGender,
    updatedAddress,
    updatedRole,
    updatedLocationsAllocated
  ) => {
    modalType === "update" &&
      handleUserUpdate(
        updatedEmployeeID,
        updatedDisplayName,
        updatedFirstName,
        updatedLastName,
        updatedEmail,
        updatedPhoneNumber,
        updatedGender,
        updatedAddress,
        updatedRole,
        updatedLocationsAllocated
      );
    modalType === "remove" && handleUserDeletion(updatedEmployeeID);
    modalType === "add" &&
      handleUserCreation(
        updatedEmployeeID,
        updatedDisplayName,
        updatedFirstName,
        updatedLastName,
        updatedEmail,
        updatedPhoneNumber,
        updatedGender,
        updatedAddress,
        updatedRole,
        updatedLocationsAllocated
      );
  };

  const time = new Date();

  return (
    <UserContext.Consumer>
      {(context) => (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={!employee}
            onClick={undefined}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {errorLoadingUsersData && (
            <Alert
              onClose={() => setErrorLoadingUsersData(false)}
              severity="error"
            >
              Error loading Users data. Please try again later.
            </Alert>
          )}

          {employee && employee.userData && (
            <Box paddingX={3}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <EqualizerRoundedIcon />
                        <br />
                        {context.userDetails}
                        Active Users
                      </CardIcon>

                      <p style={{ color: "black", textAlign: "center" }}>
                        {employee.userData.length}
                      </p>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <Update />
                        As on {time.toDateString()}
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                </GridContainer>

                
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="warning">
                      <h4 className={classes.cardTitleWhite}>
                        Employee Details
                      </h4>
                      {employee.userData.length > 0 ? (
                        <p className={classes.cardCategoryWhite}>
                          As on {time.toDateString()}
                        </p>
                      ) : (
                        <Button onClick={() => dispatch(loadEmployees())}>
                          Refresh Employee Details
                        </Button>
                      )}
                    </CardHeader>
                    <CardBody>
                      {employee.userData.length > 0 && (
                        <Table
                          tableHeaderColor="warning"
                          tableHead={["ID", "Name", "Email Id", "Role", "", ""]}
                          tableData={employee.userData.map((ed, key) => {
                            return [
                              ed.employeeId,
                              ed.displayName,
                              ed.email,
                              ed.role,
                              <>
                                <Button
                                  onClick={() =>
                                    handleAdminAction("update", key)
                                  }
                                >
                                  Update
                                </Button>
                              </>,
                              <>
                                <Button
                                  onClick={() =>
                                    handleAdminAction("remove", key)
                                  }
                                >
                                  Remove
                                </Button>
                              </>,
                            ];
                          })}
                        />
                      )}
                    </CardBody>
                    <Button onClick={() => handleAdminAction("add")}>
                      Add new Employee
                    </Button>
                  </Card>
                </GridItem>
              </GridContainer>
            </Box>
          )}
          {modalOpen && (
            <TransitionModal
              modalOpen={modalOpen}
              type={modalType}
              data={employee.userData}
              handleModalClose={handleModalClose}
              index={selectedRow}
              userAction={(
                updatedEmployeeID,
                updatedDisplayName,
                updatedFirstName,
                updatedLastName,
                updatedEmail,
                updatedPhoneNumber,
                updatedGender,
                updatedAddress,
                updatedRole,
                updatedLocationsAllocated
              ) =>
                handleUserAcion(
                  updatedEmployeeID,
                  updatedDisplayName,
                  updatedFirstName,
                  updatedLastName,
                  updatedEmail,
                  updatedPhoneNumber,
                  updatedGender,
                  updatedAddress,
                  updatedRole,
                  updatedLocationsAllocated
                )
              }
            />
          )}
        </div>
      )}
    </UserContext.Consumer>
  );
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

const TransitionModal = (props) => {
  const { modalOpen, handleModalClose, data, index, userAction, type } = props;

  const [updatedEmployeeID, setUpdatedEmployeeID] = useState(
    type === "add" ? "" : data[index].employeeId
  );
  const [updatedFirstName, setUpdatedFirstName] = useState(
    type === "add" ? "" : data[index].firstName
  );
  const [updatedLastName, setUpdatedLastName] = useState(
    type === "add" ? "" : data[index].lastName
  );
  const [updatedEmail, setUpdatedEmail] = useState(
    type === "add" ? "" : data[index].email
  );
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState(
    type === "add" ? "" : data[index].phoneNumber
  );
  const [updatedGender, setUpdatedGender] = useState(
    type === "add" ? "" : data[index].gender
  );
  const [updatedAddress, setUpdatedAddress] = useState(
    type === "add" ? "" : data[index].address
  );
  const [updatedRole, setUpdatedRole] = useState(
    type === "add" ? "" : data[index].role
  );
  const [updatedLocationsAllocated, setUpdatedLocationsAllocated] = useState(
    []
  );
  const [updatedDisplayName, setUpdatedDisplayName] = useState(
    type === "add" ? "" : data[index].displayName
  );

  useEffect(
    () =>
      type === "add"
        ? undefined
        : setUpdatedLocationsAllocated((updatedLocationsAllocated) => [
            ...updatedLocationsAllocated,
            ...data[index].locationsAllocated,
          ]),
    []
  );

  const handleLocationSelection = (location) => {
    updatedLocationsAllocated.includes(location)
      ? setUpdatedLocationsAllocated(() =>
          updatedLocationsAllocated.filter((e) => e !== location)
        )
      : setUpdatedLocationsAllocated((updatedLocationsAllocated) => [
          ...updatedLocationsAllocated,
          ...[location],
        ]);
  };

  const availableLocations = [
    ["S", "V", "N", "D", "E", "F", "G", "H"],
    ["T", "A", "Y","Z"],
    ["None", "UP", "Egg Carton"],
  ];

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      style={{
        overflowY: "scroll",
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <Table
            tableHeaderColor="warning"
            tableHead={["Employee Details", ""]}
            tableData={[
              [
                <>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Employee Id
                  </Typography>
                </>,

                <>
                  <TextField
                    style={{
                      padding: 0,
                      width: "100%",
                    }}
                    id="outlined-disabled"
                    onChange={(e) => setUpdatedEmployeeID(e.target.value)}
                    defaultValue={data[index] ? data[index].employeeId : ""}
                    label={"Employee Id"}
                  />
                </>,
              ],
              [
                <>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Display Name
                  </Typography>
                </>,

                <>
                  {type === "add" ? (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedDisplayName(e.target.value)}
                      label={"Display Name"}
                    />
                  ) : (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedDisplayName(e.target.value)}
                      defaultValue={data[index].displayName}
                    />
                  )}
                </>,
              ],
              [
                <>
                  <Typography
                    style={{
                      padding: 0,
                      width: "100%",
                    }}
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    First Name
                  </Typography>
                </>,

                <>
                  {type === "add" ? (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedFirstName(e.target.value)}
                      label={"First Name"}
                    />
                  ) : (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedFirstName(e.target.value)}
                      defaultValue={data[index].firstName}
                    />
                  )}
                </>,
              ],
              [
                <>
                  <Typography
                    style={{
                      padding: 0,
                      width: "100%",
                    }}
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Last Name
                  </Typography>
                </>,

                <>
                  {type === "add" ? (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedLastName(e.target.value)}
                      label={"Last Name"}
                    />
                  ) : (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedLastName(e.target.value)}
                      defaultValue={data[index].lastName}
                    />
                  )}
                </>,
              ],
              [
                <>
                  <Typography
                    style={{
                      padding: 0,
                      width: "100%",
                    }}
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Email Id
                  </Typography>
                </>,

                <>
                  {type === "add" ? (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedEmail(e.target.value)}
                      label={"Email Id"}
                    />
                  ) : (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedEmail(e.target.value)}
                      defaultValue={data[index].email}
                    />
                  )}
                </>,
              ],
              [
                <>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Phone Number
                  </Typography>
                </>,

                <>
                  {type === "add" ? (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedPhoneNumber(e.target.value)}
                      label={"Phone Number"}
                    />
                  ) : (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedPhoneNumber(e.target.value)}
                      defaultValue={data[index].phoneNumber}
                    />
                  )}
                </>,
              ],
              [
                <>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Gender
                  </Typography>
                </>,

                <>
                  {type === "add" ? (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedGender(e.target.value)}
                      label={"Gender"}
                    />
                  ) : (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedGender(e.target.value)}
                      defaultValue={data[index].gender}
                    />
                  )}
                </>,
              ],
              [
                <>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Address
                  </Typography>
                </>,

                <>
                  {type === "add" ? (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedAddress(e.target.value)}
                      label={"Address"}
                    />
                  ) : (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedAddress(e.target.value)}
                      defaultValue={data[index].address}
                    />
                  )}
                </>,
              ],
              [
                <>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Role
                  </Typography>
                </>,

                <>
                  {type === "add" ? (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedRole(e.target.value)}
                      label={"Role"}
                    />
                  ) : (
                    <TextField
                      style={{
                        padding: 0,
                        width: "100%",
                      }}
                      id="outlined-disabled"
                      onChange={(e) => setUpdatedRole(e.target.value)}
                      defaultValue={data[index].role}
                    />
                  )}
                </>,
              ],
              [
                <>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Locations Allocated
                  </Typography>
                </>,

                <>
                  <Stack spacing={1} alignItems="center">
                    {availableLocations.map((al, key) => (
                      <Stack direction="row" spacing={1} key={key}>
                        {al.map((label, key) => (
                          <Chip
                            key={key}
                            label={label}
                            color="primary"
                            style={{ paddingLeft: "5px", paddingRight: "5px" }}
                            clickable={true}
                            onClick={() => handleLocationSelection(label)}
                            variant={
                              updatedLocationsAllocated.includes(label)
                                ? "filled"
                                : "outlined"
                            }
                          />
                        ))}
                      </Stack>
                    ))}
                  </Stack>
                </>,
              ],
              [
                <>
                  <Button
                    style={{
                      padding: 0,
                      width: "100%",
                    }}
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                </>,

                <>
                  <Button
                    type="submit"
                    style={{
                      padding: 0,
                      width: "100%",
                    }}
                    onClick={() =>
                      userAction(
                        updatedEmployeeID,
                        updatedDisplayName,
                        updatedFirstName,
                        updatedLastName,
                        updatedEmail,
                        updatedPhoneNumber,
                        updatedGender,
                        updatedAddress,
                        updatedRole,
                        updatedLocationsAllocated
                      )
                    }
                  >
                    {type === "update"
                      ? "Update"
                      : type === "remove"
                      ? "Delete"
                      : "Create"}
                  </Button>
                </>,
              ],
            ]}
          />
        </Box>
      </Fade>
    </Modal>
  );
};
